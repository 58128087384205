import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { ColorStyleProps, IconSizeProps } from '../../../@types/theme';

import styles from './Icon.module.scss';

export interface Props extends Omit<FontAwesomeIconProps, 'size' | 'color'> {
  icon: IconProp;
  color?: ColorStyleProps;
  size?: IconSizeProps;
  /** Whether the icon should be rendered inside an auto-sized container. Default value is false. */
  isCompact?: boolean;
}

export const Icon: React.FunctionComponent<Props> = ({
  className,
  icon,
  size,
  color,
  isCompact = false,
  ...fontAwesomeProps
}) => {
  const cls = classNames(
    styles.wrapper,
    {
      [styles[`size-${size}`]]: size,
      [`text-${color}`]: color,
      compact: isCompact,
    },
    className,
  );

  return (
    <span className={cls}>
      <FontAwesomeIcon icon={icon} {...fontAwesomeProps} />
    </span>
  );
};

export default Icon;
