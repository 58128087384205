import * as React from 'react';
import { Modal } from './Modal';
import useModalStore from './store';

const ModalContainer: React.FunctionComponent = () => {
  const { modals } = useModalStore();

  return (
    <>
      {modals.map((modal) => {
        if ('render' in modal) {
          return modal.render({ id: modal.id, show: true });
        }
        return <Modal key={modal.id || modal.title} {...modal} />;
      })}
    </>
  );
};

export default ModalContainer;
