import * as React from 'react';
import classNames from 'classnames';
import Slider, { SliderProps } from 'rc-slider';
import { MarkObj } from 'rc-slider/lib/Marks';
import styles from './RangeInputField.module.scss';
import { useTheme } from '../../../contexts/theme';
import Text from '../../atoms/Text';

export interface ColorProp {
  key: number;
  color: string;
}

export interface Props extends Omit<SliderProps, 'onChange'> {
  className?: string;
  colorMap?: ColorProp[];
  min?: number;
  max?: number;
  marks?: Record<string | number, React.ReactNode | MarkObj>;
  value?: number;
  onChange: (value: number) => void;
  helperText?: string;
  errorText?: string;
}

/**
 * Renders the Handle component
 * @param percentage
 * @param accentColor
 * @returns
 */
const renderHandle =
  (
    percentage: number,
    accentColor: string,
    hidden?: boolean,
  ): SliderProps['handleRender'] =>
  (node, { prefixCls, value }) => {
    return (
      <div
        className={prefixCls}
        role="spinbutton"
        aria-valuenow={percentage}
        {...node.props}
        style={{
          left: `calc(${percentage}% - 18px)`,
          top: `6.5px`,
          width: 'auto',
          height: 'auto',
          opacity: 1,
        }}
      >
        <div
          className={classNames(styles.bubble, { hidden })}
          style={{ backgroundColor: accentColor }}
        >
          <Text className="w-100" as="span" color="white" weight="bold">
            {value}
          </Text>
        </div>
      </div>
    );
  };

const RangeInputField: React.FunctionComponent<Props> = ({
  className,
  colorMap,
  min = 0,
  max = 10,
  marks,
  value,
  onChange,
  helperText,
  errorText,
  ...sliderProps
}) => {
  const { theme } = useTheme();

  const marksMemo = React.useMemo(
    () =>
      marks ||
      Object.fromEntries(
        Array.from({ length: max - min + 1 }, (x, i) => i).map((label) => [
          label + min,
          label + min,
        ]),
      ),
    [marks, max, min],
  );

  const colorFromMap = colorMap?.find((c) => c.key === value)?.color;
  const accentColor = colorFromMap || theme.color.primary;

  const change = (value || 0) - min;
  const percentage = (change / (max - min)) * 100;

  return (
    <div className={styles.wrapper}>
      <div className={styles['slider-wrapper']}>
        <Slider
          className={classNames(styles.slider, className)}
          marks={marksMemo}
          value={value}
          min={min}
          max={max}
          dotStyle={{
            borderRadius: '0px',
            top: '0px',
            width: '1px',
            height: '30px',
            border: '0px',
            opacity: '50%',
          }}
          railStyle={{ height: '30px', borderRadius: '30px' }}
          trackStyle={{
            backgroundColor: accentColor,
            transition: 'background-color 0.2s',
            height: '30px',
            borderRadius: '30px',
          }}
          handleRender={renderHandle(
            percentage,
            accentColor,
            value === undefined,
          )}
          onChange={(v) => {
            if (typeof v === 'number') onChange(v);
          }}
          /*
           * When we pass undefined to Slider.props.value, Slider sets it to defaultValue, or 0
           * causing onChange not to be triggered if the user try to select the same value set in Slider.props.value
           * So we will use onBeforeChange, which is triggered instead with the same value that onChange should be triggered
           */
          onBeforeChange={(v) => {
            if (typeof v === 'number' && value === undefined) onChange(v);
          }}
          {...sliderProps}
        />
      </div>
      <div>
        {helperText && !errorText && (
          <Text as="span" className="mt-2" size="sm1">
            {helperText}
          </Text>
        )}
        {errorText && (
          <Text as="span" className="mt-2" color="red400" size="sm1">
            {errorText}
          </Text>
        )}
      </div>
    </div>
  );
};

export default RangeInputField;
