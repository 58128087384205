import React from 'react';
import {
  EnumServiceCustomizationType,
  JsonServiceCustomizationData,
  PatientCallReason,
} from '@quality24/inpatient-typings';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Input,
  Modal,
  RangeInputField,
  Text,
  MultipleListGroup,
} from '@quality24/design-system';
import { useHTMLParser } from '@quality24/react-hooks';
import PainScaleInput from '@/inpatient-patient-pwa/molecules/PainScaleInput/PainScaleInput';
import MultipleChoiceWithQuantity, {
  formatOutput,
  parseInput,
} from './components/MultipleChoiceWithQuantity';

type PatientCallReasonFragment = Required<
  Pick<
    PatientCallReason,
    'id' | 'modifier' | 'customizationType' | 'customizationData' | 'icons'
  >
>;

export interface Props {
  className?: string;
  show: boolean;
  reason: PatientCallReasonFragment;
  onHide: () => void;
  onComplete: (customization: { value: string }) => void;
}

/**
 * Converts the numeric value into string accordingly to customization type.
 * @param value
 * @param type
 * @returns
 */
const parseValue = (
  value: string | number,
  type: EnumServiceCustomizationType,
) => {
  if (type === 'TEMPERATURE') {
    return `${value}ºC`;
  }

  if (type === 'INTENSITY' || type === 'PAIN_VAS') {
    return `Intensidade: ${value}`;
  }

  return `${value}`;
};

/**
 * Yup validation for customization data
 * @param custom
 * @returns
 */
const getValidationSchema = (custom: JsonServiceCustomizationData) =>
  Yup.object().shape({
    type: Yup.string(),
    value: Yup.string()
      .required('Campo obrigatório')
      .when('type', {
        is: (value: EnumServiceCustomizationType) =>
          custom &&
          (value === 'TEMPERATURE' ||
            value === 'INTEGER' ||
            value === 'DECIMAL' ||
            value === 'INTENSITY'),
        then: Yup.string().test({
          name: 'NumberWithinRanges',
          test: function testNumberBounds(value?: string) {
            if (!value) return true;

            const { type }: { type: EnumServiceCustomizationType } =
              this.parent;

            const parsedValue = parseInt(value, 10);
            if (!Number.isInteger(parsedValue)) {
              return this.createError({
                message: `Só é possível solicitar quantidades inteiras!`,
              });
            }

            if (custom.max && !custom.min && parsedValue > custom.max) {
              const max = parseValue(custom.max, type);
              return this.createError({
                message: `A quantidade precisa ser menor do que ${max}.`,
              });
            }
            if (!custom.max && custom.min && parsedValue < custom.min) {
              const min = parseValue(custom.min, type);
              return this.createError({
                message: `A quantidade precisa ser maior do que ${min}.`,
              });
            }
            if (
              custom.max &&
              custom.min &&
              (parsedValue < custom.min || parsedValue > custom.max)
            ) {
              const min = parseValue(custom.min, type);
              const max = parseValue(custom.max, type);
              return this.createError({
                message: `A quatidade precisa estar entre ${min} e ${max}.`,
              });
            }
            return true;
          },
        }),
      })
      .when('type', {
        is: (value: EnumServiceCustomizationType) =>
          custom && value === 'MULTIPLE_CHOICE',
        then: Yup.string().test({
          name: 'atLeastOneItemSelected',
          test: function atLeastOneItemSelected(value?: string) {
            if (!value)
              return this.createError({
                message: `Você precisa selecionar ao menos um item!`,
              });
            return true;
          },
        }),
      }),
  });

const PatientCallCustomizationModal: React.FunctionComponent<Props> = ({
  className,
  show,
  reason,
  onHide,
  onComplete,
}) => {
  const htmlParser = useHTMLParser();
  const { customizationType, customizationData } = reason;

  const formik = useFormik({
    initialValues: {
      type: customizationType,
      data: customizationData,
      value: '',
    },
    validationSchema: getValidationSchema(customizationData),
    validateOnMount: true,
    onSubmit: (values) => {
      onComplete({ value: parseValue(values.value, customizationType) });
    },
  });

  const title = reason.modifier?.title ?? 'Preencha abaixo';

  return (
    <Modal
      id="order-customization-modal"
      className={className}
      show={show}
      onHide={onHide}
      title={title}
      footer={
        <Button
          onClick={() => formik.handleSubmit()}
          disabled={!formik.isValid}
          fluid
          size="lg"
        >
          Salvar
        </Button>
      }
    >
      {reason.modifier?.description && (
        <Text
          dangerouslySetInnerHTML={{
            __html: htmlParser(reason.modifier?.description),
          }}
        />
      )}
      <div className="p-1">
        {/* Temperature */}
        {customizationType === 'TEMPERATURE' && (
          <Input
            id="formTemperature"
            type="number"
            name="value"
            append="ºC"
            value={formik.values.value}
            helperText={formik.errors.value}
            onChange={formik.handleChange}
          />
        )}

        {/* Intensity */}
        {customizationType === 'INTENSITY' && (
          <div className="p-3">
            <RangeInputField
              className="mt-2"
              min={customizationData.min ?? 1}
              max={customizationData.max ?? 10}
              value={
                parseInt(formik.values.value, 10) || customizationData.min || 1
              }
              onChange={(v) => formik.setFieldValue('value', v)}
            />
          </div>
        )}

        {/* Pain */}
        {customizationType === 'PAIN_VAS' && (
          <div>
            <PainScaleInput
              className="mt-2"
              value={parseInt(formik.values.value, 10) || 0}
              onChange={(v) => formik.setFieldValue('value', v)}
            />
          </div>
        )}

        {/* Multiple choice */}
        {customizationType === 'MULTIPLE_CHOICE' &&
          customizationData?.choices &&
          customizationData?.type !== 'with-quantity' && (
            <MultipleListGroup
              value={formik.values.value?.split(', ')}
              onChange={(newValue) => {
                formik.setFieldValue(
                  'value',
                  newValue.filter((entry) => entry !== '').join(', '),
                );
              }}
            >
              {customizationData?.choices.map((choice) => (
                <MultipleListGroup.Item value={choice.value} key={choice.value}>
                  <Text className="d-flex flex-column" weight="semiBold">
                    {choice.value}{' '}
                    {choice.description && (
                      <Text
                        as="span"
                        color="gray400"
                        style={{ marginBottom: '-10px' }}
                      >
                        {choice.description}
                      </Text>
                    )}
                  </Text>
                </MultipleListGroup.Item>
              ))}
            </MultipleListGroup>
          )}

        {/* Multiple choice with quantity */}
        {customizationType === 'MULTIPLE_CHOICE' &&
          customizationData?.choices &&
          customizationData?.type === 'with-quantity' && (
            <MultipleChoiceWithQuantity
              choices={customizationData?.choices}
              value={parseInput(
                formik.values.value,
                customizationData?.choices ?? [],
              )}
              onChange={(quantity, item) => {
                const quantities = parseInput(
                  formik.values.value,
                  customizationData?.choices ?? [],
                );
                formik.setFieldValue(
                  'value',
                  formatOutput({ ...quantities, [item]: quantity }),
                );
              }}
            />
          )}

        {/* Numeric */}
        {customizationType === 'INTEGER' && (
          <Input
            id="formInteger"
            type="number"
            name="value"
            value={formik.values.value}
            helperText={formik.errors.value}
            onChange={formik.handleChange}
          />
        )}

        {/* String */}
        {customizationType === 'TEXT' && (
          <Input
            id="formText"
            name="value"
            value={formik.values.value}
            helperText={formik.errors.value}
            onChange={formik.handleChange}
          />
        )}
      </div>
    </Modal>
  );
};

export default PatientCallCustomizationModal;
