import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  Admission,
  Alert,
  ServiceGroupItem,
  Service,
  SubscriptionPayload,
  ServiceGroup,
} from '@quality24/inpatient-typings';

import { useToast } from '@quality24/design-system';
import { AuthContext } from '@/inpatient-patient-pwa/types/auth';
import {
  getFloorId,
  getRoomId,
} from '@/inpatient-patient-pwa/services/storage';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import navbarContext from './navbarContext';

/**
 * GraphQL authenticate mutation
 */
const SELECT_QUERY = gql`
  query FetchNavbarData($admissionId: UUID!, $floorId: UUID!) {
    admission: admissionById(id: $admissionId) {
      id
      roomId
      endedAt
    }

    activeAlerts: alerts(
      filter: {
        admissionId: { equalTo: $admissionId }
        resolvedAt: { isNull: true }
        cancelledAt: { isNull: true }
        group: {
          group: {
            active: { equalTo: true }
            locationIds: { contains: [$floorId] }
          }
        }
      }
    ) {
      totalCount
    }
  }
`;

const ALERT_SUBSCRIPTION = gql`
  subscription onAlertChanged($admissionId: UUID!) {
    alertChanges: alertChangesByAdmissionId(admissionId: $admissionId) {
      event
      alert: alertData {
        id
        resolvedAt
        cancelledAt
        reasonId
        details
        group {
          id
          service {
            id
            reason
          }
          group {
            id
            locationIds
          }
        }
      }
    }
  }
`;

const ADMISSION_CHANGED_SUBSCRIPTION = gql`
  subscription onRoomChanged($admissionId: UUID!, $roomId: UUID!) {
    admissionChanges: admissionChanges(
      admissionId: $admissionId
      roomId: $roomId
    ) {
      admission: admissionData {
        id
        roomId
        endedAt
      }
    }
  }
`;

export interface QueryResult {
  admission: Pick<Admission, 'id' | 'roomId' | 'endedAt'> | null;
  activeAlerts: {
    totalCount: number;
  };
}

interface AlertSubscriptionResult {
  subscriptionData: {
    data: {
      alertChanges: SubscriptionPayload<
        'alert',
        Pick<Alert, 'id' | 'resolvedAt' | 'cancelledAt' | 'details'> & {
          group: Pick<ServiceGroupItem, 'id'> & {
            service: Required<Pick<Service, 'id' | 'reason'>>;
            group: Pick<ServiceGroup, 'id' | 'locationIds'>;
          };
        }
      >;
    };
  };
}

interface AdmissionSubscriptionResult {
  subscriptionData: {
    data: {
      admissionChanges: SubscriptionPayload<'admission', Admission>;
    };
  };
}

export interface Props {
  children: React.ReactNode;
}

/**
 * Handles a new alert changes event
 */
const handleAlertChanges = (
  prev: QueryResult,
  { subscriptionData }: AlertSubscriptionResult,
  floorId: string,
  notifier?: (message: string) => void,
) => {
  if (!subscriptionData.data) return prev;
  const { event, alert } = subscriptionData.data.alertChanges;

  // Check if alert is from a group we have access
  if (!alert.group.group.locationIds.includes(floorId)) {
    return prev;
  }

  const totalCount = prev?.activeAlerts?.totalCount || 0;

  switch (event) {
    case 'update':
      if (!alert.resolvedAt && !alert.cancelledAt) {
        return prev;
      }
      if (alert.cancelledAt) {
        const {
          group: { service },
          details: { cancelReason },
        } = alert;
        notifier?.(
          `Seu chamado de "${
            service.reason['pt-BR']
          }" foi cancelado com o motivo "${cancelReason || 'Não informado'}".`,
        );
      }

      return {
        ...prev,
        activeAlerts: {
          ...prev.activeAlerts,
          totalCount: totalCount - 1,
        },
      };

    case 'insert':
      return {
        ...prev,
        activeAlerts: {
          ...prev.activeAlerts,
          totalCount: totalCount + 1,
        },
      };

    default:
      return prev;
  }
};

const handleAdmissionChanges =
  (logout: AuthContext['logout']) =>
  (prev: QueryResult, { subscriptionData }: AdmissionSubscriptionResult) => {
    if (!subscriptionData.data) return prev;

    const { admission } = subscriptionData.data.admissionChanges;
    const roomId = getRoomId();

    if (admission.roomId !== roomId || admission.endedAt) {
      logout();
    }

    return prev;
  };

/**
 * <NavbarProvider> component
 */
const NavbarProvider: React.FunctionComponent<Props> = ({ children }) => {
  const { warning } = useToast();
  const [renderGreeting, setRenderGreeting] = React.useState(false);

  const {
    user: { admissionId },
    logout,
  } = useAuth();
  const roomId = getRoomId();
  const floorId = getFloorId();

  const { data, loading, error, subscribeToMore } = useQuery<QueryResult>(
    SELECT_QUERY,
    {
      variables: { admissionId, floorId },
      skip: !admissionId,
    },
  );

  React.useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ALERT_SUBSCRIPTION,
      variables: { admissionId },
      updateQuery: (queryResult, subscriptionResult: AlertSubscriptionResult) =>
        handleAlertChanges(
          queryResult,
          subscriptionResult,
          floorId,
          (message) => warning(message, { autoclose: false }),
        ),
    });
    return unsubscribe;
  }, [admissionId, floorId, subscribeToMore, warning]);

  React.useEffect(() => {
    if (roomId === null) {
      return () => null;
    }
    const unsubscribe = subscribeToMore({
      document: ADMISSION_CHANGED_SUBSCRIPTION,
      variables: { admissionId, roomId },
      updateQuery: handleAdmissionChanges(logout),
    });
    return unsubscribe;
  }, [admissionId, roomId, logout, subscribeToMore]);

  React.useEffect(() => {
    // If no admission found or admission finished, logout
    if (!loading && !error && (!data?.admission || data.admission.endedAt)) {
      logout();
      return;
    }
    // If patient changed room, logout
    if (!loading && !error && (!data?.admission || data.admission.roomId)) {
      if (roomId !== null && data?.admission?.roomId !== roomId) {
        logout();
      }
    }
  }, [loading, error, logout, data?.admission, roomId]);

  const value = React.useMemo(
    () => ({
      activeAlerts: data?.activeAlerts?.totalCount || 0,
      renderGreeting,
      setRenderGreeting,
    }),
    [data?.activeAlerts?.totalCount, renderGreeting],
  );

  return (
    <navbarContext.Provider value={value}>{children}</navbarContext.Provider>
  );
};

export default NavbarProvider;
