import * as React from 'react';
import classNames from 'classnames';
import {
  ColorStyleProps,
  FontSizeProps,
  FontWeightProps,
} from '../../../@types/theme';

import styles from './Heading.module.scss';

export interface Props
  extends Omit<React.HTMLAttributes<HTMLHeadingElement>, 'color'> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: FontSizeProps;
  color?: ColorStyleProps;
  weight?: FontWeightProps;
  children?: React.ReactNode;
  isUpperCase?: boolean;
  ellipsize?: boolean;
}

export const Heading: React.FC<Props> = ({
  className,
  style,
  as = 'h1',
  children,
  size = 'md',
  weight,
  color,
  isUpperCase = false,
  ellipsize = false,
  ...htmlHeadingProps
}) => {
  const Component = as;

  const cls = classNames(styles.text, className, {
    [`fs-${as}-${size}`]: size,
    [`text-${color}`]: color,
    [`fw-${weight}`]: weight,
    ellipsize,
  });

  return (
    <Component
      className={cls}
      style={{ textTransform: isUpperCase ? 'uppercase' : 'inherit', ...style }}
      title={typeof children === 'string' ? children : undefined}
      {...htmlHeadingProps}
    >
      {children}
    </Component>
  );
};

export default Heading;
