import * as React from 'react';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { SurveyPage } from '@quality24/inpatient-typings';
import {
  Heading,
  Icon,
  SelectionContainer,
  Text,
  TextArea,
} from '@quality24/design-system';

import { SurveyHook } from '@/inpatient-patient-pwa/utils/surveyHooks';
import ImprovementsSelector from '@/inpatient-patient-pwa/molecules/ImprovementsSelector';

export interface Props {
  page: Pick<SurveyPage, 'id' | 'category' | 'type' | 'title' | 'data'>;
  answer: SurveyHook['answer'];
}

const YesOrNoQuestionPage: React.FunctionComponent<Props> = ({
  page,
  answer,
}) => {
  const description = page.data?.description;
  const improvements = page.data?.improvements;

  const show =
    answer.values.value.score !== undefined && answer.values.value.score === 0;

  return (
    <>
      <Heading as="h1" weight="semiBold" size="lg1">
        {page.title}
      </Heading>
      <Text className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
        {description}
      </Text>

      <SelectionContainer
        className="d-flex flex-justify-center"
        allowMultiple={false}
        value={answer.values.value.score}
        onChange={(v) => {
          answer.setFieldValue('value.score', v);
        }}
      >
        <SelectionContainer.Option
          value={1}
          className="py-4 px-5 rounded-md bg-nps10 flex-1"
          style={{ flex: 1 }}
        >
          <Icon icon={faThumbsUp} color="white" size="lg" />
        </SelectionContainer.Option>
        <SelectionContainer.Option
          value={0}
          className="py-4 px-5 rounded-md bg-nps0 flex-1"
          style={{ flex: 1 }}
        >
          <Icon icon={faThumbsDown} color="white" size="lg" />
        </SelectionContainer.Option>
      </SelectionContainer>

      {improvements && show && improvements.length > 0 && (
        <div className="my-4">
          <ImprovementsSelector
            titles={improvements}
            value={answer.values.value.improvements || []}
            onChange={(v: string[]) =>
              answer.setFieldValue('value.improvements', v)
            }
          />
        </div>
      )}

      <TextArea
        id="formFeedback"
        className="my-4"
        label="Deixe seu comentário abaixo:"
        name="text"
        value={answer.values.value.text}
        errorText={answer.errors?.value?.text}
        onChange={(e) => answer.setFieldValue('value.text', e.target.value)}
      />
    </>
  );
};

export default YesOrNoQuestionPage;
