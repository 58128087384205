import { gql, useQuery } from '@apollo/client';
import {
  Alert,
  EnumServiceCategory as Category,
  Connection,
  ServiceGroupItem,
  SubscriptionPayload,
  UUIDv4,
} from '@quality24/inpatient-typings';
import { Spinner, useToast } from '@quality24/design-system';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { validateConditional } from '@quality24/react-order-components';
import Can from '@/inpatient-patient-pwa/atoms/Can';
import ApiError from '@/inpatient-patient-pwa/molecules/ApiError';
import PatientCallCustomizationModal from '@/inpatient-patient-pwa/organisms/PatientCallCustomizationModal';
import PatientCallSection from '@/inpatient-patient-pwa/organisms/PatientCallSection';
import PatientCallSelectorModal from '@/inpatient-patient-pwa/organisms/PatientCallSelectorModal';

import { getFloorId } from '@/inpatient-patient-pwa/services/storage';
import {
  PatientCallReasonFragment,
  usePatientCallTrigger,
} from '@/inpatient-patient-pwa/utils/patientCallHooks';

import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import { useNavbar } from '@/inpatient-patient-pwa/contexts/navbar/useNavbar';

const PatientCallFragment = gql`
  fragment PatientCallGroupFragment on PatientCallGroupItem {
    id
    name
    modifier
    customizationData
    conditional
    isDefault
    service {
      id
      reason
      icons
      modifier
      customizationType
      customizationData
      actions
      conditional
    }

    lastAlert: alerts(
      first: 1
      orderBy: [CREATED_AT_DESC]
      filter: { admissionId: { equalTo: $admissionId } }
    ) {
      nodes {
        id
        createdAt
      }
    }

    activeAlerts: alerts(
      filter: {
        admissionId: { equalTo: $admissionId }
        resolvedAt: { isNull: true }
        cancelledAt: { isNull: true }
      }
    ) {
      nodes {
        id
      }
    }
  }
`;

export const SELECT_QUERY = gql`
  query PatientCallGroupItem(
    $floorId: UUID!
    $admissionId: UUID!
    $category: EnumPatientCallReasonsCategory
  ) {
    groups: patientCallGroupItems(
      orderBy: ORDER_ASC
      filter: {
        parentId: { isNull: true }
        service: {
          enabled: { equalTo: true }
          category: { equalTo: $category }
        }
        enabled: { equalTo: true }
        group: {
          active: { equalTo: true }
          locationIds: { contains: [$floorId] }
        }
      }
    ) {
      nodes {
        ...PatientCallGroupFragment

        children: childPatientCallGroupItems(
          orderBy: ORDER_ASC
          filter: {
            service: { category: { equalTo: $category } }
            enabled: { equalTo: true }
          }
        ) {
          totalCount
          nodes {
            ...PatientCallGroupFragment
            children: childPatientCallGroupItems(
              orderBy: ORDER_ASC
              filter: {
                service: { category: { equalTo: $category } }
                enabled: { equalTo: true }
              }
            ) {
              totalCount
              nodes {
                ...PatientCallGroupFragment
              }
            }
          }
        }
      }
    }
  }
  ${PatientCallFragment}
`;

export const ALERT_SUBSCRIPTION = gql`
  subscription onAlertChanged($admissionId: UUID!) {
    alertChanges: alertChangesByAdmissionId(admissionId: $admissionId) {
      event
      alert: alertData {
        id
        createdAt
        resolvedAt
        cancelledAt
        reasonId
        groupId
      }
    }
  }
`;

export interface QueryResult {
  groups: Connection<PatientCallReasonQueryResult>;
}

export interface PatientCallReasonQueryResult
  extends Pick<
    ServiceGroupItem,
    | 'id'
    | 'isDefault'
    | 'name'
    | 'modifier'
    | 'customizationData'
    | 'conditional'
  > {
  service: PatientCallReasonFragment;
  lastAlert: Connection<Required<Pick<Alert, 'id' | 'createdAt'>>>;
  activeAlerts: Connection<Pick<Alert, 'id'>>;

  children: Connection<{
    id: UUIDv4;
    name: ServiceGroupItem['name'];
    modifier: ServiceGroupItem['modifier'];
    conditional: ServiceGroupItem['conditional'];
    service: PatientCallReasonFragment;
    lastAlert: Connection<Required<Pick<Alert, 'id' | 'createdAt'>>>;
    activeAlerts: Connection<Pick<Alert, 'id'>>;

    children: Connection<{
      id: UUIDv4;
      name: ServiceGroupItem['name'];
      modifier: ServiceGroupItem['modifier'];
      conditional: ServiceGroupItem['conditional'];
      service: PatientCallReasonFragment;
      lastAlert: Connection<Required<Pick<Alert, 'id' | 'createdAt'>>>;
      activeAlerts: Connection<Pick<Alert, 'id'>>;
    }>;
  }>;
}

interface ComposedPatientCallReason extends PatientCallReasonFragment {
  groupId: UUIDv4;
  lastAlert: Required<Pick<Alert, 'createdAt'>> | null;
  activeAlert?: Pick<Alert, 'id'>;
  hasChildren?: boolean;
  children?: Array<ComposedPatientCallReason>;
}

interface SubscriptionResult {
  subscriptionData: {
    data: {
      alertChanges: SubscriptionPayload<
        'alert',
        Required<Pick<Alert, 'id' | 'reasonId' | 'groupId' | 'createdAt'>> &
          Pick<Alert, 'resolvedAt' | 'cancelledAt'>
      >;
    };
  };
}

export interface Props {
  category: Category;
}

/**
 * Recursively removes alerts from inner children
 * @param children
 * @param alert
 * @returns
 */
const updateInnerChildren = (
  children: PatientCallReasonQueryResult['children'],
  alert: Pick<
    Alert,
    'id' | 'reasonId' | 'groupId' | 'resolvedAt' | 'cancelledAt'
  >,
): PatientCallReasonQueryResult['children']['nodes'] => {
  // remove active alert from child
  if (children.nodes?.length) {
    const result = children.nodes.map((child) => {
      if (child.id === alert.groupId) {
        return {
          ...child,
          activeAlerts: { ...child.activeAlerts, nodes: [] },
        };
      }

      // Has inner children
      if (child.children?.totalCount && child.children.totalCount > 0) {
        const children2 = updateInnerChildren(
          child.children as PatientCallReasonQueryResult['children'],
          alert,
        );
        return { ...child, children: { ...child.children, nodes: children2 } };
      }
      return child;
    });

    return result;
  }

  return children.nodes;
};

/**
 * Recursively inserts alerts to inner children
 * @param children
 * @param alert
 * @returns
 */
const insertInnerChildren = (
  children: PatientCallReasonQueryResult['children'],
  alert: Required<Pick<Alert, 'id' | 'reasonId' | 'groupId' | 'createdAt'>> &
    Pick<Alert, 'resolvedAt' | 'cancelledAt'>,
): PatientCallReasonQueryResult['children']['nodes'] => {
  // remove active alert from child
  if (children.nodes?.length) {
    const result = children.nodes.map((child) => {
      if (child.id === alert.groupId) {
        return {
          ...child,
          lastAlert: { ...child.lastAlert, nodes: [alert] },
          activeAlerts: { ...child.activeAlerts, nodes: [alert] },
        };
      }

      // Has inner children
      if (child.children?.totalCount && child.children.totalCount > 0) {
        const children2 = insertInnerChildren(
          child.children as PatientCallReasonQueryResult['children'],
          alert,
        );
        return { ...child, children: { ...child.children, nodes: children2 } };
      }
      return child;
    });

    return result;
  }

  return children.nodes;
};

/**
 * Handles a new alert changes event
 * @param {*} prev
 * @param {*} param1
 */
const handleAlertChanges = (
  prev: QueryResult,
  { subscriptionData }: SubscriptionResult,
) => {
  if (!subscriptionData.data) return prev;
  const { event, alert } = subscriptionData.data.alertChanges;

  let reasons;
  switch (event) {
    case 'insert-alertEvent':
    case 'update': {
      if (!alert.resolvedAt && !alert.cancelledAt) return prev;
      reasons = prev.groups.nodes.map((n) => {
        // remove active alert from child
        if (n.children.nodes?.length) {
          const children = updateInnerChildren(n.children, alert);
          return { ...n, children: { ...n.children, nodes: children } };
        }
        // remove active alert from parent
        if (n.id === alert.reasonId) {
          return { ...n, activeAlerts: { ...n.activeAlerts, nodes: [] } };
        }
        return n;
      });
      break;
    }

    case 'insert':
      reasons = prev.groups.nodes.map((n) => {
        if (n.children.nodes?.length) {
          const children = insertInnerChildren(n.children, alert);
          return { ...n, children: { ...n.children, nodes: children } };
        }
        // add active alert
        if (n.id === alert.reasonId) {
          return {
            ...n,
            lastAlert: { ...n.lastAlert, nodes: [alert] },
            activeAlerts: { ...n.activeAlerts, nodes: [alert] },
          };
        }
        return n;
      });
      break;

    default:
      return prev;
  }

  return { ...prev, groups: { ...prev.groups, nodes: reasons } };
};

/**
 * Converts PatientCallReasonQueryResult types to PatientCallGroup
 * @param reasons
 * @returns
 */
const parseGroups = (
  groups?: Array<PatientCallReasonQueryResult>,
): Array<ComposedPatientCallReason> => {
  if (!groups) return [];
  return groups.map((group) => ({
    ...group.service,
    reason: group.name || group.service.reason,
    groupId: group.id,
    modifier: group.modifier || group.service.modifier,
    conditional: group.conditional || group.service.conditional,
    lastAlert: group.lastAlert?.nodes[0] || null,
    activeAlert: group.activeAlerts?.nodes
      ? group.activeAlerts.nodes[0]
      : undefined,
    hasChildren: !!group.children.totalCount && group.children.totalCount > 0,
    children: group.children.nodes
      ? group.children.nodes.map((child) => ({
          ...child.service,
          reason: child.name || child.service.reason,
          groupId: child.id,
          modifier: child.modifier || child.service.modifier,
          conditional: child.conditional || child.service.conditional,
          lastAlert: child.lastAlert?.nodes[0] || null,
          activeAlert: child.activeAlerts?.nodes
            ? child.activeAlerts.nodes[0]
            : undefined,
          // Second level children
          // TODO: Should be replaced with a logic to lazy loading these children
          hasChildren:
            !!child.children.totalCount && child.children.totalCount > 0,
          children: child.children.nodes
            ? child.children.nodes.map((child2) => ({
                ...child2.service,
                reason: child2.name || child2.service.reason,
                groupId: child2.id,
                modifier: child2.modifier || child2.service.modifier,
                conditional: child2.conditional || child2.service.conditional,
                lastAlert: child2.lastAlert?.nodes[0] || null,
                activeAlert: child2.activeAlerts?.nodes
                  ? child2.activeAlerts.nodes[0]
                  : undefined,
              }))
            : undefined,
        }))
      : undefined,
  }));
};

const PatientCallTriggerPage: React.FunctionComponent<Props> = ({
  category,
}: Props) => {
  const { error: errorToast } = useToast();

  const {
    user: { admissionId },
  } = useAuth();
  const floorId = getFloorId();

  const { setRenderGreeting } = useNavbar();
  React.useEffect(() => setRenderGreeting(false), [setRenderGreeting]);

  const navigate = useNavigate();

  const { currentStep, reason, dispatch, operation } =
    usePatientCallTrigger(admissionId);

  const { data, loading, error, subscribeToMore, refetch } =
    useQuery<QueryResult>(SELECT_QUERY, {
      variables: {
        admissionId,
        category,
        floorId,
      },
      fetchPolicy: 'network-only',
    });

  React.useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ALERT_SUBSCRIPTION,
      variables: { admissionId },
      updateQuery: handleAlertChanges,
    });
    return unsubscribe;
  }, [admissionId, subscribeToMore]);

  React.useEffect(() => {
    if (operation.called && !operation.loading && !operation.error) {
      navigate('/calls');
    }
  }, [operation, navigate]);

  const filteredGroups = React.useMemo(() => {
    if (!data) return [];
    if (data.groups?.nodes?.every((group) => group.isDefault)) {
      return data.groups.nodes;
    }
    return data.groups?.nodes?.filter((group) => !group.isDefault);
  }, [data]);

  const reasons = parseGroups(filteredGroups);

  return (
    <>
      <Helmet>
        <title>Início - Quality24</title>
      </Helmet>

      {/* Patient call section */}
      <Can perform="patient-call-section:view">
        <div>
          {/* Loading state */}
          {loading && (
            <div className="d-flex flex-justify-center p-4">
              <Spinner />
            </div>
          )}
          {/* Error state */}
          {error && <ApiError error={error} refetch={refetch} />}

          {!loading && !error && (
            <PatientCallSection
              reasons={reasons}
              category={category}
              onSelect={(selection) => {
                if (
                  !(selection as ComposedPatientCallReason).hasChildren &&
                  !validateConditional(
                    selection.conditional,
                    (selection as ComposedPatientCallReason).lastAlert
                      ?.createdAt || null,
                    errorToast,
                  )
                ) {
                  return;
                }
                dispatch({ type: 'init', reason: selection });
              }}
            />
          )}
        </div>

        {/* Modal for selecting children reasons */}
        {reason && currentStep === 'select-child' && (
          <PatientCallSelectorModal
            show
            reason={reason}
            onHide={() => dispatch({ type: 'reset', admissionId })}
            onSelect={(selection) => {
              if (
                !selection.hasChildren &&
                !validateConditional(
                  selection.conditional,
                  selection.lastAlert?.createdAt || null,
                  errorToast,
                )
              ) {
                return;
              }
              dispatch({ type: 'select-child', reason: selection });
            }}
          />
        )}

        {/* Modal for selecting alert customizations */}
        {reason && currentStep === 'select-customization' && (
          <PatientCallCustomizationModal
            show={currentStep === 'select-customization'}
            reason={reason}
            onHide={() => dispatch({ type: 'reset', admissionId })}
            onComplete={(customData) =>
              dispatch({
                type: 'select-customization',
                customization: customData,
              })
            }
          />
        )}
      </Can>
    </>
  );
};

export default PatientCallTriggerPage;
