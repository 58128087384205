import * as React from 'react';
import classNames from 'classnames';

import styles from './Modal.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
}

export const ModalFooter: React.FunctionComponent<Props> = ({
  className,
  children,
  ...htmlElementProps
}: Props) => (
  <div className={classNames(styles.footer, className)} {...htmlElementProps}>
    {children}
  </div>
);

export default ModalFooter;
