import React from 'react';

import WebSocketContext from '../contexts/websocketContext';
import * as ws from '../services/websocket';

export interface Props {
  children: React.ReactNode;
}

/**
 * <WebSocketProvider> component
 */
const WebSocketProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [wsState, setWSState] = React.useState(ws.defaultWebSocketState);

  // Allow graphql service to set this component state on
  // WebSocket state changes (mainly due to token refreshes)
  React.useEffect(() => {
    ws.setWebSocketSetterRef(setWSState);
    return () => {
      ws.setWebSocketSetterRef(undefined);
    };
  }, [setWSState]);

  return (
    <WebSocketContext.Provider value={wsState}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
