import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useTheme } from '@quality24/design-system';
import merge from 'deepmerge';

import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import NavbarTemplate from '@/inpatient-patient-pwa/templates/NavbarTemplate';
import HomePage from '@/inpatient-patient-pwa/pages/home/HomePage';
import PatientCallTriggerPage from '@/inpatient-patient-pwa/pages/patientCall/home/PatientCallTriggerPage';
import PatientCallTrackingPage from '@/inpatient-patient-pwa/pages/patientCall/tracking/PatientCallTrackingPage';
import PatientCallEvaluationPage from '@/inpatient-patient-pwa/pages/patientCall/evaluation';
import DailySurveyPage from '@/inpatient-patient-pwa/pages/survey/DailySurveyPage';
import HospitalInformationPage from '@/inpatient-patient-pwa/pages/hospitalInformation';
import PushNotificationProvider from '@/inpatient-patient-pwa/providers/PushNotificationProvider';
import { Theme } from '@/inpatient-patient-pwa/types/theme';
import NavbarProvider from '@/inpatient-patient-pwa/contexts/navbar/NavbarProvider';
import PrivateRoute from './PrivateRoute';
import { defaultTheme } from '../styles/theme';

/**
 * <PrivateRouter> component.
 */
const PrivateRouter: React.FunctionComponent = () => {
  const { user } = useAuth<Theme>();
  const { theme, updateTheme } = useTheme();
  const clientTheme = user.hospitalConfig?.theme?.default;

  React.useEffect(() => {
    if (clientTheme) {
      updateTheme(merge(defaultTheme as Partial<Theme>, clientTheme));
    }
  }, [clientTheme, updateTheme]);

  React.useEffect(
    () =>
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', theme.color.primary),
    [theme.color.primary],
  );

  return (
    <PushNotificationProvider>
      <NavbarProvider>
        <NavbarTemplate>
          <Routes>
            {/* Main data route */}
            <Route
              path="/"
              element={
                <PrivateRoute action="home-page:view">
                  <HomePage />
                </PrivateRoute>
              }
            />

            {/* Hospital Information route - */}
            <Route
              path="information"
              element={
                <PrivateRoute action="home-page:view">
                  <HospitalInformationPage />
                </PrivateRoute>
              }
            />

            {/* Patient call routes */}
            <Route path="calls">
              <Route
                index
                element={
                  <PrivateRoute action="calls-page:view">
                    <PatientCallTrackingPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="assistance"
                element={
                  <PrivateRoute action="patient-call-assistance-section:view">
                    <PatientCallTriggerPage category="ASSISTANCE" />
                  </PrivateRoute>
                }
              />
              <Route
                path="others"
                element={
                  <PrivateRoute action="patient-call-infra-section:view">
                    <PatientCallTriggerPage category="INFRASTRUCTURE" />
                  </PrivateRoute>
                }
              />
              {/* Call Evaluation routes */}
              <Route
                path="eval/:evalId"
                element={
                  <PrivateRoute action="patient-call-evaluation:answer">
                    <PatientCallEvaluationPage />
                  </PrivateRoute>
                }
              />
            </Route>

            {/* Survey routes */}
            <Route path="surveys">
              <Route
                path=":surveyId"
                element={
                  <PrivateRoute action="daily-survey-page:view">
                    <DailySurveyPage />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </NavbarTemplate>
      </NavbarProvider>
    </PushNotificationProvider>
  );
};

export default PrivateRouter;
