import * as React from 'react';
import { gql, useMutation } from '@apollo/client';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Heading,
  Icon,
  Surface,
  Text,
  getIcon,
} from '@quality24/design-system';
import { InformationAccessMetric, UUIDv4 } from '@quality24/inpatient-typings';
import moment from 'moment';

import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import { getRoomId } from '@/inpatient-patient-pwa/services/storage';
import useCustomData from '@/inpatient-patient-pwa/hooks/useCustomData';
import useUserParams, {
  UserParams,
} from '@/inpatient-patient-pwa/hooks/useUserParams';
import ContentModalTrigger from './ContentModalTrigger';
import { HospitalInformation } from './types';

import styles from './HospitalInformationContent.module.scss';

export const CREATE_INFO_METRIC_MUTATION = gql`
  mutation createInfoMetricMutation(
    $input: CreateInformationAccessMetricInput!
  ) {
    createInformationAccessMetric(input: $input) {
      informationAccessMetric {
        id
      }
    }
  }
`;

export const UPDATE_INFO_METRIC_MUTATION = gql`
  mutation updateInfoMetricMutation($id: UUID!, $closedAt: Datetime!) {
    updateInformationAccessMetricById(
      input: { patch: { closedAt: $closedAt }, id: $id }
    ) {
      informationAccessMetric {
        id
      }
    }
  }
`;

export interface Props {
  information: HospitalInformation[];
  wifiLogin?: string | null;
  wifiPassword?: string | null;
}

interface CreateInfoMetricResult {
  createInformationAccessMetric: {
    informationAccessMetric: Pick<InformationAccessMetric, 'id'>;
  };
}

interface UpdateInfoMetricResult {
  updateInformationAccessMetric: {
    informationAccessMetric: Pick<InformationAccessMetric, 'id'>;
  };
}

/**
 * Generate component according to type
 */
const generateComponent = ({
  info,
  wifiLogin,
  wifiPassword,
  navigate,
  createInfoMetric,
  updateInfoMetric,
  userParams,
}: {
  info: HospitalInformation;
  wifiLogin?: string | null;
  wifiPassword?: string | null;
  navigate?: (value: string) => void;
  createInfoMetric?: (informationSectionId: UUIDv4) => void;
  updateInfoMetric?: () => void;
  userParams?: UserParams;
}): JSX.Element | null => {
  if (info.children && info.children.length > 0) {
    return (
      <Button
        key={info.id}
        className={styles.button}
        size="lg"
        icon={info.data?.icon ? getIcon(info.data.icon) : undefined}
        onClick={() => navigate?.(`?parent_id=${info.id}`)}
        fluid
      >
        {info.data?.buttonLabel || 'Informações Adicionais'}
      </Button>
    );
  }

  if (info.type === 'WIFI_SECTION') {
    return (
      <div key={info.id} className="d-flex flex-column gap-2">
        <Heading as="h4">
          <Icon icon={faWifi} size="sm" /> Wi-fi
        </Heading>
        <Surface padding="sm">
          <Text>
            Usuário: <strong>{wifiLogin ?? ''}</strong>
          </Text>
        </Surface>
        <Surface padding="sm">
          <Text>
            Senha: <strong>{wifiPassword ?? ''}</strong>
          </Text>
        </Surface>
      </div>
    );
  }

  if (info.type === 'SECTION_TITLE') {
    return (
      <Heading as="h4" key={info.id} onClick={(e) => e.stopPropagation()}>
        {info.data?.title}
      </Heading>
    );
  }

  if (info.type === 'CONTENT_BUTTON') {
    return (
      <ContentModalTrigger
        key={info.id}
        content={info}
        createInfoMetric={createInfoMetric}
        updateInfoMetric={updateInfoMetric}
      />
    );
  }

  if (info.type === 'LINK_BUTTON') {
    try {
      // The URL constructor will throw an exception if the passed URL is not valid
      const url = new URL(info.data?.url || '');

      if (userParams && (info.data?.userParams?.length || 0) > 0) {
        info.data?.userParams?.forEach((param) => {
          const userParam = userParams[param.name];
          if (userParam) {
            url.searchParams.append(param.alias || param.name, userParam);
          }
        });
      }

      return (
        <Button
          className={styles.button}
          icon={info.data?.icon ? getIcon(info.data.icon) : undefined}
          key={info.id}
          onClick={() => {
            if (info.trackable) {
              createInfoMetric?.(info.id);
            }
            window.open(url.toString(), '_blank');
          }}
          size="lg"
          fluid
        >
          {info.data?.buttonLabel}
        </Button>
      );
    } catch {
      // eslint-disable-next-line no-console
      console.error(`Invalid URL ${info.data?.url}`);
      return null;
    }
  }

  return null;
};

const HospitalInformationContent: React.FunctionComponent<Props> = ({
  information,
  wifiLogin = '',
  wifiPassword = '',
}) => {
  const { generalInfo } = useCustomData();
  const { user } = useAuth();

  const [informationAccessMetricId, setInformationAccessMetricId] =
    React.useState<string | undefined | null>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userParams = useUserParams();

  const parentId = searchParams.get('parent_id');

  const parentItem = React.useMemo(
    () => (parentId ? information.find((i) => i.id === parentId) : undefined),
    [information, parentId],
  );

  const [createInfoMetricMutation] = useMutation<CreateInfoMetricResult>(
    CREATE_INFO_METRIC_MUTATION,
  );

  const [updateInfoMetricMutation] = useMutation<UpdateInfoMetricResult>(
    UPDATE_INFO_METRIC_MUTATION,
  );

  const createInfoMetric = React.useCallback(
    async (informationSectionId: string) => {
      const result = await createInfoMetricMutation({
        variables: {
          input: {
            informationAccessMetric: {
              roomId: getRoomId(),
              admissionId: user.admissionId,
              informationSectionId,
              openedAt: moment().toISOString(),
            },
          },
        },
      });
      setInformationAccessMetricId(
        result.data?.createInformationAccessMetric.informationAccessMetric.id,
      );
    },
    [createInfoMetricMutation, user.admissionId],
  );

  const updateInfoMetric = React.useCallback(async () => {
    if (informationAccessMetricId) {
      await updateInfoMetricMutation({
        variables: {
          id: informationAccessMetricId,
          closedAt: moment().toISOString(),
        },
      });
    }
  }, [informationAccessMetricId, updateInfoMetricMutation]);

  return (
    <div className="d-flex flex-column gap-4">
      <Heading as="h2" size="lg1" weight="semiBold">
        {parentItem?.data?.title || generalInfo}
      </Heading>
      {parentItem?.data.content && <Text>{parentItem?.data.content}</Text>}
      <div className="d-flex flex-column gap-2 w-100">
        {(parentItem?.children || []).length > 0
          ? parentItem?.children?.map((info) =>
              generateComponent({
                info,
                wifiLogin,
                wifiPassword,
                navigate,
                createInfoMetric,
                updateInfoMetric,
                userParams,
              }),
            )
          : information.map((info) =>
              generateComponent({
                info,
                wifiLogin,
                wifiPassword,
                navigate,
                createInfoMetric,
                updateInfoMetric,
                userParams,
              }),
            )}
      </div>
    </div>
  );
};

export default HospitalInformationContent;
