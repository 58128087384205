import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import pushContext from '@/inpatient-patient-pwa/contexts/pushContext';
import { requireToken } from '@/inpatient-patient-pwa/services/firebase';
import { getLoginId } from '@/inpatient-patient-pwa/services/storage';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';

const SUBSCRIBE_MUTATION = gql`
  mutation PushSubscription($input: RegisterPushTokenInput!) {
    registerPushToken(input: $input) {
      platformEndpoint
    }
  }
`;

export interface Props {
  children: React.ReactNode;
}

/**
 * <PushNotificationProvider> component.
 */
const PushNotificationProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const {
    user: { admissionId },
  } = useAuth();

  const [saveToken] = useMutation(SUBSCRIBE_MUTATION);

  /**
   * Saves the push token in the API
   */
  const savePushTokenMutation = React.useCallback(
    async (subscriptionToken) => {
      try {
        await saveToken({
          variables: {
            input: {
              deviceId: getLoginId(),
              appId: uuid(),
              appType: 'PWA',
              deviceToken: subscriptionToken,
              admissionId,
            },
          },
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(
          `Não foi possível realizar o cadastro para receber notificações `,
          err,
        );
      }
    },
    [admissionId, saveToken],
  );

  React.useEffect(() => {
    if (requireToken) {
      requireToken({
        onSuccess: savePushTokenMutation, // VERIFICAR SE TA DUPLICADFO
      });
    }
  }, [admissionId, savePushTokenMutation]);

  const contextValue = React.useMemo(() => ({}), []);

  return (
    <pushContext.Provider value={contextValue}>{children}</pushContext.Provider>
  );
};

export default PushNotificationProvider;
