import React from 'react';

export interface MaintenanceModeProviderProps {
  children: React.ReactNode;
  apiURL: string;
  headers?: Record<string, string>;
}

export interface ContextProviderProps {
  isEnabled: boolean;
  enableMaintenanceMode: () => void;
  disableMaintenanceMode: () => void;
}

export const MaintenanceModeContext = React.createContext<ContextProviderProps>(
  {
    isEnabled: false,
    enableMaintenanceMode: () => {},
    disableMaintenanceMode: () => {},
  },
);

export const useMaintenanceMode = (): ContextProviderProps =>
  React.useContext(MaintenanceModeContext);

export const MaintenanceModeProvider: React.FunctionComponent<
  MaintenanceModeProviderProps
> = ({ children, apiURL, headers }) => {
  const [isEnabled, setIsEnabled] = React.useState<boolean>(false);

  const enableMaintenanceMode = React.useCallback(() => {
    if (isEnabled) return;
    setIsEnabled(true);

    fetch(apiURL || '', { headers }).then((response) => {
      if (response.status !== 503) {
        setIsEnabled(false);
        return;
      }

      const timeInSeconds = Number(response.headers.get('Retry-After')) || 60;
      const retryAfter = timeInSeconds * 1000;

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log({
          message: `Maintenance mode enabled. Will retry in ${timeInSeconds} seconds`,
        });
      }

      const interval = setInterval(() => {
        fetch(apiURL || '', { headers }).then((innerResponse) => {
          if (innerResponse.status !== 503) {
            setIsEnabled(false);
            clearInterval(interval);
          }

          if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log({
              time: new Date().toLocaleTimeString(),
              message: 'Did fetch',
              status: innerResponse.status,
            });
          }
        });
      }, retryAfter);
    });
  }, [apiURL, headers, isEnabled]);

  const disableMaintenanceMode = React.useCallback(() => {
    setIsEnabled(false);
  }, []);

  const context = React.useMemo<ContextProviderProps>(
    () => ({
      isEnabled,
      enableMaintenanceMode,
      disableMaintenanceMode,
    }),
    [disableMaintenanceMode, enableMaintenanceMode, isEnabled],
  );

  return (
    <MaintenanceModeContext.Provider value={context}>
      {children}
    </MaintenanceModeContext.Provider>
  );
};
