/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Ref: https://github.com/newsiberian/apollo-link-token-refresh/blob/master/src/tokenRefreshLink.ts
 */
import {
  ApolloLink,
  Observable,
  Operation,
  NextLink,
  FetchResult,
} from '@apollo/client/core';

import { OperationQueue } from './queue';
import * as storage from '../../services/storage';
import { retryRefreshToken } from '../../services/graphql';

/**
 * Checks if current token is valid or
 * @param _operation
 * @returns
 */
const isTokenValidOrUndefined = (_operation: Operation) => {
  const token = storage.getDecodedToken();
  if (token === null) return true;

  /**
   * @note RFC 7519 - Expires In (token.exp) is stored as UNIX timestamp
   */
  const exp = (token as { exp: number })?.exp;
  return Math.floor(Date.now() / 1000) < exp;
};

export interface Options {
  /**
   * This is the a name of access token field in response.
   * In some scenarios we want to pass additional payload with access token,
   * i.e. new refresh token, so this field could be the object's name.
   */
  apiUrl: string;
}

/**
 * TokenRefreshLink. This link should be used
 */
export class TokenRefreshLink extends ApolloLink {
  private apiUrl: string;

  private fetching: boolean;

  private queue: OperationQueue;

  constructor(params: Options) {
    super();

    this.apiUrl = params.apiUrl;
    this.fetching = false;
    this.queue = new OperationQueue();
  }

  /**
   * Main chain function
   */
  public request(
    operation: Operation,
    forward: NextLink,
  ): Observable<FetchResult> | null {
    if (typeof forward !== 'function') {
      throw new Error(
        '[Token Refresh Link]: Token Refresh Link is a non-terminating link and should not be the last in the composed chain',
      );
    }

    // If token does not exist, which could mean that this is a not registered
    // user request, or if it is not expired -- act as always
    if (isTokenValidOrUndefined(operation)) {
      return forward(operation);
    }

    if (!this.fetching) {
      this.fetching = true;

      const refreshToken = storage.getRefreshToken() as string;

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.debug('TokenRefreshLink: refreshing expired token', {
          operation,
          refreshToken,
        });
      }

      retryRefreshToken(this.apiUrl, refreshToken).finally(() => {
        this.fetching = false;

        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.debug(
            'TokenRefreshLink: consuming operation queue after refresh',
          );
        }
        this.queue.consumeQueue();
      });
    }

    return this.queue.enqueueRequest({
      operation,
      forward,
    });
  }
}
