import * as React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { ColorStyleProps, FontWeightProps } from '../../../@types/theme';
import Text from '../Text';

import styles from './Badge.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  display?: 'flex' | 'inline-flex';
  icon?: FontAwesomeIconProps['icon'];
  weight?: FontWeightProps;
  textColor?: ColorStyleProps;
  variant?: 'primary' | 'success' | 'danger' | 'warning' | 'info';
  children: React.ReactNode;
}

export const Badge: React.FunctionComponent<Props> = ({
  className,
  style,
  children,
  icon,
  weight,
  textColor,
  display = 'inline-flex',
  variant = 'primary',
  ...htmlElementProps
}) => {
  const cls = classNames(styles.badge, variant, className);

  return (
    <div className={cls} style={{ display, ...style }} {...htmlElementProps}>
      {icon && <FontAwesomeIcon icon={icon} />}
      <Text
        as="span"
        size="sm2"
        weight={weight}
        color={textColor}
        style={{ display }}
      >
        {children}
      </Text>
    </div>
  );
};

export default Badge;
