import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import * as React from 'react';
import Text from '../Text';

import styles from './TextArea.module.scss';

export interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  rows?: number;
  label?: string;
  placeholder?: string;
  value?: string;
  resizable?: boolean;
  helperText?: string;
  errorText?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      id,
      className,
      style,
      name,
      rows = 5,
      label,
      placeholder,
      value,
      resizable = false,
      helperText,
      errorText,
      disabled,
      onChange,
      maxLength,
      ...props
    },
    ref,
  ) => {
    const inputId = id ?? uuid();

    const cls = classNames(styles['label-wrapper'], className, {
      disabled,
      error: !!errorText,
    });

    return (
      <div className={cls} style={style}>
        <Text as="label" htmlFor={inputId} className="mb-2" weight="bold">
          {label}
        </Text>

        <textarea
          id={inputId}
          name={name}
          className={classNames({ error: !!errorText })}
          style={{ resize: resizable ? 'inherit' : 'none' }}
          ref={ref}
          rows={rows}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
          {...props}
        />

        <div className="d-flex flex-justify-between gap-2">
          {helperText && !errorText && (
            <Text as="span" className="mt-2" size="sm1">
              {helperText}
            </Text>
          )}
          {errorText && (
            <Text as="span" className="mt-2" color="red400" size="sm1">
              {errorText}
            </Text>
          )}

          {maxLength && (
            <Text
              as="span"
              className={classNames('mt-2', styles['length-notice'])}
              style={{ textAlign: helperText || errorText ? 'end' : 'start' }}
              color="disabled"
              size="sm1"
            >
              {maxLength - (value?.length ?? 0) === 1
                ? 'Resta 1 caractere'
                : `Restam ${maxLength - (value?.length ?? 0)} caracteres`}
            </Text>
          )}
        </div>
      </div>
    );
  },
);

export default TextArea;
