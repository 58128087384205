import { gql } from '@apollo/client';

export const GET_EMPLOYEES_BY_NAME = gql`
  query SearchEmployeeByName($name: String) {
    employees(first: 10, filter: { name: { includesInsensitive: $name } }) {
      nodes {
        id
        name
        registry
        enabled
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_REGISTRY = gql`
  query SearchEmployeeByRegistry($registry: String) {
    employees(
      first: 1
      filter: { registry: { contains: { hospitalRegistry: $registry } } }
    ) {
      nodes {
        id
        name
        registry
        enabled
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query GetEmployeeById($id: UUID!) {
    employee: employeeById(id: $id) {
      id
      name
      registry
      enabled
    }
  }
`;
