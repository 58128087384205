import React from 'react';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  NormalizedCacheObject,
  split,
  HttpLink,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useMaintenanceMode } from '@quality24/react-maintenance-mode';

import { AuthPayload } from '../../@types/auth';

import { headers as projectHeaders } from '../../services/http';
import subscriptionClient from '../../services/websocket';

import { logout } from '../../services/auth';
import { cache, retryRefreshToken } from '../../services/graphql';
import { getIdToken, getRefreshToken } from '../../services/storage';
import { TokenRefreshLink } from './TokenRefreshLink';

// //////////////////////////////////////////////
// Types
// //////////////////////////////////////////////

export interface Props {
  children: React.ReactNode;
  apiURL: string;
}

/**
 * Stores the promise of a refresh operation.
 * Note: store it globally in order not to run multiple
 * refreshes at the same time
 */

let refreshingPromise: Promise<AuthPayload | void | null> | null = null;

// //////////////////////////////////////////////
// Custom fetch definition
// //////////////////////////////////////////////

/**
 * This fetch is passed to the BatchHttpLink (apollo link)
 * for handling re-authorization.
 */
const fetcher = (uri: RequestInfo | URL, options: RequestInit | undefined) =>
  fetch(uri, {
    ...options,
    headers: { ...options?.headers, ...projectHeaders },
  }).then((response) => response);

// //////////////////////////////////////////////
// Link definitions
// //////////////////////////////////////////////

// Create the HTTP auth middleware
export const authLink = setContext((_ignore, { headers }) => {
  const token = getIdToken();
  if (!token) return headers;

  return {
    ...headers,
    headers: { authorization: `Bearer ${token}` },
  };
});

// Create WebSocket link
export const wsLink = new GraphQLWsLink(subscriptionClient);

const ExtendedApolloProvider: React.FunctionComponent<Props> = ({
  children,
  apiURL,
}) => {
  const { enableMaintenanceMode, isEnabled: isMaintenanceModeEnabled } =
    useMaintenanceMode();

  // Create an Batch HTTP link
  const httpLink = React.useMemo(
    () =>
      new HttpLink({
        uri: apiURL,
        fetch: fetcher,
      }),
    [apiURL],
  );

  // Create the refresh token link
  const refreshTokenLink = React.useMemo(
    () => new TokenRefreshLink({ apiUrl: apiURL }),
    [apiURL],
  );

  const errorLink = React.useMemo(
    () =>
      onError(({ graphQLErrors, networkError, forward, operation }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach((error) => {
            if (error && 'statusCode' in error) {
              // Forbidden graphQL error inside Apollo response. Happens when authentication is not valid.
              if (error.statusCode === 403) {
                logout();
                return;
              }

              // Unauthorized graphQL error inside Apollo response. Happens on expired token.
              if (error.statusCode === 401) {
                // Refresh token if not yet running an operation
                if (!refreshingPromise && apiURL) {
                  const refreshToken = getRefreshToken() as string;
                  refreshingPromise = retryRefreshToken(apiURL, refreshToken);

                  // Retry current operation on promise resolved (run after default handlers)
                  refreshingPromise.then(
                    (authPayload: void | AuthPayload | null) => {
                      if (!authPayload) return;

                      // Retry previous operation
                      const prevContext = operation.getContext();
                      const newContext = {
                        ...prevContext,
                        headers: {
                          authorization: `Bearer ${authPayload.idToken}`,
                        },
                      };

                      operation.setContext(newContext);

                      forward(operation);
                    },
                  );
                }
              }
            }
          });
        }

        if (networkError && 'statusCode' in networkError) {
          // Forbidden graphQL error inside Apollo response. Happens when authentication is not valid.
          if (networkError.statusCode === 403) {
            logout();
            return;
          }
          // Unauthorized graphQL error inside Apollo response. Happens on expired token.
          if (networkError.statusCode === 401) {
            // Refresh token if not yet running an operation
            if (!refreshingPromise) {
              const refreshToken = getRefreshToken() as string;
              refreshingPromise = retryRefreshToken(apiURL, refreshToken);

              // Retry current operation on promise resolved (run after default handlers)
              refreshingPromise.then(
                (authPayload: void | AuthPayload | null) => {
                  if (!authPayload) return;

                  // Retry previous operation
                  const prevContext = operation.getContext();
                  const newContext = {
                    ...prevContext,
                    headers: {
                      authorization: `Bearer ${authPayload.idToken}`,
                    },
                  };

                  operation.setContext(newContext);

                  forward(operation);
                },
              );
            }
          }
          // maintenance mode
          if (
            networkError.statusCode === 503 &&
            networkError.response.headers.has('Retry-After')
          ) {
            enableMaintenanceMode();
          }
        }
      }),
    [enableMaintenanceMode, apiURL],
  );

  const client: ApolloClient<NormalizedCacheObject> = React.useMemo(
    () =>
      new ApolloClient({
        link: split(
          // Split based on operation type
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          wsLink,
          ApolloLink.from([errorLink, refreshTokenLink, authLink, httpLink]),
        ),
        cache,

        // Set local storage resolvers
        defaultOptions: {
          watchQuery: { fetchPolicy: 'cache-and-network' },
        },
      }),
    [errorLink, httpLink, refreshTokenLink],
  );

  React.useEffect(() => {
    /**
     * Attach connection listeners
     */
    const unsubscribeConnectedListener = subscriptionClient.on(
      'connected',
      () => {
        // Reset apollo store to refetch all active queries. This side-effect is suggested in
        // https://github.com/apollographql/apollo-client/issues/1831#issuecomment-311442700
        client.reFetchObservableQueries();
        // update...
      },
    );

    const unsubscribeErrorListener = subscriptionClient.on('error', () => {
      console.info('WebSocket error.'); // eslint-disable-line no-console
      if (!isMaintenanceModeEnabled) {
        client
          .query({
            query: gql`
              query FetchApiVersion {
                apiVersion {
                  versionName
                }
              }
            `,
          })
          .catch((error) => {
            if (
              error?.networkError?.statusCode === 503 &&
              error?.networkError?.response?.headers.get('Retry-After')
            ) {
              enableMaintenanceMode();
            }
          });
      }
    });

    return () => {
      unsubscribeErrorListener();
      unsubscribeConnectedListener();
    };
  }, [apiURL, client, enableMaintenanceMode, isMaintenanceModeEnabled]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ExtendedApolloProvider;
