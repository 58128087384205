import React from 'react';
import { AlertEvaluation } from '@quality24/inpatient-typings';
import { useNavigate } from 'react-router-dom';
import { Badge, BadgeProps, Button } from '@quality24/design-system';

export interface Props {
  evaluation: Pick<AlertEvaluation, 'id' | 'value' | 'status'>;
  className?: string;
}

const smileyScores: {
  value: number;
  label: string;
  variant: BadgeProps['variant'];
}[] = [
  { value: 1, label: 'Péssimo', variant: 'danger' },
  { value: 2, label: 'Ruim', variant: 'danger' },
  { value: 3, label: 'Regular', variant: 'warning' },
  { value: 4, label: 'Bom', variant: 'success' },
  { value: 5, label: 'Ótimo', variant: 'success' },
];

const CallEvaluationButton: React.FunctionComponent<Props> = ({
  evaluation,
  className,
}) => {
  const navigate = useNavigate();

  if (evaluation?.value?.score) {
    let variant: BadgeProps['variant'] | undefined = 'success';
    variant =
      evaluation?.value?.score >= 5 && evaluation?.value?.score < 8
        ? 'warning'
        : variant;
    variant = evaluation?.value?.score < 5 ? 'danger' : variant;

    return (
      <Badge
        variant={variant}
        className={className}
      >{`Nota: ${evaluation.value.score}`}</Badge>
    );
  }

  if (evaluation?.value?.smileyScore) {
    const score = evaluation?.value?.smileyScore as number;

    const scoreObj = smileyScores.find((l) => l.value === score);

    return (
      <Badge variant={scoreObj?.variant} className={className}>
        {scoreObj?.label}
      </Badge>
    );
  }

  return (
    <Button
      onClick={() => navigate(`calls/eval/${evaluation.id}`)}
      variant="outline"
      className={className}
    >
      Avaliar
    </Button>
  );
};

export default CallEvaluationButton;
