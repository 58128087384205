import jwtDecode from 'jwt-decode';
import { AuthenticationResult, UUIDv4 } from '@quality24/inpatient-typings';

import { deleteAuthPayload, storeAuthPayload } from './storage';
import { headers as projectHeaders } from './http';
import { disconnectWebsocket } from './websocket';
import { AuthPayload, AuthUserRole, LoginState } from '../@types/auth';

/**
 * The default auth payload format stored in the browser cookies
 */
export const defaultAuthPayload: AuthPayload = {
  status: LoginState.NONE,
  accessToken: '',
  refreshToken: '',
  idToken: '',
  challenge: null,
  user: { role: 'GUEST' },
};

interface DecodedIdToken {
  hospital_id: string;
  role: string;
  [key: string]: string;
}

/**
 * Parses the AuthFlowPayload into the format to store in the browser cookies
 * @param {*} authFlow
 * @returns
 */
export const parseAuthFlowResult = (
  auth: AuthenticationResult,
): AuthPayload => {
  // Decode the ID token
  const identity = auth.idToken
    ? jwtDecode<DecodedIdToken>(auth.idToken)
    : { hospital_id: undefined, role: 'guest' };

  const role =
    'custom:role' in identity ? identity['custom:role'] : identity.role;
  // Build auth payload
  const authPayload = {
    status: LoginState.AUTHENTICATED,
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
    idToken: auth.idToken,
    user: {
      ...auth.userData,
      hospitalId: ('custom:hospital_id' in identity
        ? identity['custom:hospital_id']
        : identity.hospital_id) as UUIDv4,
      role: role.toUpperCase() as AuthUserRole,
    },
  };

  return authPayload;
};

/**
 * Refreshes the authorization token using the
 * stored refreshToken
 * @param {String} uri the URI for the request.
 * @returns
 */
export const refreshAuthToken = (
  uri: string,
  refreshToken: string,
): Promise<Response> => {
  // Create the body
  const body = {
    operationName: 'RefreshAuthToken',
    variables: { refreshToken },
    query: `
      mutation RefreshAuthToken($refreshToken: String!) {
        authFlow: refreshDeviceTokenWithType(
          input: {
            refreshToken: $refreshToken
            appType: PWA_APP
          }
        ) {
          ...AuthFlowFragment
        }
      }
      fragment AuthFlowFragment on AuthFlowPayload {
        session
        username
        challengeName
        challengeParameters
        authenticationResult {
          tokenType
          expiresIn
          accessToken
          refreshToken
          idToken
          userData {
            modules
            department
            departmentId
            floorPermissions
            hospitalConfig
          }
        }
      }
    `,
  };

  // Get fetch options
  const options = {
    method: 'POST',
    headers: {
      accept: '*/*',
      'content-type': 'application/json',
      ...projectHeaders,
    },
    body: JSON.stringify(body),
  };

  return fetch(uri, options);
};

//
// Auth Payload State Updater
//

export type AuthPayloadSetter = (payload: AuthPayload) => void;

let updateAuthPayload: AuthPayloadSetter | undefined;

/**
 * Sets the auth payload setter
 * @param {*} setter the state setter
 */
export const setAuthPayloadSetterRef = (setter?: AuthPayloadSetter): void => {
  updateAuthPayload = setter;
};

/**
 * Updates the auth payload state
 */
export const setAuthPayload = (payload = defaultAuthPayload): void => {
  storeAuthPayload(payload);
  if (updateAuthPayload) {
    updateAuthPayload(payload);
  }
};

/**
 * Logs user in.
 * @param {*} payload the auth flow payload
 */
export const login = (payload: AuthPayload): void => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.debug('auth: logging user in');
  }
  // Disconnect from subscription websocket
  disconnectWebsocket();
  // Set auth payload and cookies
  setAuthPayload(payload);
};

/**
 * Log user out
 */
export const logout = (): void => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.debug('auth: logging user out');
  }
  // Set auth payload and cookies to default value
  setAuthPayload();
  // Erases browser cookies and localstorage
  deleteAuthPayload();
  // Disconnect from subscription websocket
  disconnectWebsocket();
};
