import React from 'react';

import { AuthContext, LoginState } from '@/inpatient-patient-pwa/types/auth';

const authContext = React.createContext<AuthContext>({
  status: LoginState.NONE,
  accessToken: '',
  user: {
    role: 'GUEST',
  },
  login: () => Promise.resolve({}),
  logout: () => {},
});

export default authContext;
