import * as React from 'react';
import classNames from 'classnames';
import { Icon, Pill, Text, getIcon } from '@quality24/design-system';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import styles from './OrderCard.module.scss';

export interface Props {
  className?: string;
  icon?: string;
  disabled?: boolean;
  title: string;
  onSelect: () => void;
  direction?: 'column' | 'row';
}

const OrderCard: React.FunctionComponent<Props> = ({
  className,
  icon = 'FaQuestion',
  disabled = false,
  title,
  onSelect,
  direction = 'column',
}) => (
  <button
    type="button"
    className={classNames(className, styles.wrapper, {
      disabled,
      [`card-${direction}`]: direction,
    })}
    onClick={!disabled ? onSelect : undefined}
  >
    <Icon icon={getIcon(icon) || faQuestionCircle} color="white" />
    <div>
      <Text weight="semiBold" color="white">
        {title}
      </Text>
      {disabled && <Pill value="em andamento" />}
    </div>
  </button>
);

export default OrderCard;
