import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Hospital } from '@quality24/inpatient-typings';

import logo from './default-logo.png';

const SELECT_QUERY = gql`
  query FetchHospitalLogo($hospitalId: UUID!) {
    hospital: hospitalsViewById(id: $hospitalId) {
      id
      logo
      config
    }
  }
`;

export interface QueryResult {
  hospital?: Pick<Hospital, 'id' | 'logo' | 'config'>;
}

export interface Props {
  className?: string;
  hospitalId?: string;
  defaultLogo?: string;
  height?: string | number;
  width?: string | number;
  style?: React.CSSProperties;
}

const HospitalLogo: React.FunctionComponent<Props> = ({
  className,
  hospitalId,
  defaultLogo = logo,
  height,
  width,
  style,
}) => {
  const { data, loading, error } = useQuery<QueryResult>(SELECT_QUERY, {
    variables: { hospitalId },
    skip: !hospitalId,
  });

  const logoUrl = React.useMemo(() => {
    if (hospitalId && data?.hospital?.config?.logos) {
      const { logos } = data.hospital.config;
      return logos.dark || logos.light || defaultLogo;
    }
    if (hospitalId && data?.hospital?.logo) {
      return data.hospital.logo;
    }
    return defaultLogo;
  }, [data?.hospital?.config, data?.hospital?.logo, defaultLogo, hospitalId]);

  return !loading && !error ? (
    <img
      className={className}
      style={style}
      src={logoUrl}
      alt="Logo Hospital"
      height={height}
      width={width}
    />
  ) : null;
};

export default HospitalLogo;
