import * as React from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
}

export const CardBody: React.FunctionComponent<Props> = ({
  className,
  children,
  ...htmlElementProps
}: Props) => (
  <div className={classNames(styles.body, className)} {...htmlElementProps}>
    {children}
  </div>
);

export default CardBody;
