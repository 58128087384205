import * as React from 'react';
import classNames from 'classnames';

import CardHeader, { Props as CardHeaderProps } from './CardHeader';
import CardBody, { Props as CardBodyProps } from './CardBody';
import CardFooter, { Props as CardFooterProps } from './CardFooter';

import styles from './Card.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
}

interface CardSubComponents {
  Header: React.FunctionComponent<CardHeaderProps>;
  Body: React.FunctionComponent<CardBodyProps>;
  Footer: React.FunctionComponent<CardFooterProps>;
}

export const Card: React.FunctionComponent<Props> & CardSubComponents = ({
  className,
  children,
  ...htmlElementProps
}: Props) => (
  <div className={classNames(styles.card, className)} {...htmlElementProps}>
    {children}
  </div>
);

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;
