import * as React from 'react';
import { ReleasedAdmissionSurvey } from '@quality24/inpatient-typings';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { faMessageHeart } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Text } from '@quality24/design-system';
import classNames from 'classnames';

export type Survey = Pick<
  ReleasedAdmissionSurvey,
  'id' | 'surveyId' | 'createdAt' | 'status'
> | null;

export interface Props {
  className?: string;
  survey: Survey;
  additionalText?: string;
}

const SurveyNotificationAlert: React.FunctionComponent<Props> = ({
  className,
  survey,
  additionalText,
}) => {
  const navigate = useNavigate();
  const weekDay = moment(survey?.createdAt).format('dddd');
  const message = `nest${
    ['sábado', 'domingo'].includes(weekDay) ? 'e' : 'a'
  } ${weekDay}?`;

  return (
    <button
      className={classNames(className, 'border-0 p-0 bg-transparent text-left')}
      type="button"
      onClick={() => navigate(`/surveys/${survey?.id}`)}
    >
      <div className="d-flex flex-column align-items-start gap-2 bg-primaryLighter rounded-md p-3">
        <Icon icon={faMessageHeart} color="primary" size="md" />
        <Text as="span" color="blackLight">
          Que tal nos contar <strong>como foi a sua experiência</strong>
          {additionalText ? <strong> {additionalText} </strong> : ' '}
          {message}
        </Text>
        <Text
          as="span"
          className="p-2 bg-primary rounded-md fw-bold"
          color="white"
        >
          clique para responder
        </Text>
      </div>
    </button>
  );
};

export default SurveyNotificationAlert;
