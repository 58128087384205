import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';

import {
  ModalContainer,
  Theme,
  ThemeProvider,
  ToastContainer,
} from '@quality24/design-system';
import { MaintenanceModeProvider } from '@quality24/react-maintenance-mode';
import AuthProvider from '@/inpatient-patient-pwa/contexts/auth/AuthProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import Router from './routes/Router';

import ExtendedApolloProvider from './providers/ExtendedApolloProvider';
import { MaintenanceMode } from './components/molecules/MaintenanceMode';
import { headers } from './services/http';
import { defaultTheme } from './styles/theme';
import runtimeConfig from './config';

import '@quality24/design-system/src/styles/global.scss';
import './styles/global.scss';
import 'moment/locale/pt-br';

// Set moment locale globally
moment.locale('pt-BR');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MaintenanceModeProvider
        apiURL={runtimeConfig.API_URL || process.env.REACT_APP_API_URL || ''}
        headers={headers}
      >
        <ExtendedApolloProvider
          apiURL={runtimeConfig.API_URL || process.env.REACT_APP_API_URL || ''}
        >
          <AuthProvider>
            <ThemeProvider theme={defaultTheme as Partial<Theme>}>
              <ModalContainer />
              <Router />
              <MaintenanceMode />
              <ToastContainer />
            </ThemeProvider>
          </AuthProvider>
        </ExtendedApolloProvider>
      </MaintenanceModeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(`${process.env.PUBLIC_URL}/firebase-sw.js`);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
