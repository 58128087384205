import * as React from 'react';
import classNames from 'classnames';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { SelectionOption } from '../../../containers/SelectionContainer';
import styles from './ListGroup.module.scss';
import { Icon } from '../../atoms';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  value: string | number;
  selected?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<Element>;
  children?: React.ReactNode;
}

const ListItem: React.FunctionComponent<Props> = ({
  className,
  children,
  value,
  selected,
  disabled,
  onClick,
  ...htmlElementProps
}) => (
  <SelectionOption
    value={value}
    selected={selected}
    onClick={!disabled ? onClick : undefined}
    disabled={disabled}
    aria-disabled={disabled}
    className={classNames(styles.item, className, { disabled })}
    {...htmlElementProps}
  >
    <div className="d-flex flex-column">{children}</div>
    {selected && <Icon icon={faCheck} />}
  </SelectionOption>
);

export default ListItem;
