import React from 'react';
import {
  EnumServiceCategory,
  Service,
  Alert,
  UUIDv4,
} from '@quality24/inpatient-typings';
import { Heading, Text } from '@quality24/design-system';
import classNames from 'classnames';

import OrderCard from '@/inpatient-patient-pwa/molecules/OrderCard';
import useCustomData from '../../../hooks/useCustomData';

type PatientCallReasonFragment = Required<
  Pick<
    Service,
    | 'id'
    | 'reason'
    | 'modifier'
    | 'customizationType'
    | 'customizationData'
    | 'actions'
    | 'icons'
    | 'conditional'
  >
>;

interface ComposedPatientCallReason extends PatientCallReasonFragment {
  groupId: UUIDv4;
  lastAlert?: Required<Pick<Alert, 'createdAt'>> | null;
  activeAlert?: Pick<Alert, 'id'>;
  hasChildren?: boolean;
  children?: Array<ComposedPatientCallReason>;
}

export interface Props {
  className?: string;
  reasons: Array<ComposedPatientCallReason>;
  category: EnumServiceCategory;
  onSelect: (reason: ComposedPatientCallReason) => void;
}

/**
 * Checks whether or not the reason can be triggered based on active alerts
 * @param reason
 * @returns
 */
const canBeTriggered = (reason: ComposedPatientCallReason): boolean => {
  if (reason.children?.length) {
    const activeAlerts = reason.children.filter((child) => {
      const innerChildren = child.hasChildren
        ? child.children?.filter((child2) => !!child2.activeAlert)
        : undefined;
      return !innerChildren
        ? !!child.activeAlert
        : innerChildren?.length === child.children?.length;
    });
    return reason.children.length !== activeAlerts.length;
  }
  return !reason.activeAlert;
};

const PatientCallSection: React.FunctionComponent<Props> = ({
  className,
  reasons,
  category,
  onSelect,
}) => {
  const { infrastructureCalls } = useCustomData();

  const cls = classNames('patient-call-section', className);

  const title =
    category === 'ASSISTANCE'
      ? 'enfermagem'
      : infrastructureCalls.toLocaleLowerCase();

  return (
    <div className={cls}>
      {reasons.length > 0 && (
        <>
          <Heading as="h4" className="mb-2" size="lg1" weight="semiBold">
            Chamar {title}
          </Heading>
          <Text className="mb-2">
            Selecione uma opção abaixo para abrir o chamado
          </Text>

          <div className="row g-2">
            {reasons.map((reason) => (
              <div key={reason.id} className="col-6 col-sm-4 col-lg-3">
                <OrderCard
                  title={reason.reason['pt-BR'] || ''}
                  icon={reason.icons?.pwa ?? ''}
                  disabled={!canBeTriggered(reason)}
                  onSelect={() => onSelect(reason)}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PatientCallSection;
