import * as React from 'react';
import classNames from 'classnames';

import styles from './Spinner.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  size?: 'xs' | 'sm' | 'lg';
  type?: 'default' | 'light';
}

export const Spinner: React.FunctionComponent<Props> = ({
  size = 'lg',
  type = 'default',
  ...htmlElementProps
}) => {
  const graph = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const ciclegraph = graph.current;
    if (ciclegraph) {
      const circleElements =
        ciclegraph.childNodes as unknown as HTMLCollectionOf<HTMLElement>;

      let angle = 360 - 90;
      const dangle = 360 / circleElements.length;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < circleElements.length; i++) {
        const circle = circleElements[i];
        angle += dangle;
        circle.style.transform = `rotate(${angle}deg) translate(${
          ciclegraph.clientWidth / 2
        }px) rotate(-${angle}deg)`;
      }
    }
  }, []);

  const cls = classNames(styles['lds-default'], {
    [styles[`spinner-${size}`]]: size,
    [styles['spinner-light']]: type === 'light',
  });

  return (
    <div {...htmlElementProps}>
      <div className={cls} ref={graph}>
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
      </div>
    </div>
  );
};

export default Spinner;
