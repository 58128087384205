import merge from 'deepmerge';
import { Theme } from '../@types/theme';

export interface InjectedThemeProps {
  theme: Theme;
}

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const defaultTheme: RecursivePartial<Theme> = {
  color: {
    primary: '#214e92',
    primaryLight: '#008eca',
    primaryLighter: '#a4d5ee',
    secondary: '#653d85',
    disabled: '#c6c6c6',

    'pain-0': '#2DB102',
    'pain-1': '#79CC01',
    'pain-2': '#9DCD06',
    'pain-3': '#C0CB0F',
    'pain-4': '#EBBD0F',
    'pain-5': '#FFC120',
    'pain-6': '#FFB200',
    'pain-7': '#FE9900',
    'pain-8': '#FF8943',
    'pain-9': '#FE654F',
    'pain-10': '#F64646',
    'pain-11': '#EE3534',
    'pain-none': '#1BC733',
    'pain-light': '#66C722',
    'pain-mild': '#BFCB07',
    'pain-severe': '#FFB200',
    'pain-verySevere': '#FF800B',
    'pain-worst': '#F74B1D',
  },
  texts: {
    infrastructureCalls: 'Outros Serviços',
  },
};

const createTheme = (
  theme?: RecursivePartial<Theme>,
): RecursivePartial<Theme> => {
  if (!theme) return defaultTheme;
  return merge(defaultTheme, theme) as Theme;
};

/**
 * The following themes are not being imported in the app
 * Instead they are loaded from hospital.config
 * But we will just let them here for documentation purposes
 */
export const themes: Record<string, RecursivePartial<Theme>> = {
  q24: createTheme(),
  RHPTheme: createTheme({
    color: {
      primary: '#28603a',
      primaryLight: '#49a266',
      primaryLighter: '#49a266',
      secondary: '#28603a',
      disabled: '#c6c6c6',
    },
    texts: {
      infrastructureCalls: 'Outros serviços',
    },
  }),
  BPTheme: createTheme({
    color: {
      primary: '#55489d',
      primaryLight: '#55489d',
      primaryLighter: '#55489d',
      success: '#6ac07a',
      warning: '#ffca05',
      danger: '#f0587d',
      secondary: '#f37053',
      disabled: '#c6c6c6',
    },
    texts: {
      infrastructureCalls: 'Hotelaria',
    },
    typography: {
      fontFamily: 'AllerLight, Inter',
    },
  }),
  MaterDeiTheme: createTheme({
    color: {
      primary: '#038E83',
      primaryLight: '#4ABFA1',
      primaryLighter: '#4ABFA1',
      secondary: '#038E83',
      success: '#4ABFA1',
      disabled: '#c6c6c6',
    },
    texts: {
      infrastructureCalls: 'Outros serviços',
    },
  }),
};
