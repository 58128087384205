import classNames from 'classnames';
import * as React from 'react';
import SelectionOption, {
  Props as SelectionOptionProps,
} from './SelectionOption';

import styles from './SelectionContainer.module.scss';

export interface IProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'onChange'> {
  gap?: number;
  mode?: 'horizontal' | 'vertical';
  children: React.ReactNode;
}

export type Props<T extends string | number = string> = IProps &
  (
    | {
        allowMultiple: false;
        value?: T;
        onChange: (value?: T) => void;
      }
    | {
        allowMultiple: true;
        value?: T[];
        onChange: (value: T[], option?: T, selected?: boolean) => void;
      }
  );

const SelectionContainer = <T extends string | number = string>({
  className,
  style,
  mode = 'horizontal',
  gap = 1,
  children,
  value,
  onChange,
  allowMultiple = false,
  ...htmlElementProps
}: React.PropsWithChildren<Props<T>>) => {
  const cls = classNames(styles.wrapper, `wrapper-${mode}`, className);

  const isValueSelected = React.useCallback(
    (newValue: SelectionOptionProps<T>['value']): boolean => {
      if (
        value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0)
      ) {
        return false;
      }

      if (Array.isArray(value)) {
        if (typeof value[0] === 'string') {
          return value.includes(newValue);
        }
      }
      return newValue === value;
    },
    [value],
  );

  return (
    <div
      className={cls}
      style={{ gap: `${gap}rem`, ...style }}
      role="listbox"
      {...htmlElementProps}
    >
      {React.Children.map(children, (child) => {
        const hasValue = (() => {
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          if (value !== undefined && value !== null) {
            return true;
          }
          return false;
        })();

        const item = child as React.ReactElement<SelectionOptionProps<T>>;
        const isSelected = isValueSelected(item.props.value);

        return React.cloneElement(item, {
          selected: isSelected,
          className: classNames(item.props.className, {
            inactive: hasValue && !isSelected,
          }),
          onClick: (e: React.MouseEvent) => {
            let onChangeFn;

            if (allowMultiple) {
              onChangeFn = onChange as (
                value: T[],
                option?: T,
                selected?: boolean,
              ) => void;
              const arrayValue = value as T[];
              if (!isSelected) {
                onChangeFn(
                  [...(arrayValue || []), item.props.value],
                  item.props.value,
                  true,
                );
              } else {
                onChangeFn(
                  [
                    ...(Array.isArray(arrayValue) ? arrayValue : []).filter(
                      (v) => v !== item.props.value,
                    ),
                  ],
                  item.props.value,
                  false,
                );
              }
              return item.props.onClick?.(e);
            }

            onChangeFn = onChange as (value?: T) => void;
            if (!isSelected) {
              onChangeFn(item.props.value);
            } else {
              onChangeFn(undefined);
            }

            return item.props.onClick?.(e);
          },
        });
      })}
    </div>
  );
};

SelectionContainer.Option = SelectionOption;

export default SelectionContainer;
