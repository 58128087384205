import React from 'react';
import classNames from 'classnames';
import { Button, Text } from '@quality24/design-system';
import { faRefresh } from '@fortawesome/pro-regular-svg-icons';

export interface Props<ErrorType extends Error> {
  className?: string;
  error: ErrorType;
  refetch: () => void;
}

const ApiError = <ErrorType extends Error>({
  className,
  error,
  refetch = () => {},
}: Props<ErrorType>): React.ReactElement => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('GraphQL Error: ', error);
  }

  return (
    <div
      className={classNames(
        className,
        'd-flex flex-column flex-justify-center flex-align-center text-center',
      )}
    >
      <Text weight="semiBold">Erro ao buscar dados.</Text>

      <div className="d-flex justify-content-center">
        <Button
          className="my-3"
          variant="outline"
          onClick={() => refetch()}
          icon={faRefresh}
        >
          Tentar Novamente
        </Button>
      </div>

      {process.env.NODE_ENV !== 'production' && (
        <Text size="sm2">Error message: {error.message}</Text>
      )}
    </div>
  );
};

export default ApiError;
