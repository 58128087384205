import * as React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import { ColorStyleProps, FontSize } from '../../../@types/theme';
import Text from '../../atoms/Text';
import Surface, { SurfaceProps } from '../../atoms/Surface';

import styles from './Alert.module.scss';
import Icon from '../../atoms/Icon';

export interface Props extends SurfaceProps {
  onDismiss?: () => void;
  title?: string;
  titleSize?: keyof FontSize | undefined;
  variant?: 'success' | 'warning' | 'danger' | 'info';
  icon?: FontAwesomeIconProps['icon'];
  children?: React.ReactNode;
}

const color: Record<Exclude<Props['variant'], undefined>, ColorStyleProps> = {
  danger: 'red100_28',
  success: 'green100_28',
  warning: 'yellow100_28',
  info: 'grayLight1',
};

const Alert: React.FunctionComponent<Props> = ({
  onDismiss,
  children,
  title,
  titleSize = 'md',
  icon,
  className,
  variant = 'success',
  ...htmlElementProps
}) => {
  const cls = classNames(
    styles.alert,
    {
      [`alert-${variant}`]: variant,
    },
    className,
  );

  return (
    <Surface
      className={cls}
      backgroundColor={color[variant]}
      role="alert"
      {...htmlElementProps}
    >
      {title && (
        <>
          <div className="d-flex flex-justify-between mb-3">
            <div className="d-flex flex-items-center">
              {icon && <FontAwesomeIcon className={styles.icon} icon={icon} />}
              <Text as="span" size={titleSize} weight="semiBold">
                {title}
              </Text>
            </div>

            {onDismiss && (
              <button
                type="button"
                className="close-button"
                aria-label="dismiss-button"
                onClick={onDismiss}
              >
                <Icon icon={faXmarkLarge} />
              </button>
            )}
          </div>
          <Text className={styles.content} size="sm2">
            {children}
          </Text>
        </>
      )}

      {!title && (
        <div className="d-flex flex-justify-between">
          <div className="d-flex">
            {icon && <Icon className={styles.icon} icon={icon} size="sm" />}
            <Text className={styles.content} size="sm2">
              {children}
            </Text>
          </div>

          {onDismiss && (
            <button
              type="button"
              className="close-button"
              aria-label="dismiss-button"
              onClick={onDismiss}
            >
              <Icon icon={faXmarkLarge} />
            </button>
          )}
        </div>
      )}
    </Surface>
  );
};

export default Alert;
