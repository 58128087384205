import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import LoginPage from '../pages/login/LoginPage';

const baseUrl = process.env.PUBLIC_URL;

/**
 * <PublicRouter> component.
 */
const PublicRouter: React.FunctionComponent = () => (
  <Routes>
    <Route path={`${baseUrl}/login`} element={<LoginPage />} />

    <Route path="*" element={<Navigate to="/login" replace />} />
  </Routes>
);

export default PublicRouter;
