import * as React from 'react';
import classNames from 'classnames';
import {
  ColorStyleProps,
  FontSizeProps,
  FontWeightProps,
} from '../../../@types/theme';

import styles from './Text.module.scss';

export interface Props
  extends Omit<React.HTMLAttributes<HTMLElement | HTMLLabelElement>, 'color'> {
  as?: 'p' | 'span' | 'label';
  htmlFor?: string;
  size?: FontSizeProps;
  color?: ColorStyleProps;
  weight?: FontWeightProps;
  children?: React.ReactNode;
  isUpperCase?: boolean;
  ellipsize?: boolean;
}

export const Text: React.FC<Props> = ({
  className,
  style,
  children,
  color,
  isUpperCase = false,
  ellipsize = false,
  size,
  as = 'p',
  weight,
  htmlFor,
  ...htmlElementProps
}) => {
  const Component = as;

  const cls = classNames(
    styles.text,
    {
      [`fs-${size}`]: size,
      [`text-${color}`]: color,
      [`fw-${weight}`]: weight,
      ellipsize,
    },
    className,
  );

  return (
    <Component
      className={cls}
      title={typeof children === 'string' ? children : undefined}
      style={{
        textTransform: isUpperCase ? 'uppercase' : 'inherit',
        whiteSpace: 'pre-wrap',
        ...style,
      }}
      htmlFor={htmlFor}
      {...htmlElementProps}
    >
      {children}
    </Component>
  );
};

export default Text;
