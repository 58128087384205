import React from 'react';
import { Button, Modal } from '@quality24/design-system';

export interface Props {
  className?: string;
  show: boolean;
  title?: string;
  children?: React.ReactNode;
  onHide: () => void;
  onConfirm: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  // Text overrides
  confirmLabel?: string;
  cancelLabel?: string;
  cancelBtnClassName?: string;
  confirmBtnClassName?: string;
}

/**
 * <ConfirmationModal> component
 */
const ConfirmationModal: React.FunctionComponent<Props> = ({
  className,
  show,
  title,
  onHide,
  children,
  cancelBtnClassName = '',
  confirmBtnClassName = '',
  onConfirm,
  onCancel,
  confirmLabel = 'Sim',
  cancelLabel = 'Cancelar',
}) => {
  /**
   * Handles the confirmation case.
   */
  const handleConfirm = async () => {
    await onConfirm();
    onHide();
  };

  /**
   * Handles the cancel case.
   */
  const handleCancel = async () => {
    if (onCancel) await onCancel();
    onHide();
  };

  return (
    <Modal
      id="confirmation-modal"
      className={className}
      show={show}
      title={title}
      onHide={onHide}
      footer={
        <div className="d-flex flex-justify-end gap-3">
          <Button
            variant="link"
            onClick={handleCancel}
            className={cancelBtnClassName}
            size="lg"
          >
            {cancelLabel}
          </Button>
          <Button
            onClick={handleConfirm}
            className={confirmBtnClassName}
            size="lg"
          >
            {confirmLabel}
          </Button>
        </div>
      }
    >
      {children}
    </Modal>
  );
};

export default ConfirmationModal;
