import React from 'react';

import { hasPermission } from '@/inpatient-patient-pwa/services/rbac-rules';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';

export interface Props {
  perform: string;
  children: React.ReactNode;
  no?: React.ReactNode;
}

/**
 * <Can> Component.
 */
const Can: React.FunctionComponent<Props> = ({
  children,
  perform,
  no: FallbackComponent = null,
}: Props) => {
  const { user } = useAuth();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{hasPermission(user, perform) ? children : FallbackComponent}</>;
};

export default Can;
