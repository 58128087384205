import * as React from 'react';
import produce from 'immer';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { gql, useQuery } from '@apollo/client';
import {
  Admission,
  Connection,
  Hospital,
  ReleasedAdmissionSurvey,
  JsonSurveyScheduleData,
  SubscriptionPayload,
  Room,
} from '@quality24/inpatient-typings';
import { Heading, useTheme } from '@quality24/design-system';
import classNames from 'classnames';

import Can from '@/inpatient-patient-pwa/atoms/Can';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import OrderCard from '@/inpatient-patient-pwa/molecules/OrderCard';
import SurveyNotificationAlert from '@/inpatient-patient-pwa/molecules/SurveyNotificationAlert';
import { Theme } from '@/inpatient-patient-pwa/types/theme';
import {
  getRoomId,
  storeFloorId,
  storeRoomId,
} from '@/inpatient-patient-pwa/services/storage';
import { useNavbar } from '@/inpatient-patient-pwa/contexts/navbar/useNavbar';
import useCustomData from '../../hooks/useCustomData';

export const SELECT_QUERY = gql`
  query FetchHomePageData($admissionId: UUID!) {
    admission: admissionById(id: $admissionId) {
      id
      endedAt
      room {
        id
        floorId
      }
    }
    survey: releasedAdmissionSurveys(
      first: 1
      filter: { admissionId: { equalTo: $admissionId } }
      orderBy: CREATED_AT_DESC
    ) {
      nodes {
        id
        surveyId
        createdAt
        status
        survey {
          scheduleData
        }
      }
    }
  }
`;

const SURVEY_SUBSCRIPTION = gql`
  subscription OnSubscriptionChanged($admissionId: UUID!) {
    releasedAdmissionSurveyChangesByAdmissionId(admissionId: $admissionId) {
      releasedAdmissionSurveyData {
        id
        surveyId
        createdAt
        status
      }
    }
  }
`;

export type ReleasedSurvey =
  | (Pick<
      ReleasedAdmissionSurvey,
      'id' | 'surveyId' | 'createdAt' | 'status'
    > & { survey: { scheduleData: JsonSurveyScheduleData } })
  | null;

export interface QueryResult {
  admission:
    | (Pick<Admission, 'id' | 'endedAt'> & {
        room: Pick<Room, 'id' | 'floorId'>;
      })
    | null;
  survey: Connection<ReleasedSurvey>;
}

export interface HospitalModules {
  hospital: Pick<Hospital, 'modules'>;
}

interface SubscriptionResult {
  subscriptionData: {
    data: {
      releasedAdmissionSurveyChangesByAdmissionId: SubscriptionPayload<
        'releasedAdmissionSurveyData',
        ReleasedSurvey
      >;
    };
  };
}

const handleSurveyChanges = (prev: QueryResult, props: SubscriptionResult) => {
  const survey =
    props.subscriptionData.data?.releasedAdmissionSurveyChangesByAdmissionId
      .releasedAdmissionSurveyData;
  return produce(prev, (draft) => {
    // eslint-disable-next-line no-param-reassign
    draft.survey.nodes = survey ? [survey] : [];

    // eslint-disable-next-line no-param-reassign
    draft.survey.totalCount = survey ? 1 : 0;
  });
};

const HomePage: React.FunctionComponent = () => {
  const { user } = useAuth();
  const { theme } = useTheme<Theme>();
  const { generalInfo, infrastructureCalls, dailySurvey } = useCustomData();
  const admissionId = user?.admissionId || '';

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const { setRenderGreeting } = useNavbar();
  React.useEffect(() => setRenderGreeting(true), [setRenderGreeting]);

  const navigate = useNavigate();

  const { data, subscribeToMore } = useQuery<QueryResult>(SELECT_QUERY, {
    variables: {
      admissionId,
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (data?.admission?.room?.floorId) {
      storeFloorId(data?.admission?.room?.floorId);
    }
    if (data?.admission?.room?.id && !getRoomId()) {
      storeRoomId(data?.admission?.room?.id);
    }
  }, [data?.admission?.room?.floorId, data?.admission?.room?.id]);

  React.useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SURVEY_SUBSCRIPTION,
      variables: { admissionId },
      updateQuery: handleSurveyChanges,
    });
    return unsubscribe;
  }, [admissionId, subscribeToMore]);

  const avaliableModules = user.modules ?? [];
  const hasTwoRows =
    avaliableModules.includes('PATIENT_CALL_ASSISTANCE') &&
    avaliableModules.includes('PATIENT_CALL_INFRASTRUCTURE');

  const isSurveyExpired = React.useMemo(() => {
    const surveySchedulePeriodInDays =
      data?.survey?.nodes[0]?.survey.scheduleData?.frequency === 'periodic'
        ? data?.survey?.nodes[0]?.survey.scheduleData?.period || 1
        : 1;
    const createdAt = data?.survey.nodes[0]?.createdAt;
    const limitDate = moment().subtract(surveySchedulePeriodInDays, 'days');

    if (createdAt) {
      return moment(createdAt).diff(limitDate, 'days') < 0;
    }
    return true;
  }, [data]);

  return (
    <>
      <Helmet>
        <title>Início - Quality24</title>
      </Helmet>

      <div>
        <Heading as="h4" className="mb-4" size="lg1" weight="semiBold">
          Como podemos ajudar?
        </Heading>
        <div className="row g-2">
          <Can perform="patient-call-assistance-section:view">
            <div key="assistance" className="col-6 col-sm-4 col-lg-3">
              <OrderCard
                title="Chamar enfermagem"
                icon="faUserNurse"
                onSelect={() => navigate('/calls/assistance')}
              />
            </div>
          </Can>
          <Can perform="patient-call-infra-section:view">
            <div key="infra" className="col-6 col-sm-4 col-lg-3">
              <OrderCard
                title={`Chamar ${infrastructureCalls.toLowerCase()}`}
                icon="faToolbox"
                onSelect={() => navigate('/calls/others')}
              />
            </div>
          </Can>
          <div
            key="info"
            className={classNames(
              hasTwoRows ? 'col-12' : 'col-6',
              'col-sm-4 col-lg-3',
            )}
          >
            <OrderCard
              title={generalInfo}
              icon="faInfoCircle"
              onSelect={() => navigate('/information')}
              direction={isMobile && hasTwoRows ? 'row' : 'column'}
            />
          </div>
        </div>
      </div>

      {data?.survey?.nodes[0]?.status === 'PENDING' && !isSurveyExpired && (
        <SurveyNotificationAlert
          className="mt-4"
          survey={data.survey.nodes[0]}
          additionalText={dailySurvey}
        />
      )}
    </>
  );
};

export default HomePage;
