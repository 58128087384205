import cron from 'cron-parser';
import moment from 'moment';

export function cronContainsDate(expression: string, date: Date): boolean {
  const data = cron.parseExpression(expression).fields;

  if (!data.second.some((n) => n === date.getUTCSeconds())) {
    return false;
  }
  if (!data.minute.some((n) => n === date.getUTCMinutes())) {
    return false;
  }
  if (!data.hour.some((n) => n === date.getUTCHours())) {
    return false;
  }
  if (!data.dayOfMonth.some((n) => n === date.getUTCDate())) {
    return false;
  }
  if (!data.month.some((n) => n === date.getUTCMonth() + 1)) {
    return false;
  }
  if (!data.dayOfWeek.some((n) => n === date.getUTCDay())) {
    return false;
  }

  return true;
}

export function cronCheckFrequency(
  expression: string,
  date: Date,
  previousDate: Date,
) {
  const mappers: moment.unitOfTime.DurationConstructor[] = [
    'second',
    'minute',
    'hour',
    'day',
  ];

  let nextDate = moment(previousDate).utc();

  expression.split(' ').forEach((expr, index) => {
    if (expr === '*') return;

    const mapper = mappers[index];
    if (mapper) {
      nextDate = nextDate.add(Number(expr), mapper);
    }
  });

  return nextDate.isSameOrBefore(date);
}

export function cronCheck(
  expression: string,
  date: Date,
  startDate: Date | null = null,
) {
  // get range
  const rangeExpression = expression
    .split(' ')
    .map((pos) => {
      const parts = pos.split('/');
      if (parts.length > 1) return parts.shift();
      return pos;
    })
    .join(' ');

  const matchRange = cronContainsDate(rangeExpression, date);
  if (!startDate || !matchRange) return matchRange;

  // get frequency
  const frequencyExpression = expression
    .split(' ')
    .map((pos) => {
      const parts = pos.split('/');
      if (parts.length > 1) return `${parts.pop()}`;
      return '*';
    })
    .join(' ');
  return cronCheckFrequency(frequencyExpression, date, startDate);
}
