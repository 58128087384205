import * as React from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

import Portal from '../Portal';

import styles from './Overlay.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  wrapperId?: string;
  target: HTMLElement | null;
  show: boolean;
  children: React.ReactNode;
  onHide: () => void;
  // Overlay placement in relation to the target reference
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  skidding?: number;
  offset?: number;
  tooltip?: boolean;
  // Overlay can be wider than 100vw
  canOverflow?: boolean;
  // Overlay will be forcely placed to the left with 100vw
  fullMode?: boolean;
}

/**
 * <Overlay> component
 *
 * This component positions any component in relation to a target
 */
export const Overlay: React.FunctionComponent<Props> = ({
  className,
  wrapperId = 'default-overlay-id',
  style,
  target,
  show,
  children,
  onHide = () => null,
  // Overlay placement in relation to the target reference
  placement = 'bottom',
  skidding = 0,
  offset = 8,
  tooltip = false,
  canOverflow = false,
  fullMode = false,
  ...htmlElementProps
}) => {
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null,
  );

  const {
    styles: popperStyles,
    attributes,
    update,
  } = usePopper(target, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [skidding, offset],
        },
      },
    ],
  });

  React.useEffect(() => {
    if (update) {
      update();
    }
  }, [show, update]);

  /**
   * Hide overlay if user clicks outside the overlay
   */
  React.useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (
        show &&
        event.target !== target &&
        !target?.contains(event.target as Node) &&
        !popperElement?.contains(event.target as Node)
      ) {
        onHide();
      }
    };
    document.addEventListener('mousedown', handler);

    return () => document.removeEventListener('mousedown', handler);
  });

  return (
    <Portal wrapperId={wrapperId}>
      <div
        ref={setPopperElement}
        className={classNames(styles.overlay, className, {
          show,
          tooltip,
          overflow: canOverflow,
          full: fullMode,
        })}
        style={{
          ...style,
          ...popperStyles.popper,
        }}
        role="dialog"
        aria-modal
        {...attributes.popper}
        {...htmlElementProps}
      >
        {children}
      </div>
    </Portal>
  );
};

export default Overlay;
