import * as React from 'react';
import { SelectionContainer, Text } from '@quality24/design-system';
import classNames from 'classnames';
import styles from './ImprovementsSelector.module.scss';

export interface Props<T extends string | number = string> {
  titles: string[];
  className?: string;
  value: T[];
  onChange: (value: T[]) => void;
}

const ImprovementsSelector = <T extends string | number = string>({
  value,
  onChange,
  titles,
  className = '',
}: React.PropsWithChildren<Props<T>>) => (
  <>
    <div className="mb-3">
      <Text weight="semiBold">
        Na sua opinião, quais dos itens abaixo podem ser melhorados?
      </Text>
      <Text size="sm2">Selecione quantos achar necessário.</Text>
    </div>
    <SelectionContainer
      mode="vertical"
      className={classNames(styles.wrapper, className)}
      allowMultiple
      value={value}
      onChange={onChange}
    >
      {titles.map((element) => (
        <SelectionContainer.Option
          key={element}
          value={element}
          className={styles.item}
        >
          <Text weight="semiBold" size="sm2">
            {element}
          </Text>
        </SelectionContainer.Option>
      ))}
    </SelectionContainer>
  </>
);

export default ImprovementsSelector;
