import * as React from 'react';
import classNames from 'classnames';
import {
  BorderRadiusProps,
  ColorStyleProps,
  SpacingProps,
} from '../../../@types/theme';
import { isThemeColorName } from '../../../utils';

import styles from './Surface.module.scss';

export interface Props
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> {
  backgroundColor?: ColorStyleProps | `#${string}`;
  color?: ColorStyleProps | `#${string}`;
  padding?: SpacingProps;
  borderRadius?: BorderRadiusProps;
  children?: React.ReactNode;
}

export const Surface = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      style,
      className,
      backgroundColor,
      color,
      padding,
      borderRadius,
      children,
      ...htmlElementProps
    },
    ref,
  ) => {
    const cls = classNames(
      styles.surface,
      {
        [`bg-${backgroundColor}`]:
          backgroundColor && isThemeColorName(backgroundColor),
        [`text-${color}`]: color && isThemeColorName(color),
        [`rounded-${borderRadius}`]: borderRadius,
        [`p-${padding}`]: padding,
      },
      className,
    );

    return (
      <div
        className={cls}
        style={{
          color: color && !isThemeColorName(color) ? color : undefined,
          backgroundColor:
            backgroundColor && !isThemeColorName(backgroundColor)
              ? backgroundColor
              : undefined,
          ...style,
        }}
        ref={ref}
        {...htmlElementProps}
      >
        {children}
      </div>
    );
  },
);

export default Surface;
