import * as React from 'react';
import classNames from 'classnames';
import Button, { ButtonProps } from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './PillsFilter.module.scss';

// Interfaces
// //////////////////////////

export interface SelectOptions {
  id: string;
  label: string;
  disabled?: boolean;
}

export interface Props
  extends Omit<React.HTMLAttributes<HTMLElement>, 'onChange'> {
  name: string;
  value: string;
  options: Array<SelectOptions>;
  onChange?: (name: string, newValue: string) => void;
  label?: string;
  helperText?: string;
  errorText?: string;
  align?: 'left' | 'center' | 'right';
  size?: ButtonProps['size'];
  variant?: 'pill' | 'button';
}

/**
 * <PillsFilter> component
 */
export const PillsFilter: React.FunctionComponent<Props> = ({
  className,
  name,
  options,
  value,
  onChange,
  label,
  helperText,
  errorText,
  align = 'center',
  size = 'sm',
  variant = 'pill',
  ...htmlElementProps
}) => (
  <div className={classNames(styles.wrapper, className)} {...htmlElementProps}>
    {label && (
      <Text className="mb-2" weight="bold">
        {label}
      </Text>
    )}

    {helperText && (
      <Text className="mb-2" size="sm1">
        {helperText}
      </Text>
    )}

    <div
      className={classNames(styles['options-container'], align, {
        'on-error': !!errorText,
        [variant]: variant,
      })}
    >
      {options.map((opt) => (
        <Button
          key={opt.id}
          className={classNames(styles.pill, { active: opt.id === value })}
          size={size}
          variant="outline"
          disabled={opt.disabled}
          onClick={() => onChange && onChange(name, opt.id)}
        >
          {opt.label}
        </Button>
      ))}
    </div>

    {errorText && (
      <Text className="mt-2" size="sm1" color="red400">
        {errorText}
      </Text>
    )}
  </div>
);

export default PillsFilter;
