import * as React from 'react';
import { toast as reactToast, ToastOptions } from 'react-toastify';
import {
  faCheck,
  faTriangleExclamation,
  faX,
} from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '../../contexts/theme';
import Icon, { IconProps } from '../../components/atoms/Icon';
import { parseColor } from '../themeUtils';
import { ColorStyleProps } from '../../@types/theme';

export const useToast = () => {
  const { theme } = useTheme();

  const toast = React.useCallback(
    (
      message: React.ReactNode | string,
      options: Partial<ToastOptions> = {},
      autoClose = 8e3,
    ): void => {
      reactToast(message, {
        autoClose,
        style: {
          background: 'rgba(255, 255, 255, 0.95)',
          color: theme.color.blackLight,
          borderRadius: 3,
          width: 'calc(100% - 30px)',
          margin: 14,
          padding: 14,
          boxSizing: 'border-box',
          boxShadow: '0px 0px 30px rgba(7, 12, 19, 0.1)',
          border: '1px solid #dcdcdc',
        },
        progressStyle: {
          background: theme.color.primary,
          borderRadius: 3,
        },
        ...options,
      });
    },
    [theme],
  );

  const notify = React.useCallback(
    (
      icon: IconProps['icon'],
      color: ColorStyleProps,
      message: string,
      autoClose?: number,
    ) => {
      toast(
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              width: '2rem',
              height: '2rem',
              borderRadius: '50%',
              background: `${parseColor(color, { theme })}`,
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '0.5rem',
            }}
          >
            <Icon icon={icon} />
          </span>
          {message}
        </div>,
        { progressStyle: { background: `${parseColor(color, { theme })}` } },
        autoClose,
      );
    },
    [theme, toast],
  );

  const success = React.useCallback(
    (message: string, autoClose = 3e3) => {
      notify(faCheck, 'primary', message, autoClose);
    },
    [notify],
  );

  const error = React.useCallback(
    (message: string, autoClose = 3e3) => {
      notify(faX, 'danger', message, autoClose);
    },
    [notify],
  );

  const warning = React.useCallback(
    (message: string, autoClose = 3e3) => {
      notify(faTriangleExclamation, 'warning', message, autoClose);
    },
    [notify],
  );

  return { toast, notify, success, error, warning };
};

export default useToast;
