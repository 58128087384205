import * as React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AlertEvaluationValue } from '@quality24/inpatient-typings';

import { Button, RangeInputField, TextArea } from '@quality24/design-system';
import SmileyInputField from '@/inpatient-patient-pwa/molecules/SmileyInputField';

export interface Props {
  onSubmit?: (feedback: Partial<AlertEvaluationValue>) => void;
  onRefuse?: () => void;
  message?: string;
  title?: string;
  evalType?: '_5_POINT_SMILEY' | '10_POINT' | string;
}

const validationSchema = Yup.object().shape({
  score: Yup.number().required(),
  text: Yup.string(),
});

const CallEvaluationForm: React.FunctionComponent<Props> = ({
  onSubmit = () => {},
  onRefuse = () => {},
  evalType = '_10_POINT',
  title = 'Avalie seu chamado',
  message = 'Qual nota você daria para sua experiência durante a resolução deste chamado?',
}) => {
  const formik = useFormik<Partial<AlertEvaluationValue>>({
    initialValues: { score: 0, text: '' },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className="text-primary text-center mb-4">{title}</h2>
      <p className="page-description mb-5" style={{ whiteSpace: 'pre-wrap' }}>
        {message}
      </p>

      {evalType === '_5_POINT_SMILEY' ? (
        <SmileyInputField
          className="mb-5"
          value={formik.values.score}
          onChange={(score) => formik.setFieldValue('score', score)}
        />
      ) : (
        <RangeInputField
          className="mb-5"
          value={formik.values.score}
          errorText={formik.errors.score}
          onChange={(score) => formik.setFieldValue('score', score)}
          min={1}
        />
      )}

      <TextArea
        id="formFeedback"
        label="Comentário"
        name="text"
        value={formik.values.text}
        errorText={formik.errors.text}
        onChange={formik.handleChange}
      />

      <div className="d-flex justify-content-end mt-4">
        <Button className="m-2" variant="outline" onClick={onRefuse}>
          Não quero avaliar
        </Button>
        <Button className="m-2" type="submit" disabled={!formik.isValid}>
          Enviar
        </Button>
      </div>
    </form>
  );
};

export default CallEvaluationForm;
