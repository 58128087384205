import React from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { AuthFlowPayload } from '@quality24/inpatient-typings';
import { silentRefresh } from '@/inpatient-patient-pwa/services/silentRefresh';

import WebSocketProvider from '@/inpatient-patient-pwa/providers/WebSocketProvider';

import { getAuthPayload } from '@/inpatient-patient-pwa/services/storage';
import { resetCache } from '@/inpatient-patient-pwa/services/graphql';
import * as auth from '@/inpatient-patient-pwa/services/auth';

import { AuthPayload } from '@/inpatient-patient-pwa/types/auth';
import AuthContext from './authContext';

/**
 * The GraphQL auth flow fragment
 * @todo Remover a parte de userData após a implementação das queries nos componentes
 */
const AUTH_FLOW_FRAGMENT = gql`
  fragment AuthFlowFragment on AuthFlowPayload {
    session
    username
    challengeName
    challengeParameters
    authenticationResult {
      tokenType
      expiresIn
      accessToken
      refreshToken
      idToken
      userData {
        modules
        admissionId
        loginId
        department
        departmentId
        floorPermissions
        hospitalConfig
      }
    }
  }
`;

/**
 * GraphQL authenticate mutation
 */
const INITIATE_AUTH_FLOW_MUTATION = gql`
  mutation ($input: AuthPWADeviceInput!) {
    authFlow: initiatePwaDeviceAuth(input: $input) {
      ...AuthFlowFragment
    }
  }
  ${AUTH_FLOW_FRAGMENT}
`;

/**
 * GraphQL set new password mutation
 */
const SET_NEW_PASSWORD = gql`
  mutation ($input: SetNewPasswordInput!) {
    authFlow: setNewPassword(input: $input) {
      ...AuthFlowFragment
    }
  }
  ${AUTH_FLOW_FRAGMENT}
`;

/**
 * GraphQL forgot password mutation
 */
const FORGOT_PASSWORD_MUTATION = gql`
  mutation ($email: String!) {
    forgotPassword(input: { email: $email }) {
      destination
    }
  }
`;

/**
 * GraphQL confirm forgot password mutation
 */
const CONFIRM_FORGOT_PASSWORD_MUTATION = gql`
  mutation ($input: ConfirmForgotPasswordInput!) {
    confirmForgotPassword(input: $input)
  }
`;

/**
 * This method returns the encoded data string used for cognito advanced security feature.
 * This would be generated only when developer has included the JS used for collecting the
 * data on their client. Please refer to documentation to know more about using AdvancedSecurity
 * features
 * Ref.: https://github.com/amazon-archives/amazon-cognito-auth-js/blob/v1.3.2/src/CognitoAuth.js#L759
 */
// const getUserContextData = (email) => {
//   if (typeof AmazonCognitoAdvancedSecurityData === 'undefined') {
//     return undefined;
//   }

//   const userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
//   const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
//   // eslint-disable-next-line no-undef
//   return AmazonCognitoAdvancedSecurityData.getData(email, userPoolId, clientId);
// };

interface OnFlowCompletedArg {
  authFlow: AuthFlowPayload;
}

type AuthPayloadSetter = (setter: (old: AuthPayload) => AuthPayload) => void;

export type OnFlowCompletedHandler = (
  navigate: NavigateFunction,
  setAuthPayload: AuthPayloadSetter,
) => (arg: OnFlowCompletedArg) => void;

/**
 * Gets the function to handle a successful login.
 * Note: exported for testing purposes only
 * @param {Object} setters the component state setters.
 */
export const getOnFlowCompletedHandler: OnFlowCompletedHandler =
  (navigate: NavigateFunction, setAuthPayload: AuthPayloadSetter) =>
  ({ authFlow }: OnFlowCompletedArg): void => {
    // Check if we received a login challenge
    if (authFlow.challengeName) {
      const challenge = {
        email: authFlow.username,
        session: authFlow.session,
        name: authFlow.challengeName,
        parameters: authFlow.challengeParameters,
      };
      setAuthPayload((old) => ({ ...old, challenge }));
      navigate(`/login?mode=${authFlow.challengeName}`);
      return;
    }

    // Type safety
    if (!authFlow.authenticationResult) return;

    // Perform state login
    const authPayload = auth.parseAuthFlowResult(authFlow.authenticationResult);
    auth.login(authPayload);
  };

export interface Props {
  children: React.ReactNode;
}

/**
 * <AuthenticationProvider> component
 */
const AuthenticationProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const payload = getAuthPayload();

  // React Hooks
  const [authPayload, setAuthPayload] = React.useState(
    payload || auth.defaultAuthPayload,
  );

  // Auto refresh token
  React.useEffect(() => {
    if (authPayload?.accessToken) return silentRefresh();
    return () => null;
  }, [authPayload.accessToken]);

  // Allow graphql service to set this component state on
  // payload changes (mainly token refreshes)
  React.useEffect(() => {
    auth.setAuthPayloadSetterRef(setAuthPayload);
    return () => {
      auth.setAuthPayloadSetterRef(undefined);
    };
  }, [setAuthPayload]);

  // Get router history
  const navigate = useNavigate();

  // Apollo login mutation hook
  const [loginMutation] = useMutation(INITIATE_AUTH_FLOW_MUTATION, {
    onCompleted: getOnFlowCompletedHandler(navigate, setAuthPayload),
  });

  // Apollo set new password mutation hook
  const [setNewPasswordMutation] = useMutation(SET_NEW_PASSWORD, {
    onCompleted: getOnFlowCompletedHandler(navigate, setAuthPayload),
  });

  // Apollo forgot password process mutation hooks
  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD_MUTATION);
  const [confirmForgotPasswordMutation] = useMutation(
    CONFIRM_FORGOT_PASSWORD_MUTATION,
  );

  /**
   * Initiates the auth flow in order to log in the user.
   * @param {*} email user email
   * @param {*} password user password
   * @param {*} options other apollo options
   */
  const login = React.useCallback(
    (token, name, userType, birthDate, admissionNumber, options) => {
      const variables = {
        input: {
          token,
          validationData: { name, userType, birthDate, admissionNumber },
        },
      };
      return loginMutation({ ...options, variables });
    },
    [loginMutation],
  );

  /**
   * Initiates the auth flow in order to log in the user.
   * @param {*} email user email
   * @param {*} password user password
   * @param {*} options other apollo options
   */
  const logout = React.useCallback(async () => {
    await resetCache();
    // Set auth payload and cookies to default value
    auth.logout();
  }, []);

  /**
   * Handles the response for the NEW_PASSWORD_REQUIRED challenge.
   * @param {*} newPassword new user password
   * @param {*} options other apollo options
   */
  const setNewPassword = React.useCallback(
    (newPassword, options) => {
      const { challenge } = authPayload;
      const variables = {
        input: {
          newPassword,
          email: challenge?.email,
          session: challenge?.session,
          challengeName: challenge?.name,
          challengeParameters: challenge?.parameters,
          // userContextData: getUserContextData(challenge.email),
        },
      };
      return setNewPasswordMutation({ ...options, variables });
    },
    [authPayload, setNewPasswordMutation],
  );

  /**
   * Initiates the forgot password process..
   * @param {*} email user email
   * @param {*} options other apollo options
   */
  const forgotPassword = React.useCallback(
    (email, options) => {
      const variables = { email };
      return forgotPasswordMutation({ ...options, variables });
    },
    [forgotPasswordMutation],
  );

  /**
   * Initiates the forgot password process..
   * @param {*} confirmationCode the forgot password process confirmation code
   * @param {*} email user email
   * @param {*} newPassword user new password
   * @param {*} options other apollo options
   */
  const confirmForgotPassword = React.useCallback(
    (confirmationCode, email, newPassword, options) => {
      const variables = {
        input: { confirmationCode, email, newPassword },
      };
      return confirmForgotPasswordMutation({ ...options, variables });
    },
    [confirmForgotPasswordMutation],
  );

  /**
   * Prepare the auth value
   */
  const authProviderValue = React.useMemo(
    () => ({
      status: authPayload.status,
      challenge: authPayload.challenge,
      accessToken: authPayload.accessToken,
      refreshToken: authPayload.refreshToken,
      idToken: authPayload.idToken,
      user: authPayload.user,
      // Auth Flow functions
      login,
      logout,
      setNewPassword,
      forgotPassword,
      confirmForgotPassword,
    }),
    [
      authPayload.accessToken,
      authPayload.challenge,
      authPayload.idToken,
      authPayload.refreshToken,
      authPayload.status,
      authPayload.user,
      confirmForgotPassword,
      forgotPassword,
      login,
      logout,
      setNewPassword,
    ],
  );

  return (
    <WebSocketProvider>
      <AuthContext.Provider value={authProviderValue}>
        {children}
      </AuthContext.Provider>
    </WebSocketProvider>
  );
};

export default AuthenticationProvider;
