import * as React from 'react';
import classNames from 'classnames';

import styles from './Image.module.scss';

export interface Props extends React.HTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  fluid?: boolean;
}

/**
 * <Image> component
 */
export const Image: React.FunctionComponent<Props> = ({
  className,
  src,
  alt,
  width = 320,
  height,
  fluid = false,
  ...htmlAttributes
}: Props) => (
  <img
    className={classNames(styles.img, { fluid }, className)}
    src={src}
    alt={alt}
    width={width}
    height={height}
    {...htmlAttributes}
  />
);

export default Image;
