import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import TabPane, { Props as TabPaneProps } from './TabPane';
import Text from '../../atoms/Text';

import styles from './Tab.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  activeKey?: number | string;
  onTabSelected?: (tabKey: number | string) => void;
  tabPaneStyle?: React.CSSProperties;
  children: React.ReactNode;
}

interface TabSubComponents {
  TabPane: React.FunctionComponent<TabPaneProps>;
}

interface TabData {
  [key: string | number]: TabPaneProps;
}

const Tab: React.FunctionComponent<Props> & TabSubComponents = ({
  className,
  activeKey = 0,
  children,
  onTabSelected,
  tabPaneStyle,
  ...htmlElementProps
}: Props) => {
  const [tabsData, setTabsData] = React.useState<TabData>({});

  // Check tabs from children
  React.useEffect(() => {
    const data: TabData = {};

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;

      const { props } = element;
      // eslint-disable-next-line react/prop-types
      data[props.tabKey] = props;
    });

    setTabsData(data);
  }, [children]);

  const handleTabChange = React.useCallback(
    (newTabKey: number | string) => {
      if (onTabSelected) onTabSelected(newTabKey);
    },
    [onTabSelected],
  );

  return (
    <div className={className} {...htmlElementProps}>
      <ul role="tablist" className={styles['tab-navigation']}>
        {Object.values(tabsData).map(({ tabKey, title, icon }) => (
          <li
            key={tabKey}
            className={classNames(styles['nav-item'], {
              active: tabKey === activeKey,
            })}
            role="tab"
            aria-selected={tabKey === activeKey}
            aria-controls={tabKey}
            tabIndex={tabKey === activeKey ? 0 : -1}
          >
            <button
              type="button"
              className={styles['nav-link']}
              onClick={() => handleTabChange(tabKey)}
              style={{ width: '100%' }}
            >
              {icon && <FontAwesomeIcon icon={icon} />}

              <Text as="span" size="sm1" weight="bold">
                {title}
              </Text>
            </button>
          </li>
        ))}
      </ul>

      {Object.values(tabsData).map(({ tabKey, title, icon, ...otherProps }) => (
        <div
          key={`panel-${tabKey}`}
          className={styles['tab-pane']}
          style={tabPaneStyle}
          role="tabpanel"
          hidden={tabKey !== activeKey}
          {...otherProps}
        >
          {/* render only the active tab content */}
          {tabKey === activeKey &&
            tabsData[activeKey] &&
            tabsData[activeKey].children}
        </div>
      ))}
    </div>
  );
};

Tab.TabPane = TabPane;

export default Tab;
