import React from 'react';
import {
  Alert,
  HospitalDepartment,
  ServiceGroupItem,
  Service,
} from '@quality24/inpatient-typings';
import classNames from 'classnames';
import { Text } from '@quality24/design-system';

import ActiveAlertCard from '@/inpatient-patient-pwa/molecules/ActiveAlertCard';

export interface Props {
  className?: string;
  alerts: Array<ExtendedAlert>;
}

interface ExtendedAlert extends Required<Pick<Alert, 'id' | 'createdAt'>> {
  group: Pick<ServiceGroupItem, 'id' | 'name'> & {
    service: Required<Pick<Service, 'id' | 'reason'>> & {
      department: Required<Pick<HospitalDepartment, 'id' | 'name'>>;
    };
  };
}

const ActiveAlertList: React.FunctionComponent<Props> = ({
  className,
  alerts,
}) => (
  <div
    className={classNames('d-flex flex-column gap-2', className)}
    role="list"
  >
    {alerts.length === 0 && <Text>Não há chamados ativos</Text>}

    {alerts.map((alert) => (
      <ActiveAlertCard key={alert.id} alert={alert} />
    ))}
  </div>
);

export default ActiveAlertList;
