import * as React from 'react';
import DefaultErrorComponent from './DefaultErrorComponent';

export interface Props {
  children?: React.ReactNode;
  fallback?: React.FunctionComponent;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage?: string;
  statusCode?: number[];
}

interface ErrorResponse {
  message?: string;
  graphQLErrors?: Array<{
    statusCode?: number;
  }>;
  networkError?: {
    statusCode?: number;
  };
}

class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: ErrorResponse): ErrorBoundaryState {
    const { graphQLErrors, networkError } = error;

    const statusCode = [];
    if (networkError && 'statusCode' in networkError) {
      statusCode.push(networkError.statusCode as number);
    }
    // Add statusCode from graphQLErrors
    graphQLErrors?.forEach((err) => {
      if (err && 'statusCode' in err) {
        statusCode.push(err.statusCode as number);
      }
    });

    return { hasError: true, errorMessage: error.message, statusCode };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const { hasError, errorMessage, statusCode } = this.state;
    const { fallback, children } = this.props;

    const Component = fallback || DefaultErrorComponent;
    if (hasError && !statusCode?.find((code) => code === 401)) {
      return <Component errorMessage={errorMessage} />;
    }

    return children;
  }
}

export default ErrorBoundary;
