import * as React from 'react';
import classNames from 'classnames';
import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faLock,
  faRightFromBracket,
} from '@fortawesome/pro-regular-svg-icons';
import {
  SidebarContextProvider,
  Props as ProviderProps,
} from './utils/context';

import { useSidebar } from './utils/useSidebar';
import Text from '../../atoms/Text';
import Image from '../../atoms/Image';

import styles from './Sidebar.module.scss';
import Icon, { IconProps } from '../../atoms/Icon';
import { Subtitle } from '../../atoms';

export const translateRole = (role: string) =>
  ({
    ADMIN: 'Administrador',
    ORG_ADMIN: 'Administrador Organização',
    HOSPITAL_ADMIN: 'Administrador Hospital',
    SUPERVISOR: 'Supervisor',
    OMBUDSMAN: 'Ouvidoria',
    MONITOR: 'Operação',
    FLOOR_MONITOR: 'Operação do Andar',
    GUEST: 'Convidado', // This role does not have access to the sidebar
  }[role] || '-');

interface SidebarSubComponents {
  ContextProvider: React.FunctionComponent<ProviderProps>;
}

export interface MenuGroup {
  id: string;
  label: string;
  items: Array<MenuItem>;
}

export interface MenuItem {
  id: string;
  className?: string;
  children: React.ReactNode;
  to: string;
  icon?: IconProps['icon'];
  end?: boolean;
}
export interface UserInformations {
  logo?: string | null;
  email?: string | null;
  role?: string | null;
}

export interface Props extends React.HTMLAttributes<HTMLElement> {
  menu: Array<MenuGroup>;
  onLogout?: () => void;
  // Here we need it to be any so it is generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuItemAs?: React.ElementType<any>;
  userInformations?: UserInformations;
}

/**
 * <Sidebar> component
 */
export const Sidebar: React.FunctionComponent<Props> & SidebarSubComponents = ({
  className,
  style,
  menu,
  menuItemAs = 'button',
  onLogout,
  userInformations,
  ...htmlElementProps
}: Props) => {
  const expandButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [mobileShow, setMobileShow] = React.useState(false);
  const [element, setElement] = React.useState<HTMLElement | null>(null);
  const [collapsedsMenuGroup, setCollapsedsMenuGroup] = React.useState<
    string[]
  >([]);

  // Get collapse state from context
  const { collapsed, toggleCollapse } = useSidebar();

  // Menu item component
  const MenuItem = menuItemAs;

  /**
   * Hide overlay if user clicks outside the overlay
   */
  React.useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (
        mobileShow &&
        event.target !== element &&
        (!expandButtonRef || expandButtonRef?.current !== event.target) &&
        !element?.contains(event.target as Node)
      ) {
        setMobileShow(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => document.removeEventListener('mousedown', handler);
  });

  const switchMenuGroup = (groupId: string) => {
    setCollapsedsMenuGroup((_collapsedsMenuGroup) => {
      if (_collapsedsMenuGroup.find((item) => item === groupId))
        return _collapsedsMenuGroup.filter((item) => item !== groupId);

      return [..._collapsedsMenuGroup, groupId];
    });
  };

  return (
    <nav
      className={classNames(styles.sidebar, className, {
        collapsed,
        show: mobileShow,
      })}
      {...htmlElementProps}
    >
      <section className={styles['mobile-wrapper']}>
        {/* Mobile hamburger button */}
        <button
          ref={expandButtonRef}
          type="button"
          className={classNames(styles.button, 'expand')}
          aria-label="Abrir menu"
          onClick={() => setMobileShow((prev) => !prev)}
        >
          <Icon icon={faBars} size="sm" />
        </button>

        {/* Logo heading */}
        <div className={styles['logo-wrapper']}>
          <Image
            src="https://d9d5m694col3p.cloudfront.net/images/branding/logo_quality24_light_color_256px.png"
            alt="Logo Quality 24"
            width={125}
            id={styles.logo}
          />
          <Image
            src="https://d9d5m694col3p.cloudfront.net/images/branding/logo_quality24_short_light_color_64px.png"
            alt="Logo Quality 24 Short"
            width={58}
            id={styles['logo-collapsed']}
          />
        </div>

        {/* Lock button */}
        <button
          type="button"
          aria-label={
            collapsed ? 'Fechar barra lateral' : 'Travar barra lateral'
          }
          className={classNames(styles.button, styles['lock-button'])}
          onClick={toggleCollapse}
        >
          <Icon
            icon={collapsed ? faLock : faChevronLeft}
            size="xs"
            color="primary"
          />
        </button>
      </section>

      {/* Menu */}
      <section ref={setElement} className={styles['expandable-wrapper']}>
        <div className={styles['menu-wrapper']}>
          <div className={styles.menu}>
            {menu.map((group) => {
              const containerOpen = !collapsedsMenuGroup.find(
                (item) => item === group.id,
              );
              return (
                <div key={group.id} className={styles['menu-container']}>
                  <div className={classNames(styles.title, 'ellipsize')}>
                    <Subtitle
                      className={styles['group-label']}
                      size="sm1"
                      weight="bold"
                      color="blue600_25"
                      isUpperCase
                    >
                      {group.label}
                    </Subtitle>
                    <button
                      type="button"
                      className={classNames(
                        styles.button,
                        styles['collapsed-category-button'],
                      )}
                      onClick={() => switchMenuGroup(group.id)}
                    >
                      <Icon
                        icon={containerOpen ? faChevronUp : faChevronDown}
                        size="xs"
                        color="white"
                      />
                    </button>
                  </div>

                  {containerOpen && (
                    <div className={styles.menu}>
                      {group.items.map(
                        ({
                          icon,
                          children,
                          className: itemClassName,
                          ...item
                        }) => (
                          <MenuItem
                            key={item.id}
                            type="button"
                            className={classNames(
                              styles.button,
                              'item',
                              itemClassName,
                            )}
                            {...item}
                          >
                            {icon && <Icon icon={icon} size="sm" />}
                            <Text
                              size="sm1"
                              weight="bold"
                              className={styles['item-label']}
                            >
                              {children}
                            </Text>
                          </MenuItem>
                        ),
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* App version */}
          <Subtitle
            size="sm1"
            weight="semiBold"
            color="disabled"
            className={styles['version-label']}
          >{`v${process.env.REACT_APP_VERSION || '0.0.0'}`}</Subtitle>
        </div>

        {/* User information & Logout button */}
        <div
          className={classNames(
            styles.user,
            'd-flex flex-justify-between py-2 px-3 border-top-1 border-style-solid border-top-disabled',
            !userInformations && !collapsed && styles['no-informations'],
          )}
        >
          <div
            className={classNames(styles.informations, 'd-flex gap-2', {
              'd-none': collapsed,
            })}
          >
            <img
              className="rounded-circle"
              src={
                userInformations?.logo ||
                'https://cdn.quality24.com.br/images/branding/logo_quality24_short_light_color_64px.png'
              }
              alt={userInformations?.email || 'Usuário'}
            />
            {userInformations && (
              <div className={classNames(styles.details, 'd-flex gap-1')}>
                {userInformations.email && (
                  <Text
                    size="sm1"
                    weight="semiBold"
                    color="white"
                    title={userInformations.email}
                  >
                    {userInformations.email.split('@')?.[0]}
                  </Text>
                )}
                {userInformations.role && translateRole(userInformations.role) && (
                  <Subtitle size="sm1" weight="regular" color="white">
                    {translateRole(userInformations.role)}
                  </Subtitle>
                )}
              </div>
            )}
          </div>
          <button
            type="button"
            aria-label="Sair"
            className={classNames(styles.button, styles['informations-button'])}
            onClick={onLogout}
          >
            <Icon icon={faRightFromBracket} size="sm" />
          </button>
        </div>
      </section>
    </nav>
  );
};

Sidebar.ContextProvider = SidebarContextProvider;

export default Sidebar;
