import * as React from 'react';
import moment from 'moment';

import { useInterval } from '@quality24/react-hooks';

/**
 * Component prop types
 */
export interface Props extends React.HTMLAttributes<HTMLElement> {
  start?: string;
  updateInterval?: number;
}

/**
 * Parses the time elapsed in `(hours):(minutes):(seconds)` format.
 * @param {String} startTime the start time.
 */
const timeParser = (startTime: string): string => {
  const diff = moment.duration(moment().diff(startTime));
  const days = Math.floor(diff.asDays());
  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();

  // Parse the string
  let baseStr = `${seconds < 10 ? '0' : ''}${seconds}`;
  baseStr = `${minutes < 10 ? '0' : ''}${minutes}:${baseStr}`;
  if (days > 0 || hours > 0) {
    baseStr = `${hours < 10 ? '0' : ''}${hours}:${baseStr}`;
  }
  if (days > 0) {
    baseStr = `${days}:${baseStr}`;
  }

  return baseStr;
};

/**
 * <ElapsedTime> component.
 */
export const ElapsedTime: React.FunctionComponent<Props> = ({
  start = moment().toISOString(),
  updateInterval = 1000,
  ...htmlElementProps
}: Props) => {
  // React states
  const [diff, setDiff] = React.useState(timeParser(start));

  // Ref to keep track of the latest start value
  const startRef = React.useRef(start);

  // Update startRef and reset the diff when start prop changes
  React.useEffect(() => {
    startRef.current = start;
    setDiff(timeParser(start)); // Reset the diff when start changes
  }, [start]);

  // Interval Ticker
  useInterval(() => {
    setDiff(timeParser(startRef.current));
  }, updateInterval);

  return <span {...htmlElementProps}>{diff}</span>;
};

export default ElapsedTime;
