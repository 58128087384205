import classNames from 'classnames';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import Text from '../../atoms/Text';

import styles from './RadioGroup.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  className?: string;
  label?: string;
  helperText?: string;
  errorText?: string;
  direction?: 'row' | 'column';
  cols?: 1 | 2 | 3 | 4 | 6;
}

const RadioGroup: React.FC<Props> = ({
  id,
  className,
  children,
  helperText,
  errorText,
  label,
  direction = 'row',
  cols,
  ...htmlElementProps
}) => {
  const groupId = id ?? uuid();

  const cls = classNames(styles.group, className, {
    'in-error': !!errorText,
  });

  return (
    <div
      className={cls}
      role="radiogroup"
      aria-labelledby={groupId}
      {...htmlElementProps}
    >
      {label && (
        <Text as="label" id={groupId} className="mb-2" weight="bold">
          {label}
        </Text>
      )}

      <div
        className={classNames(styles['option-container'], {
          'flex-row flex-items-center mode-row': direction === 'row',
          'flex-column mode-column': direction === 'column',
          [`flex-wrap radio-cols-${cols}`]: !!cols,
          'gap-3': direction === 'row' && !cols,
          'gap-0': direction === 'row' && !!cols,
        })}
      >
        {children}
      </div>

      {helperText && !errorText && (
        <Text as="span" className="mt-2" size="sm1">
          {helperText}
        </Text>
      )}
      {errorText && (
        <Text as="span" className="mt-2" color="red400" size="sm1">
          {errorText}
        </Text>
      )}
    </div>
  );
};

export default RadioGroup;
