import * as storage from './storage';
import { resetCache, retryRefreshToken } from './graphql';
import { logout } from './auth';
import runtimeConfig from '../config';

const apiUrl = runtimeConfig.API_URL || process.env.REACT_APP_API_URL;

export const silentRefresh = (): (() => void) => {
  const exp = storage.getDecodedToken()?.exp;
  const refreshToken = storage.getRefreshToken();

  if (!refreshToken) {
    // eslint-disable-next-line no-console
    console.error(
      `Failed to refresh token. Unable to find refresh token. Logging user out.`,
    );
    resetCache();
    logout();
    return () => null;
  }

  if (exp && apiUrl) {
    const now = new Date().getTime() / 1000;

    // Remaining time until token expiration in seconds
    const timeLeft = exp - now;

    // Schedule token refresh 5 minutes before authentication expiration
    const interval = timeLeft - 300;
    if (interval > 0) {
      const id = setTimeout(() => {
        retryRefreshToken(apiUrl, refreshToken);
      }, interval * 1000);
      return () => clearTimeout(id);
    }

    /**
     * Refresh the token immediately if it's not expired yet and there's not enough time for scheduled refresh
     * It will usually happen if the app is re-opened right before the token expiration
     */
    if (timeLeft > 0) {
      retryRefreshToken(apiUrl as string, refreshToken);
    }
  }
  return () => null;
};

export default silentRefresh;
