import React from 'react';
import { Patient, SurveyPage } from '@quality24/inpatient-typings';
import { Button, Heading, Text } from '@quality24/design-system';
import { handlebarsCompiler } from '@/inpatient-patient-pwa/utils/handlebars';

export interface Props {
  page: Pick<SurveyPage, 'id' | 'title' | 'data'>;
  patient?: Pick<Patient, 'name'> | null;
  onAnswer: () => void;
  onRefuse: () => void;
  onSkip: () => void;
}

const IntroductionPage: React.FunctionComponent<Props> = ({
  page,
  patient = { name: 'Prezado(a)' },
  onAnswer,
  onRefuse,
  onSkip,
}) => {
  const description = page.data?.description;

  const template = handlebarsCompiler(description);

  const htmlString = template({ patient });

  return (
    <>
      <Heading as="h1" weight="semiBold" size="lg1">
        {page.title}
      </Heading>
      <Text className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
        {htmlString}
      </Text>

      <div className="d-flex flex-column gap-3">
        <Button size="lg" onClick={onAnswer} fluid>
          Responder agora
        </Button>
        <Button variant="outline" size="lg" onClick={onSkip} fluid>
          Responder mais tarde
        </Button>
        <Button variant="link" size="lg" onClick={onRefuse} fluid>
          Não quero responder
        </Button>
      </div>
    </>
  );
};

export default IntroductionPage;
