import { FetchResult } from '@apollo/client';
import {
  AuthFlowPayload,
  AuthUserPayload,
  EnumInpatientModule,
  GenericTheme,
  UUIDv4,
} from '@quality24/inpatient-typings';

export enum LoginState {
  NONE = 'NONE',
  AUTHENTICATED = 'AUTHENTICATED',
}

export type AuthUserRole = 'DEVICE' | 'GUEST';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface AuthUser<TTheme extends GenericTheme = {}>
  extends AuthUserPayload<TTheme> {
  role: AuthUserRole;
  hospitalId?: UUIDv4;
  admissionId?: UUIDv4;
}

export interface AuthChallenge {
  email?: string;
  session?: string;
  name: AuthFlowPayload['challengeName'];
  parameters: AuthFlowPayload['challengeParameters'];
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface AuthPayload<TTheme extends GenericTheme = {}> {
  status: LoginState;
  accessToken?: string;
  refreshToken?: string | null;
  idToken?: string;
  challenge?: AuthChallenge | null;
  user: AuthUser<TTheme>;
}

/**
 * Authentication context interface
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export interface AuthContext<TTheme extends GenericTheme = {}>
  extends AuthPayload<TTheme> {
  login: (
    token: string,
    name: string,
    userType: string,
    birthDate: string,
    admissionNumber: string,
    options?: Record<string, unknown>,
  ) => Promise<FetchResult>;
  logout: () => void;
}

/**
 * Role-based Access Control rule interface
 */
export interface RBACRule {
  static: Array<string>;
  dynamic: Record<
    string,
    (userModuleList: Array<EnumInpatientModule> | undefined) => boolean
  >;
}
