import React from 'react';
import {
  faMeh,
  faSmile,
  faTired,
  faFrown,
  faLaughBeam,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {
  ColorStyleProps,
  Icon,
  IconProps,
  Text,
  parseColor,
  useTheme,
} from '@quality24/design-system';
import { Theme } from '@/inpatient-patient-pwa/types/theme';
import styles from './SmileyInputField.module.scss';

export interface Props {
  className?: string;
  value: number | undefined;
  onChange: (v: number) => void;
}
export interface IOption {
  label: string;
  value: number;
  icon: IconProps['icon'];
  backgroundColor: ColorStyleProps;
}

const options: Array<IOption> = [
  {
    label: 'Péssimo',
    value: 1,
    icon: faTired,
    backgroundColor: 'nps0',
  },
  {
    label: 'Ruim',
    value: 2,
    icon: faFrown,
    backgroundColor: 'nps3',
  },
  {
    label: 'Regular',
    value: 3,
    icon: faMeh,
    backgroundColor: 'nps5',
  },
  {
    label: 'Bom',
    value: 4,
    icon: faSmile,
    backgroundColor: 'nps8',
  },
  {
    label: 'Ótimo',
    value: 5,
    icon: faLaughBeam,
    backgroundColor: 'nps10',
  },
];

const SmileyInputField: React.FunctionComponent<Props> = ({
  className,
  value,
  onChange,
}: Props) => {
  const { theme } = useTheme<Theme>();

  return (
    <div className={classNames(styles.wrapper, className)}>
      {options.map((option) => (
        <button
          key={option.label}
          type="button"
          className={classNames(styles.choice, 'smile', {
            inactive: value && value !== option.value,
          })}
          onClick={() => onChange(option.value)}
          style={{
            backgroundColor: parseColor(option.backgroundColor ?? 'primary', {
              theme,
            }),
            color: parseColor(option.backgroundColor ?? 'grayLight1', {
              theme,
            }),
          }}
        >
          {option.icon && <Icon icon={option.icon} size="md" color="white" />}
          {option.label && (
            <Text
              size="md"
              color="white"
              weight="semiBold"
              style={{
                width: '100px',
              }}
            >
              {option.label}
            </Text>
          )}
        </button>
      ))}
    </div>
  );
};
export default SmileyInputField;
