import * as React from 'react';
import classNames from 'classnames';
import {
  faArrowLeftLong,
  faArrowRotateRight,
} from '@fortawesome/pro-regular-svg-icons';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import Heading from '../../atoms/Heading';

import error from './error.svg';
import styles from './DefaultErrorComponent.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  errorMessage?: string;
  onReload?: () => void;
  onGoBack?: () => void;
}

const DefaultErrorComponent: React.FunctionComponent<Props> = ({
  className,
  errorMessage,
  onReload = () =>
    setTimeout(() => {
      window.location.reload();
    }),
  onGoBack,
  ...htmlElementProps
}) => (
  <div
    className={classNames(styles['error-component-wrapper'], className)}
    {...htmlElementProps}
  >
    <section className="d-flex flex-column flex-justify-center">
      <img src={error} alt="error illustration" />
    </section>

    <section className="d-flex flex-column flex-justify-center">
      <Heading className="mb-2" size="lg2" weight="bold" color="primary">
        Algo inesperado aconteceu...
      </Heading>

      <Text className="mb-2" size="lg1" color="blackLight">
        Tente recarregar a página, se o erro persistir, entre em contato com o
        suporte.
      </Text>

      {process.env.NODE_ENV !== 'production' && (
        <div className="d-flex">
          <Text className="mb-2" size="lg1" color="blackLight">
            {'Detalhes do erro: '}
            {errorMessage && <strong>{errorMessage}</strong>}
          </Text>
        </div>
      )}

      <div className="d-flex mt-4">
        {onGoBack && (
          <Button
            className="mr-3"
            variant="outline"
            size="lg"
            icon={faArrowLeftLong}
            onClick={onGoBack}
          >
            Voltar
          </Button>
        )}
        <Button onClick={onReload} size="lg" icon={faArrowRotateRight}>
          Recarregar
        </Button>
      </div>
    </section>
  </div>
);

export default DefaultErrorComponent;
