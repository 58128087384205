import { EnumInpatientModule as Module } from '@quality24/inpatient-typings';
import { AuthUser, AuthUserRole, RBACRule } from '../@types/auth';

/**
 * Checks the user module list for the module name
 * @param {String|Array} moduleNames
 */
export const getModuleComparator =
  (moduleNames: Module | Array<Module>) =>
  (userModuleList: Array<Module> | undefined): boolean => {
    if (!userModuleList) return false;
    return Array.isArray(moduleNames)
      ? moduleNames.some((moduleName) => userModuleList.includes(moduleName))
      : userModuleList.includes(moduleNames);
  };

/**
 * Role Based Access Controll Rules.
 */
const rules: Record<AuthUserRole, RBACRule> = {
  /**
   * Rules for hospital_admin role
   */
  DEVICE: {
    static: [
      // Pages
      'home-page:view',
      'calls-page:view',
      // Sidebar Sections
      // Sections
      // Actions
      'patient-call:trigger',
    ],
    dynamic: {
      // daily evaluation module
      'daily-survey-page:view': getModuleComparator('DAILY_EV'),
      // Patient call modules
      'patient-call-section:view': getModuleComparator([
        'PATIENT_CALL_ASSISTANCE',
        'PATIENT_CALL_INFRASTRUCTURE',
      ]),
      'patient-call-assistance-section:view': getModuleComparator(
        'PATIENT_CALL_ASSISTANCE',
      ),
      'patient-call-infra-section:view': getModuleComparator(
        'PATIENT_CALL_INFRASTRUCTURE',
      ),
      // Ombudsman module
      'ombudsman-page:view': getModuleComparator([
        'PATIENT_FEEDBACK_COMPLAINT',
        'PATIENT_FEEDBACK_COMPLIMENT',
      ]),
      'ombudsman-complaint-page:view': getModuleComparator(
        'PATIENT_FEEDBACK_COMPLAINT',
      ),
      'ombudsman-compliment-page:view': getModuleComparator(
        'PATIENT_FEEDBACK_COMPLIMENT',
      ),
      'ombudsman-complaint:create': getModuleComparator(
        'PATIENT_FEEDBACK_COMPLAINT',
      ),
      'ombudsman-compliment:create': getModuleComparator(
        'PATIENT_FEEDBACK_COMPLIMENT',
      ),
      'patient-call-evaluation:answer': getModuleComparator(
        'PATIENT_CALL_EVALUATION',
      ),
    },
  },
  /**
   * Rules for guest role
   */
  GUEST: {
    static: [],
    dynamic: {},
  },
};

/**
 * Checks if user has permission to perform a given action
 * @param {Object} user the user object.
 * @param {String} action the action the user is trying to perform.
 */
export const hasPermission = (user: AuthUser, action: string): boolean => {
  const permissions = rules[user.role];
  if (!permissions) {
    // Role not configured
    return false;
  }

  const { static: staticPermissions, dynamic: dynamicPermissions } =
    permissions;

  // Check static permissions
  if (staticPermissions && staticPermissions.includes(action)) {
    // Static rule not provided for action
    return true;
  }

  // Check dynamic permissions
  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // Dynamic rule not provided for action
      return false;
    }

    return permissionCondition(user.modules);
  }

  return false;
};

export default rules;
