import React from 'react';
import isFunction from 'lodash/isFunction';

export type UseIntervalHook = (
  callback: () => void,
  delay: number | null,
) => void;

/**
 * Interval hook. Periodically run a callback after component is mounted
 * @param {*} callback
 * @param {*} delay
 */
const useInterval: UseIntervalHook = (callback, delay) => {
  const savedCallback = React.useRef<Parameters<UseIntervalHook>[0]>();

  // Remember the latest callback
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  React.useEffect(() => {
    if (delay === null) {
      return () => null;
    }
    if (savedCallback && isFunction(savedCallback.current)) {
      const id = setInterval(savedCallback.current, delay);
      return () => clearInterval(id);
    }
    return () => null;
  }, [delay]);
};

export default useInterval;
