import * as React from 'react';
import { Button, RadioGroup, Radio, Text } from '@quality24/design-system';
import classNames from 'classnames';

export interface SelectOption {
  value: string;
  label: string;
}

export interface Props {
  controlId: string;
  className?: string;
  optionsClassName?: string;
  name: string;
  label?: string;
  type?: 'button' | 'radio';
  direction?: 'row' | 'column';
  options: Array<SelectOption>;
  value?: string;
  helperText?: string;
  onChange?: (name: string, value: string) => void;
  size?: 'md' | 'lg';
  fluid?: boolean;
}

/**
 * <RadioField> component
 */
const RadioField: React.FunctionComponent<Props> = ({
  controlId,
  className = '',
  name,
  label = '',
  type = 'radio',
  direction = 'row',
  value = '',
  options,
  onChange,
  helperText = '',
  optionsClassName = '',
  size = 'md',
  fluid,
}: Props) => {
  if (type === 'button') {
    return (
      <div id={controlId} className={className}>
        {label && <Text as="span">{label}</Text>}
        <div
          className={classNames('d-flex gap-2', {
            'flex-column': direction === 'column',
          })}
        >
          {options.map((opt) => (
            <Button
              id={opt.value}
              key={opt.value}
              onClick={() => onChange?.(name, opt.value)}
              className={optionsClassName}
              size={size}
              fluid={fluid}
            >
              {opt.label}
            </Button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div id={controlId} className={className}>
      <RadioGroup
        label={label}
        helperText={helperText}
        className={classNames('d-flex', {
          'flex-column': direction === 'column',
        })}
        direction={direction}
      >
        {options.map((opt) => (
          <Radio
            name={name}
            id={opt.value}
            value={opt.value}
            key={opt.value}
            label={opt.label}
            checked={value === opt.value}
            onChange={() => onChange?.(name, opt.value)}
            className={optionsClassName}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default RadioField;
