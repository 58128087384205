/* eslint-disable import/prefer-default-export */
import { Service } from '@quality24/inpatient-typings';
import { cronCheck } from './cronUtils';

/**
 *
 * @param conditional
 * @returns
 */
export const validateConditional = (
  conditional: Service['conditional'],
  lastAlertCreation: string | null,
  notifier?: (message: string) => void,
) => {
  // Cron validation
  const cronRules = conditional?.cronRule;
  if (cronRules) {
    const lastAlertDate = lastAlertCreation
      ? new Date(lastAlertCreation)
      : null;

    const currentDate = new Date();
    const match = cronRules.rules.some((rule) =>
      cronCheck(rule, currentDate, lastAlertDate),
    );

    if (!match) {
      notifier?.(
        cronRules.errorMessage ||
          'Esta categoria de serviço não está disponivel para este horário',
      );
      return false;
    }
  }
  return true;
};
