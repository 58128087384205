import * as React from 'react';
import classNames from 'classnames';
import { Icon, IconProps, Subtitle } from '@quality24/design-system';
import {
  faFaceConfounded,
  faFaceDiagonalMouth,
  faFaceFrown,
  faMeh,
  faSmile,
  faTired,
} from '@fortawesome/pro-regular-svg-icons';
import styles from './PainScale.module.scss';

export interface Props {
  className?: string;
}
interface PainObject {
  id: string;
  label: string;
  icon: IconProps['icon'];
  levels: Array<{ color: string }>;
}

const painConfigs: Array<PainObject> = [
  {
    id: 'none',
    label: 'Sem dor',
    icon: faSmile,
    levels: [{ color: 'pain-0' }, { color: 'pain-1' }],
  },
  {
    id: 'light',
    label: 'Leve',
    icon: faFaceDiagonalMouth,
    levels: [{ color: 'pain-2' }, { color: 'pain-3' }],
  },
  {
    id: 'mild',
    label: 'Moderada',
    icon: faFaceFrown,
    levels: [{ color: 'pain-4' }, { color: 'pain-5' }],
  },
  {
    id: 'severe',
    label: 'Severa',
    icon: faMeh,
    levels: [{ color: 'pain-6' }, { color: 'pain-7' }],
  },
  {
    id: 'verySevere',
    label: 'Muito severa',
    icon: faFaceConfounded,
    levels: [{ color: 'pain-8' }, { color: 'pain-9' }],
  },
  {
    id: 'worst',
    label: 'Pior dor possível',
    icon: faTired,
    levels: [{ color: 'pain-10' }, { color: 'pain-11' }],
  },
];

const PainScale: React.FunctionComponent<Props> = ({ className }) => (
  <div className={classNames(className, styles.wrapper)}>
    {painConfigs.map((painObj) => (
      <div className={styles['pain-group']} key={painObj.id}>
        <div className="d-flex">
          {painObj.levels.map((painLvl) => (
            <div
              className={classNames(
                styles['colored-rectangle'],
                `bg-${painLvl.color}`,
              )}
              key={painLvl.color}
            />
          ))}
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center text-center p-1 gap-1">
          <Icon
            icon={painObj.icon}
            className={`text-pain-${painObj.id} mt-2`}
            size="lg"
          />

          <Subtitle size="sm1">{painObj.label}</Subtitle>
        </div>
      </div>
    ))}
  </div>
);

export default PainScale;
