/* eslint-disable import/no-mutable-exports */
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
} from 'firebase/messaging';
import { getPushToken, storePushToken } from './storage';

// eslint-disable-next-line import/extensions
import firebaseConfig from '../firebase-config.json';

interface RequireTokenInput {
  onSuccess?: (token: string) => void;
}

let firebaseApp: FirebaseApp;
let messaging: Messaging;
let requireToken: ({ onSuccess }: RequireTokenInput) => void;
// Initialize the Firebase app in the service worker by passing the generated config
isSupported().then((support) => {
  if (support) {
    firebaseApp = initializeApp(firebaseConfig);

    // Retrieve firebase messaging
    messaging = getMessaging();

    requireToken = ({ onSuccess = undefined }: RequireTokenInput): void => {
      navigator.serviceWorker.ready.then((registration) => {
        getToken(messaging, {
          vapidKey: firebaseConfig.vapIdKey,
          serviceWorkerRegistration: registration,
        })
          .then((currentToken) => {
            if (currentToken && currentToken !== getPushToken()) {
              storePushToken(currentToken);
              if (onSuccess) onSuccess(currentToken);
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.warn('An error occurred while retrieving token. ', err);
          });
      });
    };
  }
});

export { requireToken, messaging, firebaseApp };
