import React from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { Alert, Service, UUIDv4 } from '@quality24/inpatient-typings';
import { v4 as uuid } from 'uuid';
import { useToast } from '@quality24/design-system';

// GraphQL
// //////////////////////////

const CREATE_ALERT_MUTATION = gql`
  mutation CreateAlertMutation($input: AlertsViewInput!) {
    createAlert: createAlertsView(input: { alertsView: $input }) {
      clientMutationId
    }
  }
`;

// Type defs
// //////////////////////////

interface ModalTriggerActionData extends Record<string, unknown> {
  content: string;
}

export type PatientCallReasonFragment = Required<
  Pick<
    Service,
    | 'id'
    | 'reason'
    | 'modifier'
    | 'customizationType'
    | 'customizationData'
    | 'actions'
    | 'icons'
    | 'conditional'
  >
>;

export interface ComposedPatientCallReason extends PatientCallReasonFragment {
  groupId?: UUIDv4;
  lastAlert?: Required<Pick<Alert, 'createdAt'>> | null;
  activeAlert?: Pick<Alert, 'id'>;
  hasChildren?: boolean;
  children?: Array<ComposedPatientCallReason>;
}

export interface HookResult {
  currentStep: 'idle' | 'select-child' | 'select-customization' | 'trigger';
  reason?: ComposedPatientCallReason | PatientCallReasonFragment;
  dispatch: React.Dispatch<HookAction>;
  operation: {
    data?: { clientMutationId?: string };
    error?: ApolloError;
    loading: boolean;
    called: boolean;
  };
}

export interface HookState {
  step: 'idle' | 'select-child' | 'select-customization' | 'trigger';
  alert: Pick<Alert, 'id' | 'admissionId'> & { groupId?: UUIDv4 };
  reason?: (ComposedPatientCallReason | PatientCallReasonFragment) & {
    groupId?: UUIDv4;
  };
}

// type: 'init' | 'select-child' | 'select-customization' | 'reset';
export type HookAction =
  | {
      type: 'init';
      reason: ComposedPatientCallReason;
    }
  | {
      type: 'select-child';
      reason: ComposedPatientCallReason;
    }
  | {
      type: 'select-customization';
      customization: { value: string };
    }
  | {
      type: 'reset';
      admissionId?: UUIDv4;
    };

// Hooks definition
// //////////////////////////

/**
 * Initial trigger flow state
 */
const getInitialState = (admissionId?: UUIDv4): HookState => ({
  step: 'idle',
  alert: {
    id: uuid() as UUIDv4,
    admissionId: admissionId || (uuid() as UUIDv4),
  },
  reason: undefined,
});

/**
 *
 * @param state
 * @param action
 * @returns
 */
export const triggerFlowReducer: React.Reducer<HookState, HookAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'init': {
      const result = {
        ...state,
        reason: action.reason,
      };
      // if has child, move to select-child
      if (action.reason.hasChildren) {
        return { ...result, step: 'select-child' };
      }
      // if has customization, move to select-customization
      if (action.reason.customizationType !== 'NONE') {
        return {
          ...result,
          alert: {
            ...state.alert,
            reasonId: action.reason.id,
            groupId: state.reason?.groupId,
          },
          step: 'select-customization',
        };
      }

      return {
        ...result,
        alert: {
          ...state.alert,
          reasonId: action.reason.id,
          groupId: action.reason.groupId,
        },
        step: 'trigger',
      };
    }

    case 'select-child': {
      const result = {
        ...state,
        alert: {
          ...state.alert,
          reasonId: action.reason.id,
          groupId: action.reason.groupId,
        },
        reason: action.reason,
      };
      // if has children, move to select-child
      if (action.reason.hasChildren) {
        return { ...state, reason: action.reason, step: 'select-child' };
      }
      // if has customization, move to select-customization
      if (action.reason.customizationType !== 'NONE') {
        return { ...result, step: 'select-customization' };
      }
      return { ...result, step: 'trigger' };
    }

    case 'select-customization': {
      return {
        ...state,
        alert: {
          ...state.alert,
          customization: action.customization,
          groupId: state.reason?.groupId,
        },
        step: 'trigger',
      };
    }

    case 'reset':
      return getInitialState(action.admissionId);

    default:
      throw new Error('Unexpected action type');
  }
};

/**
 * Handles alert trigger flow.
 * - If reason-group has children, display selector for sub-reasons-group
 * - If reason-group has customization, display selector for customization value
 * - Else trigger alert
 * @param admissionId
 * @returns
 */
export const usePatientCallTrigger = (admissionId?: UUIDv4): HookResult => {
  const [state, dispatch] = React.useReducer(
    triggerFlowReducer,
    getInitialState(admissionId),
  );
  const { toast } = useToast();

  /**
   * Performs every 'on-trigger-local' actions
   * @param reason the patient call reason
   */
  const performTriggerActions = React.useCallback(
    (reason: PatientCallReasonFragment): void => {
      if (!reason.actions || reason.actions.length === 0) return;
      reason.actions
        .filter((action) => action.hookType === 'on-trigger-local')
        .forEach((action) => {
          // For now we are only handling the modal actions
          if (action.action !== 'modal') return;
          toast((action.data as ModalTriggerActionData).content);
        });
    },
    [toast],
  );

  // Prepare mutation
  const [triggerAlert, { data, error, loading, called }] = useMutation(
    CREATE_ALERT_MUTATION,
    {
      onCompleted: () => dispatch({ type: 'reset', admissionId }),
    },
  );

  // Perform mutation if state === 'trigger'
  React.useEffect(() => {
    if (state.step === 'trigger') {
      triggerAlert({
        variables: { input: state.alert },
      })
        .then(() => {
          if (state.reason) {
            performTriggerActions(state.reason);
          }
        })
        .catch(() => {
          toast(
            'Não foi possível fazer sua requisição. Por favor tente novamente.',
          );
        });
    }
  }, [performTriggerActions, state, toast, triggerAlert]);

  return {
    currentStep: state.step,
    reason: state.reason,
    dispatch,
    operation: { data, error, loading, called },
  };
};
