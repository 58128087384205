import { Interval } from '@quality24/inpatient-typings';
import moment from 'moment';

export type ConversionKeys =
  | 'y'
  | 'M'
  | 'w'
  | 'd'
  | 'hh'
  | 'mm'
  | 'ss'
  | 'ms'
  | 'h'
  | 'm'
  | 's';

type KeyConversionMap = {
  [k in ConversionKeys]: (duration: moment.Duration, trim?: boolean) => string;
};

const keyMap: KeyConversionMap = {
  // Years parser
  y: (duration) => Math.floor(duration.asYears()).toFixed(),
  // Months parser
  M: (duration, trim) =>
    Math.floor(trim ? duration.asMonths() : duration.months()).toFixed(),
  // Weeks parser
  w: (duration, trim) =>
    Math.floor(trim ? duration.asWeeks() : duration.weeks()).toFixed(),
  // Days parser
  d: (duration, trim) =>
    Math.floor(trim ? duration.asDays() : duration.asDays()).toFixed(),
  // Hours parsers
  hh: (duration, trim) => {
    const value = Math.floor(
      trim ? duration.asHours() : duration.asHours() % 24,
    );
    return value < 10 ? `0${value.toFixed()}` : value.toFixed();
  },
  h: (duration, trim) =>
    Math.floor(trim ? duration.asHours() : duration.asHours() % 24).toFixed(),
  // Minutes parsers
  mm: (duration, trim) => {
    const value = Math.floor(
      trim ? duration.asMinutes() : duration.asMinutes() % 60,
    );
    return value < 10 ? `0${value.toFixed()}` : value.toFixed();
  },
  m: (duration, trim) =>
    Math.floor(
      trim ? duration.asMinutes() : duration.asMinutes() % 60,
    ).toFixed(),
  // Seconds parsers
  ss: (duration, trim) => {
    const value = Math.floor(
      trim ? duration.asSeconds() : duration.asSeconds() % 60,
    );
    return value < 10 ? `0${value.toFixed()}` : value.toFixed();
  },
  s: (duration, trim) =>
    Math.floor(
      trim ? duration.asSeconds() : duration.asSeconds() % 60,
    ).toFixed(),
  // Milliseconds parser
  ms: (duration, trim) =>
    Math.floor(
      trim ? duration.asMilliseconds() : duration.asMilliseconds() % 1000,
    ).toFixed(),
};

/**
 * Formats a moment duration using a custom format:
 * y - years
 * M - months
 * w - weeks
 * d - days
 * h - hours
 * hh - hours padded with 0
 * m - minutes
 * mm - minutes padded with 0
 * s - seconds
 * ss - seconds padded with 0
 * ms - milliseconds
 *
 * For custom strings, wrap it in [], i.ex.: "D [days]". ":" and "-" are recognized by default
 * and don't need escape.
 * @param duration
 * @param format
 */
export const formatDuration = (
  duration: moment.Duration,
  format: string,
): string => {
  const matches = format.matchAll(
    /(?:\[([\w\s]+)\])*(y|M|w|d|hh|mm|ss|ms|h|m|s)*/g,
  );

  const isNegative = duration.asMilliseconds() < 0;
  const signal = isNegative ? '-' : '';
  const positiveValue = isNegative
    ? moment.duration(duration.asMilliseconds() * -1)
    : duration;

  let keyFound = false;
  return (
    signal +
    Array.from(matches)
      .filter((m) => m[0] !== '')
      .reduce((acc, match) => {
        if (!match[2]) {
          return acc.replace(match[0], match[1]);
        }
        const value = keyMap[match[2] as ConversionKeys](
          positiveValue,
          !keyFound,
        );
        keyFound = true;
        return acc.replace(match[0], value);
      }, format)
  );
};

//
// Month Arrays
//

/**
 * Checks whether or not a bound (upper or lower) was reached.
 * @param {Moment} currentPos the moment for the current date.
 * @param {Moment} bound the moment object for the bound.
 * @param {'asc' | 'desc'} order the order
 */
const isDateBoundReached = (
  currentPos: moment.Moment,
  bound: moment.Moment,
  order: string,
) =>
  order === 'desc'
    ? currentPos.isSameOrBefore(bound)
    : currentPos.isSameOrAfter(bound);

/**
 * Returns an array of objects { id, display } with all date within an interval
 * @param {*} from
 * @param {*} to
 */
export const rangeToSimpleDateArray = (
  from: string,
  to?: string | null,
  order = 'desc',
): ['DD/MM/YYYY', Array<{ id: string; display: string }>] => {
  const start = moment(from).startOf('day');
  const stop = moment(to || undefined).endOf('day');
  const dateFormat = 'DD/MM/YYYY';

  // Populate the date array
  const dateArr = [];
  const lastDate = order === 'desc' ? start : stop;
  const currentDate = order === 'desc' ? stop : start;
  while (!isDateBoundReached(currentDate, lastDate, order)) {
    const pos = currentDate.clone();
    dateArr.push({ id: pos.format(dateFormat), display: pos.format('DD/MM') });

    if (order === 'desc') {
      currentDate.subtract(1, 'days');
    } else {
      currentDate.add(1, 'days');
    }
  }

  return [dateFormat, dateArr];
};

//
// Alert Elapsed Time Utils
//

/**
 * Formats the elapsed time into patient call format (hh:mm:ss).
 * @param {String} start the ISO8601 string representing the start timestamp.
 * @param {String} end the ISO8601 string representing the end timestamp.
 */
export const formatElapsedTime = (
  start: string,
  end: string,
): string | null => {
  if (!start || !end) return null;

  const timeDiff = moment.duration(moment(end).diff(moment(start)));
  return formatDuration(timeDiff, 'hh:mm:ss');
};

// Support Date.now on older clients
if (!Date.now) {
  Date.now = () => new Date().getTime();
}

/**
 * Return current timestamp in UNIX format
 * @returns {number}
 */
export const unixCurrentTime = (): number =>
  Math.trunc ? Math.trunc(Date.now() / 1000) : Math.floor(Date.now() / 1000);

/**
 * Converts an Interval object into an interval string like "1 dia 12:34:00"
 * @param interval
 * @returns {string}
 */
export const formatInterval = (interval: Interval): string => {
  const duration = moment.duration({
    seconds: interval.seconds || 0,
    minutes: interval.minutes || 0,
    hours: interval.hours || 0,
    days: interval.days || 0,
    months: interval.months || 0,
    years: interval.years || 0,
  });

  const years = Math.floor(duration.asYears());
  duration.subtract(moment.duration({ years }));
  const months = Math.floor(duration.asMonths());
  duration.subtract(moment.duration({ months }));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const parts = [];

  if (years > 0) {
    parts.push(`${years} ${years > 1 ? 'anos' : 'ano'}`);
  }
  if (months > 0) {
    parts.push(`${months} ${months > 1 ? 'meses' : 'mês'}`);
  }
  if (days > 0) {
    parts.push(`${days} ${days > 1 ? 'dias' : 'dia'}`);
  }

  if (hours > 0 || minutes > 0 || seconds > 0) {
    parts.push(
      `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
    );
  }

  return parts.join(' ');
};
