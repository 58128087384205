import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button, getIcon, Modal } from '@quality24/design-system';
import { UUIDv4 } from '@quality24/inpatient-typings';

import { HospitalInformation } from '../types';
import styles from '../HospitalInformationContent.module.scss';

export interface Props {
  content: HospitalInformation;
  createInfoMetric?: (informationSectionId: UUIDv4) => void;
  updateInfoMetric?: () => void;
}

const HospitalInformationContent: React.FunctionComponent<Props> = ({
  content,
  createInfoMetric,
  updateInfoMetric,
}) => {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <Button
        key={content.id}
        className={styles.button}
        size="lg"
        icon={getIcon(content.data?.icon)}
        onClick={() => {
          if (content.trackable) {
            createInfoMetric?.(content.id);
          }
          setShow(true);
        }}
        fluid
      >
        {content.data?.buttonLabel || 'Saber mais'}
      </Button>
      <Modal
        id={`content-modal-${content.id}`}
        title={content.data?.title}
        show={show}
        onHide={() => {
          setShow(false);
          if (content.trackable) {
            updateInfoMetric?.();
          }
        }}
      >
        <div className="d-flex flex-column gap-2">
          {content.data?.content && (
            <>{ReactHtmlParser(content.data?.content)}</>
          )}
        </div>
      </Modal>
    </>
  );
};

export default HospitalInformationContent;
