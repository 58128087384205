import { useTheme } from '@quality24/design-system';
import { Theme } from '@/inpatient-patient-pwa/types/theme';

const useCustomData = () => {
  const { theme } = useTheme<Theme>();

  return {
    generalInfo: theme?.texts?.generalInfo || 'Informações Gerais',
    infrastructureCalls: theme?.texts?.infrastructureCalls || 'Outros Serviços',
    dailySurvey: theme?.texts?.dailySurvey || '',
  };
};

export default useCustomData;
