import React from 'react';
import classNames from 'classnames';
import SelectionContainer from '../../../containers/SelectionContainer';
import ListItem from './ListItem';
import styles from './ListGroup.module.scss';

export interface Props<T extends string | number = string>
  extends Omit<React.HTMLAttributes<HTMLElement>, 'onChange'> {
  value?: T[];
  onChange: (value: T[], option?: T, selected?: boolean) => void;
  children: React.ReactNode;
}

const MultipleListGroup = <T extends string | number = string>({
  className,
  style,
  children,
  value,
  onChange,
  ...htmlElementProps
}: React.PropsWithChildren<Props<T>>) => (
  <SelectionContainer
    className={classNames(styles.wrapper, className)}
    style={{ ...style }}
    mode="vertical"
    value={value}
    allowMultiple
    onChange={onChange}
    role="list"
    {...htmlElementProps}
  >
    {children}
  </SelectionContainer>
);

MultipleListGroup.Item = ListItem;

export default MultipleListGroup;
