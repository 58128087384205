import DOMPurify from 'dompurify';

/**
 * HTMLParser hook
 */
const useHTMLParser = () => {
  const parser = (data: string) => {
    return DOMPurify.sanitize(data);
  };

  return parser;
};

export default useHTMLParser;
