import React from 'react';
import {
  ColorStyleProps,
  Heading,
  HeadingProps,
  Text,
} from '@quality24/design-system';
import classNames from 'classnames';

export interface Props {
  as?: HeadingProps['as'];
  className?: string;
  name?: string | null;
  color?: ColorStyleProps;
}

const UserGreeting: React.FunctionComponent<Props> = ({
  as = 'h2',
  className,
  name,
  color,
}) => {
  const hour = new Date().getHours();
  const greeting = React.useMemo(() => {
    if (hour <= 11) {
      return 'Bom dia';
    }
    if (hour <= 17) {
      return 'Boa tarde';
    }
    return 'Boa noite';
  }, [hour]);

  const title = React.useMemo(
    () => (name ? `${greeting}, ` : greeting),
    [greeting, name],
  );

  const shortName = name ? name.split(' ')[0] : null;

  return (
    <Heading
      as={as}
      className={classNames(className, { [`text-${color}`]: color })}
      size="lg1"
      weight="light"
    >
      {title} <br />
      <Text as="span" size="xl" weight="bold">
        {shortName}
      </Text>
    </Heading>
  );
};

export default UserGreeting;
