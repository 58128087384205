import * as React from 'react';

import { useStorage } from '@quality24/react-hooks';

export interface ISidebarContext {
  collapsed: boolean;
  toggleCollapse: () => void;
}

const sidebarContext = React.createContext<ISidebarContext>({
  collapsed: false,
  toggleCollapse: () => {},
});

const COLLAPSE_KEY = '_q24_sidebar_collapse';

export interface Props {
  children: React.ReactNode;
  defaultCollapsed?: boolean;
}

export const SidebarContextProvider: React.FunctionComponent<Props> = ({
  children,
  defaultCollapsed = false,
}) => {
  const { persistItem, retrieveItem } = useStorage();

  // Could load initial state from local storage
  const [collapsed, setCollapsed] = React.useState(
    retrieveItem(COLLAPSE_KEY, `${defaultCollapsed}`) === 'true',
  );

  /**
   * Updates the collapse flag and persist the value
   */
  const handleToggleCollapse = React.useCallback(() => {
    setCollapsed((prev) => {
      persistItem(COLLAPSE_KEY, `${!prev}`);
      return !prev;
    });
  }, [persistItem]);

  return (
    <sidebarContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ collapsed, toggleCollapse: handleToggleCollapse }}
    >
      {children}
    </sidebarContext.Provider>
  );
};

export default sidebarContext;
