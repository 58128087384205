import classNames from 'classnames';
import * as React from 'react';
import { ColorStyleProps } from '../../../@types/theme';

import styles from './Pill.module.scss';

export interface Props
  extends Omit<React.HTMLAttributes<HTMLElement>, 'color'> {
  value: string;
  color?: ColorStyleProps;
  backgroundColor?: ColorStyleProps;
}

export const Pill: React.FunctionComponent<Props> = ({
  className,
  value,
  color,
  backgroundColor,
  ...htmlElementProps
}) => {
  const cls = classNames(
    styles.pill,
    {
      [`text-${color}`]: color,
      [`bg-${backgroundColor}`]: backgroundColor,
    },
    className,
  );

  return (
    <div className={cls} {...htmlElementProps}>
      {value}
    </div>
  );
};

export default Pill;
