import React from 'react';
import {
  Alert,
  Employee,
  HospitalDepartment,
  ServiceGroupItem,
  Service,
  Connection,
  AlertEvaluation,
} from '@quality24/inpatient-typings';
import { Heading, Text } from '@quality24/design-system';
import moment from 'moment';
import classNames from 'classnames';

import Can from '@/inpatient-patient-pwa/atoms/Can';
import CallEvaluationButton from '@/inpatient-patient-pwa/organisms/CallEvaluationButton';

interface ExtendedAlert
  extends Required<Pick<Alert, 'id' | 'createdAt' | 'resolvedAt'>> {
  group: Pick<ServiceGroupItem, 'id' | 'name'> & {
    service: Required<Pick<Service, 'id' | 'reason'>> & {
      department: Required<Pick<HospitalDepartment, 'id' | 'name'>>;
    };
  };
  resolutionEvent: Connection<{
    id: string;
    employee: Required<Pick<Employee, 'id' | 'name'>> | null;
  }>;
  callEvaluation: Connection<
    Pick<AlertEvaluation, 'id' | 'status' | 'value' | 'alertId'>
  >;
}

export interface Props {
  className?: string;
  alert: ExtendedAlert;
}

const ResolvedAlertCard: React.FunctionComponent<Props> = ({
  className,
  alert,
}) => {
  const { group, resolvedAt, resolutionEvent, callEvaluation } = alert;

  const employee =
    resolutionEvent.nodes.length > 0 &&
    alert.resolutionEvent.nodes[0]?.employee;

  const evaluation =
    callEvaluation.nodes &&
    callEvaluation.nodes[callEvaluation.nodes.length - 1];

  return (
    <div
      className={classNames(
        className,
        'bg-white border border-2 border-borderColor rounded-md p-3',
      )}
      role="listitem"
    >
      <div className="d-flex justify-content-between">
        <Heading as="h3" weight="semiBold">
          {group.name?.['pt-BR'] || group.service.reason['pt-BR']}
        </Heading>
        <Can perform="patient-call-evaluation:answer">
          {evaluation && <CallEvaluationButton evaluation={evaluation} />}
        </Can>
      </div>

      <Text>Resolvido às {moment(resolvedAt).format('HH:mm')}</Text>

      {employee && <Text>Responsável: {employee.name}</Text>}
    </div>
  );
};

export default ResolvedAlertCard;
