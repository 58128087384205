import * as React from 'react';
import classNames from 'classnames';

import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import TabPane, { Props as TabPaneProps } from './TabPane';

import styles from './VerticalTab.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  activeKey?: number | string;
  children: React.ReactNode;
  onTabSelected?: (tabKey: number | string) => void;
  tabPaneStyle?: React.CSSProperties;
}

interface TabSubComponents {
  TabPane: React.FunctionComponent<TabPaneProps>;
}

interface TabData {
  [key: string | number]: TabPaneProps;
}

const VerticalTab: React.FunctionComponent<Props> & TabSubComponents = ({
  className,
  activeKey = 0,
  children,
  onTabSelected,
  tabPaneStyle,
  ...htmlElementProps
}: Props) => {
  const [tabsData, setTabsData] = React.useState<TabData>({});

  // Check tabs from children
  React.useEffect(() => {
    const data: TabData = {};

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;

      const { props } = element;
      // eslint-disable-next-line react/prop-types
      data[props.tabKey] = props;
    });

    setTabsData(data);
  }, [children]);

  const handleTabChange = React.useCallback(
    (newTabKey: number | string) => {
      if (onTabSelected) onTabSelected(newTabKey);
    },
    [onTabSelected],
  );

  return (
    <nav className={classNames('d-flex', className)} {...htmlElementProps}>
      <ul className={styles['nav-tab']}>
        {Object.values(tabsData).map(({ tabKey, title, icon, description }) => (
          <li
            key={tabKey}
            className={classNames(styles['nav-item'], {
              active: tabKey === activeKey,
              // prev: tabKey === (activeKey ?? 0) - 1,
              // next: tabKey === (activeKey ?? 0) + 1,
            })}
          >
            <button
              type="button"
              className={classNames(styles['nav-link'], {
                // prev: tabKey === (activeKey ?? 0) - 1,
                // next: tabKey === (activeKey ?? 0) + 1,
              })}
              aria-label={title}
              onClick={() => handleTabChange(tabKey)}
            >
              {icon && <Icon className="icon" icon={icon} size="sm" />}

              <div className={styles['nav-link-content']}>
                <Text
                  className="title mb-2"
                  size="lg1"
                  weight="bold"
                  color="disabled"
                >
                  {title}
                </Text>

                <Text className="description" weight="light" color="disabled">
                  {description}
                </Text>
              </div>
            </button>
          </li>
        ))}
      </ul>

      <div className="flex-grow-1 mx-3" style={tabPaneStyle}>
        {tabsData[activeKey] && tabsData[activeKey].children}
      </div>
    </nav>
  );
};

VerticalTab.TabPane = TabPane;

export default VerticalTab;
