import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import { Icon } from '@quality24/design-system';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import { getUserName } from '@/inpatient-patient-pwa/services/storage';
import UserGreeting from '@/inpatient-patient-pwa/atoms/UserGreeting';
import HospitalLogo from '../HospitalLogo';

import styles from './Topbar.module.scss';
import logo from './navbar-logo.png';

export interface Props {
  patientName?: string;
  renderGreeting?: boolean;
}

const Topbar: React.FunctionComponent<Props> = ({
  patientName,
  renderGreeting,
}) => {
  const { user } = useAuth();
  const storedPatientName = getUserName();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className={styles.wrapper}>
      <div className="container d-flex flex-justify-center flex-items-center">
        <HospitalLogo
          className={styles.logo}
          hospitalId={user.hospitalId}
          defaultLogo={logo}
          height={32}
        />

        {location.pathname !== '/' && location.pathname !== '/login/process' ? (
          <button
            type="button"
            onClick={() => {
              if (location.key !== 'default') {
                navigate(-1);
              } else {
                navigate('/');
              }
            }}
            className={classNames('mb-0 p-0 border-0', styles.navigation)}
          >
            <Icon icon={faChevronLeft} color="white" size="md" />
          </button>
        ) : null}
      </div>
      <div className="mt-3">
        {renderGreeting && (
          <UserGreeting name={storedPatientName || patientName} color="white" />
        )}
      </div>
    </nav>
  );
};

export default Topbar;
