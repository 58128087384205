import * as React from 'react';
import classNames from 'classnames';
import Slider, { SliderProps } from 'rc-slider';
import { Text } from '@quality24/design-system';
import PainScale from '@/inpatient-patient-pwa/molecules/PainScale';
import styles from './PainScaleInput.module.scss';

const PAIN_SCALE_MIN = 0;
const PAIN_SCALE_MAX = 10;

const PAIN_SCALE_COLOR_MAP = [
  { key: 0, color: 'var(--q24-color-pain-0)' },
  { key: 1, color: 'var(--q24-color-pain-1)' },
  { key: 2, color: 'var(--q24-color-pain-2)' },
  { key: 3, color: 'var(--q24-color-pain-3)' },
  { key: 4, color: 'var(--q24-color-pain-4)' },
  { key: 5, color: 'var(--q24-color-pain-5)' },
  { key: 6, color: 'var(--q24-color-pain-6)' },
  { key: 7, color: 'var(--q24-color-pain-7)' },
  { key: 8, color: 'var(--q24-color-pain-8)' },
  { key: 9, color: 'var(--q24-color-pain-9)' },
  { key: 10, color: 'var(--q24-color-pain-10)' },
  { key: 11, color: 'var(--q24-color-pain-11)' },
];

export interface Props extends Omit<SliderProps, 'onChange'> {
  className?: string;
  value?: number;
  onChange: (value: number) => void;
  helperText?: string;
}

const renderHandle =
  (percentage: number, accentColor: string): SliderProps['handleRender'] =>
  (node, { prefixCls, value }) =>
    (
      <div>
        <div
          className={prefixCls}
          style={{
            left: `calc(${percentage}% - 18px)`,
            top: -52,
          }}
          role="spinbutton"
          aria-valuenow={percentage}
        >
          <div
            className={styles.bubble}
            style={{ backgroundColor: accentColor }}
          >
            <Text className="w-100" as="span" color="white" weight="bold">
              {value}
            </Text>
          </div>
          <div
            className={styles.polygon}
            style={{ backgroundColor: accentColor }}
          />
        </div>
        <div
          {...node.props}
          style={{ ...node.props.style, borderColor: accentColor }}
        />
      </div>
    );

const PainScaleInput: React.FunctionComponent<Props> = ({
  className,
  value,
  onChange,
  helperText,
  ...sliderProps
}) => {
  const accentColor =
    PAIN_SCALE_COLOR_MAP?.find((c) => c.key === value)?.color ||
    'var(--q24-color-pain-0)';

  const change = (value || 0) - PAIN_SCALE_MIN;
  const percentage = (change / (PAIN_SCALE_MAX - PAIN_SCALE_MIN)) * 100;

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles['slider-wrapper']}>
        <Slider
          className={styles.slider}
          value={value}
          min={PAIN_SCALE_MIN}
          max={PAIN_SCALE_MAX}
          dotStyle={{
            display: 'none',
          }}
          railStyle={{
            height: '0.6rem',
            borderRadius: '0',
            backgroundColor: 'var(--q24-color-grayDark)',
          }}
          trackStyle={{
            height: '0.6rem',
            borderRadius: '0',
            backgroundColor: accentColor,
            transition: 'background-color 0.2s',
          }}
          handleRender={renderHandle(percentage, accentColor)}
          onChange={(v) => {
            if (typeof v === 'number') onChange(v);
          }}
          {...sliderProps}
        />
      </div>
      <PainScale />
      <div className="mt-2">
        {helperText && (
          <Text as="span" className="mt-2" size="sm1">
            {helperText}
          </Text>
        )}
      </div>
    </div>
  );
};

export default PainScaleInput;
