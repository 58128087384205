import React from 'react';
import { Alert, Service, UUIDv4 } from '@quality24/inpatient-typings';
import { Button, Modal, Text, ListGroup } from '@quality24/design-system';
import { useHTMLParser } from '@quality24/react-hooks';

type PatientCallReasonFragment = Required<
  Pick<
    Service,
    | 'id'
    | 'reason'
    | 'modifier'
    | 'customizationType'
    | 'customizationData'
    | 'actions'
    | 'icons'
    | 'conditional'
  >
>;

interface ComposedPatientCallReason extends PatientCallReasonFragment {
  groupId?: UUIDv4;
  lastAlert?: Required<Pick<Alert, 'createdAt'>> | null;
  activeAlert?: Pick<Alert, 'id'>;
  hasChildren?: boolean;
  children?: Array<ComposedPatientCallReason>;
}

export interface Props {
  className?: string;
  show: boolean;
  reason: ComposedPatientCallReason;
  onHide: () => void;
  onSelect: (reason: ComposedPatientCallReason) => void;
}

const PatientCallSelectorModal: React.FunctionComponent<Props> = ({
  className,
  show,
  reason,
  onHide,
  onSelect,
}) => {
  const [value, setValue] = React.useState<string | undefined>();
  const htmlParser = useHTMLParser();

  const services = React.useMemo(
    () =>
      reason.children?.filter((child) => {
        const innerChildren = child.hasChildren
          ? child.children?.filter((child2) => !child2.activeAlert)
          : undefined;
        return (
          !child.activeAlert && (!innerChildren || innerChildren.length > 0)
        );
      }) || [],
    [reason.children],
  );

  const title = reason.modifier?.title ?? 'Selecione um dos motivos abaixo:';

  const selectedService = React.useMemo(
    () => services.find((service) => service.id === value),
    [services, value],
  );

  const handleSelect = React.useCallback(() => {
    if (selectedService) {
      onSelect(selectedService);
    }
  }, [onSelect, selectedService]);

  return (
    <Modal
      id="patient-call-selector-modal"
      className={className}
      show={show}
      onHide={onHide}
      title={title}
      footer={
        <Button
          fluid
          size="lg"
          onClick={() => handleSelect()}
          disabled={!selectedService}
        >
          {selectedService?.hasChildren ? 'Avançar' : 'Abrir chamado'}
        </Button>
      }
    >
      <div className="px-0">
        {reason.modifier?.description && (
          <Text
            className="text-gray400"
            weight="light"
            dangerouslySetInnerHTML={{
              __html: htmlParser(reason.modifier?.description),
            }}
          />
        )}
        <ListGroup className="mt-3" onChange={(v) => setValue(v)} value={value}>
          {services.map((child) => (
            <ListGroup.Item key={child.id} value={child.id}>
              <Text className="d-flex flex-column" weight="semiBold">
                {child.reason['pt-BR']}
                {child.modifier?.shortDescription && (
                  <Text
                    as="span"
                    className="text-gray400"
                    style={{ marginBottom: '-10px' }}
                  >
                    {child.modifier.shortDescription}
                  </Text>
                )}
              </Text>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    </Modal>
  );
};

export default PatientCallSelectorModal;
