import React from 'react';
import { SurveyPage } from '@quality24/inpatient-typings';
import {
  Heading,
  ScaleSelection,
  Text,
  TextArea,
} from '@quality24/design-system';
import classNames from 'classnames';
import { SurveyHook } from '@/inpatient-patient-pwa/utils/surveyHooks';
import ImprovementsSelector from '@/inpatient-patient-pwa/molecules/ImprovementsSelector';
import styles from './DefaultQuestionPage.module.scss';

export interface Props {
  page: Pick<SurveyPage, 'id' | 'category' | 'type' | 'title' | 'data'>;
  answer: SurveyHook['answer'];
}

const DefaultQuestionPage: React.FunctionComponent<Props> = ({
  page,
  answer,
}) => {
  const description = page.data?.description;
  const improvements = page.data?.improvements;

  const show =
    answer.values.value.score !== undefined && answer.values.value.score < 10;

  return (
    <>
      <Heading as="h1" weight="semiBold" size="lg1">
        {page.title}
      </Heading>
      <Text className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
        {description}
      </Text>

      <ScaleSelection
        className={classNames(styles['scale-selection'], 'mb-5')}
        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        colors={[
          'nps1',
          'nps2',
          'nps3',
          'nps4',
          'nps5',
          'nps6',
          'nps7',
          'nps8',
          'nps9',
          'nps10',
        ]}
        compact
        value={answer.values.value.score}
        onChange={(v) => {
          answer.setFieldValue('value.score', v);
          if (v === 10) {
            answer.setFieldValue('value.improvements', []);
          }
        }}
      />
      {improvements && show && improvements.length > 0 && (
        <ImprovementsSelector
          className="my-4"
          titles={improvements}
          value={answer.values.value.improvements || []}
          onChange={(v: string[]) =>
            answer.setFieldValue('value.improvements', v)
          }
        />
      )}
      <TextArea
        id="formFeedback"
        className="my-4"
        label="Deixe seu comentário abaixo:"
        name="text"
        value={answer.values.value.text}
        errorText={answer.errors?.value?.text}
        onChange={(e) => answer.setFieldValue('value.text', e.target.value)}
      />
    </>
  );
};

export default DefaultQuestionPage;
