import * as React from 'react';
import { Icon, SelectionOption, Text } from '@quality24/design-system';
import classNames from 'classnames';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import styles from './MultipleChoiceWithQuantity.module.scss';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  value: string | number;
  selected?: boolean;
  onClick?: React.MouseEventHandler<Element>;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
}

const ListItem: React.FunctionComponent<Props> = ({
  className,
  title,
  subtitle,
  children,
  value,
  selected,
  onClick,
  ...htmlElementProps
}) => (
  <SelectionOption
    value={value}
    selected={selected}
    onClick={onClick}
    className={classNames(styles['list-item'], className)}
    {...htmlElementProps}
  >
    <div className={styles['title-wrapper']}>
      <Text as="span" className="d-flex flex-column" weight="semiBold">
        {title}
        {subtitle && (
          <Text as="span" color="gray400">
            {subtitle}
          </Text>
        )}
      </Text>
      {selected && <Icon icon={faCheck} />}
    </div>
    <div className="d-flex flex-column align-items-center">{children}</div>
  </SelectionOption>
);

export default ListItem;
