import { create } from 'zustand';
import { ModalStore } from './types';

const useModalStore = create<ModalStore>((set) => ({
  modals: [],

  createModal: (newProps) => {
    set((state) => ({
      modals: [...state.modals, { ...newProps, show: true }],
    }));
  },

  createCustomModal: ({ id, render }) => {
    set((state) => ({ modals: [...state.modals, { id, render }] }));
  },

  destroyModal: (id) =>
    set((state) => ({
      modals: state.modals.filter((modal) => modal.id !== id),
    })),
}));

export default useModalStore;
