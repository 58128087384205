import React from 'react';
import { InputWithIcon, MultipleListGroup } from '@quality24/design-system';
import { JsonServiceCustomizationData } from '@quality24/inpatient-typings';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import styles from './MultipleChoiceWithQuantity.module.scss';
import ListItem from './ListItem';

export interface Props {
  className?: string;
  choices: NonNullable<JsonServiceCustomizationData['choices']>;
  value: Record<string, number>;
  onChange?: (quantity: number, item: string) => void;
}

/**
 * Parses the form input for MULTIPLE_CHOICE customization (string)
 * into the subcomponent state format (Map<string,number>).
 *
 * - When type = with-quantity, parses the input string
 *   `1x Item1, 2x Item2` into {Item1: 1, Item2: 2}
 */
export const parseInput = (
  currentString: string,
  choices?: JsonServiceCustomizationData['choices'],
) => {
  const initialState =
    choices?.reduce((acc, curr) => ({ ...acc, [curr.value]: 0 }), {}) ?? {};

  // Return initial state if no input string
  if (currentString === '') return initialState;

  const newState = currentString
    .split(', ')
    .map((s) => s.split('x '))
    .reduce(
      (acc, current) => ({
        ...acc,
        [current[1]]: parseInt(current[0], 10),
      }),
      {},
    );

  return { ...initialState, ...newState };
};

/**
 * Formats the subcomponent state (Map<string,number>) into form output
 * format for MULTIPLE_CHOICE customizations.
 *
 * - When type = with-quantity, formats to `1x Item1, 2x Item2`
 */
export const formatOutput = (quantities: Record<string, number>) =>
  Object.entries(quantities)
    .map((quantity) =>
      quantity[1] !== 0 ? `${quantity[1]}x ${quantity[0]}` : '',
    )
    .filter((value) => value !== '')
    .join(', ');

const MultipleChoiceWithQuantity: React.FunctionComponent<Props> = ({
  choices,
  value,
  className,
  onChange = () => null,
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

  const inputRefs = React.useRef<HTMLInputElement[]>([]);

  return (
    <MultipleListGroup
      className={className}
      value={selectedOptions}
      onChange={(val, option, selected) => {
        setSelectedOptions(val);
        if (option) {
          onChange(
            !selected ? 0 : choices.find((c) => c.value === option)?.min || 0,
            option,
          );
        }
      }}
    >
      {choices.map((choice, idx) => (
        <ListItem
          value={choice?.value}
          title={choice?.value}
          subtitle={choice?.description}
          key={choice?.value}
        >
          {selectedOptions.includes(choice?.value) && (
            <InputWithIcon
              className={styles['quantity-input']}
              name="quantity"
              ref={(ref) => {
                if (ref !== null) inputRefs.current[idx] = ref;
              }}
              prependIcon={faMinus}
              appendIcon={faPlus}
              onClick={(e) => e.stopPropagation()}
              onPrependClick={(e) => {
                e.stopPropagation();
                inputRefs.current[idx]?.focus();
                if (value[choice?.value] > (choice?.min || 0)) {
                  onChange(value[choice?.value] - 1, choice?.value);
                }
              }}
              disablePrepend={value[choice?.value] === (choice?.min || 0)}
              onAppendClick={(e) => {
                e.stopPropagation();
                inputRefs.current[idx]?.focus();
                if (value[choice?.value] < (choice?.max || 1)) {
                  onChange(value[choice?.value] + 1, choice?.value);
                }
              }}
              disableAppend={value[choice?.value] === (choice?.max || 1)}
              onChange={() => null}
              value={String(value[choice?.value])}
              min={choice?.min}
              max={choice?.max}
              // Prevent emitting blur event to Input
              onPrependMouseDown={(e) => e.preventDefault()}
              onAppendMouseDown={(e) => e.preventDefault()}
            />
          )}
        </ListItem>
      ))}
    </MultipleListGroup>
  );
};

export default MultipleChoiceWithQuantity;
