import React from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { useFormik, FormikErrors } from 'formik';
import {
  Admission,
  AdmissionSurveyAnswer,
  EnumSurveyPageCategory as PageCategory,
  EnumSurveyPageType as PageType,
  ReleasedAdmissionSurvey,
  SurveyPage,
  UUIDv4,
} from '@quality24/inpatient-typings';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import { useToast } from '@quality24/design-system';
import { useNavigate, useSearchParams } from 'react-router-dom';

// GraphQL
// //////////////////////////

const ANSWER_MUTATION = gql`
  mutation AnswerReleasedAdmissionSurveyMutation(
    $id: UUID!
    $answers: [AdmissionSurveyAnswerViewInput]!
  ) {
    answerReleasedAdmissionSurvey(
      input: {
        id: $id
        answeredBy: PATIENT
        admissionSurveyAnswerViews: $answers
      }
    ) {
      releasedAdmissionSurveyView {
        id
      }
    }
  }
`;

const REFUSE_MUTATION = gql`
  mutation RefuseReleasedAdmissionSurveyMutation($id: UUID!) {
    updateReleasedAdmissionSurveyViewById(
      input: { id: $id, patch: { status: REFUSED } }
    ) {
      clientMutationId
    }
  }
`;

// Type defs
// //////////////////////////

type HookSurveyPage = Required<
  Pick<SurveyPage, 'id' | 'category' | 'type' | 'title'>
> &
  Pick<SurveyPage, 'data'>;

export interface ComposedReleasedSurvey
  extends Pick<ReleasedAdmissionSurvey, 'id' | 'createdAt'> {
  admission: Pick<Admission, 'id' | 'patient'>;
  pages: Array<HookSurveyPage>;
}

export interface HookSurveyAnswer
  extends Pick<
    AdmissionSurveyAnswer,
    'id' | 'releasedSurveyId' | 'questionId'
  > {
  category: PageCategory;
  type: PageType;
  value: {
    score?: number;
    smileyScore?: number;
    improvements?: Array<string>;
    text?: string;
  };
}

export interface SurveyHook {
  pages: Array<HookSurveyPage>;
  currentPage: HookSurveyPage;
  answer: {
    values: HookSurveyAnswer;
    errors: FormikErrors<HookSurveyAnswer>;
    setFieldValue: (
      field: string,
      value: unknown,
      shouldValidate?: boolean | undefined,
    ) => void;
  };
  isValid: boolean;
  nav: {
    hasNext: boolean;
    hasPrevious: boolean;
    next: () => void;
    previous: () => void;
    submit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    refuse: () => void;
    answersAmount: number;
  };
  operation: {
    data?: { clientMutationId?: string };
    error?: ApolloError;
    loading: boolean;
  };
}

// Hooks definition
// //////////////////////////

const validationSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.string().required('Campo Obrigatório'),
    releasedSurveyId: Yup.string().required('Campo Obrigatório'),
    questionId: Yup.string().required('Campo Obrigatório'),
    value: Yup.object().shape({
      score: Yup.number(),
      smileyScore: Yup.number(),
      improvements: Yup.array().of(Yup.string()),
      text: Yup.string(),
    }),
  }),
);

/**
 * Map each page to an answer
 * @param param0
 * @returns
 */
const initAnswers = ({
  id,
  pages,
}: ComposedReleasedSurvey): Array<HookSurveyAnswer> =>
  pages.map((page) => ({
    id: uuid() as UUIDv4,
    releasedSurveyId: id,
    questionId: page.id,
    value: {
      score: undefined,
      smileyScore: undefined,
      improvements: undefined,
      text: '',
    },
    // page data
    category: page.category,
    type: page.type,
  }));

/**
 * Interval hook. Periodically run a callback after component is mounted
 * @param {*} callback
 * @param {*} delay
 */
export const useSurvey = (
  releasedSurvey: ComposedReleasedSurvey,
  onSubmit?: () => void,
  onRefuse?: () => void,
): SurveyHook => {
  const [idx, setIdx] = React.useState(0);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentPageIndex = searchParams.get('page');

  React.useEffect(() => {
    const newIndex = Number(currentPageIndex);
    if (Number.isInteger(newIndex)) {
      setIdx(newIndex);
    }
  }, [currentPageIndex]);

  // Router history
  const { toast, error: errorToast } = useToast();

  /**
   * Toasts an error message to the user if any request fails
   */
  const handleMutationError = React.useCallback(
    () =>
      errorToast(
        'Não foi possível fazer sua requisição. Por favor tente novamente.',
      ),
    [errorToast],
  );

  // Prepare mutation
  const [answerSurvey, { data, error, loading: answerLoading }] = useMutation(
    ANSWER_MUTATION,
    {
      onCompleted: () => {
        toast('Muito obrigado! Sua avaliação foi recebida com sucesso.');
        if (onSubmit) onSubmit();
      },
      onError: handleMutationError,
    },
  );

  // Prepare the refuse mutation
  const [refuseSurvey, { loading: refuseLoading }] = useMutation(
    REFUSE_MUTATION,
    {
      variables: { id: releasedSurvey.id },
      onCompleted: () => {
        if (onRefuse) onRefuse();
      },
      onError: handleMutationError,
    },
  );

  // Formik
  const formik = useFormik<Array<HookSurveyAnswer>>({
    initialValues: initAnswers(releasedSurvey),
    validationSchema,
    onSubmit: async (values) => {
      // Filter answers and save only if answer is filled up
      const answers = values
        .filter((v) => {
          if (v.category === 'STATIC_PAGE') return false;
          return (
            v.value.score ||
            v.value.smileyScore ||
            v.value.improvements ||
            v.value.text
          );
        })
        .map(({ type, category, ...answer }) => answer);

      await answerSurvey({
        variables: { id: releasedSurvey.id, answers },
      });
    },
  });

  const { pages } = releasedSurvey;

  // Survey navigation
  const maxPages = React.useMemo(() => pages.length, [pages.length]);
  const hasNext = idx < maxPages - 1;
  const hasPrevious = idx > 0;

  /**
   * Move state to next page
   */
  const next = React.useCallback(() => {
    if (!hasNext) return;
    navigate(`?page=${idx + 1}`);
    setIdx((prev) => prev + 1);
  }, [hasNext, idx, navigate]);

  /**
   * Move state to previous page
   */
  const previous = React.useCallback(() => {
    if (!hasPrevious) return;
    navigate(`?page=${idx - 1}`);
    setIdx((prev) => prev - 1);
  }, [hasPrevious, idx, navigate]);

  return {
    pages,
    currentPage: pages[idx],
    nav: {
      hasNext,
      hasPrevious,
      next,
      previous,
      submit: formik.handleSubmit,
      refuse: refuseSurvey,
      answersAmount: formik.values.filter((v) => {
        if (v.category === 'STATIC_PAGE') return false;
        return (
          v.value.score ||
          v.value.smileyScore ||
          v.value.improvements ||
          v.value.text
        );
      }).length,
    },
    answer: {
      values: formik.values[idx] || {},
      errors: formik.errors[idx] || {},
      setFieldValue: (field, value, shouldValidate) => {
        formik.setFieldValue(`[${idx}].${field}`, value, shouldValidate);
      },
    },
    isValid: formik.isValid,
    operation: { data, error, loading: answerLoading || refuseLoading },
  };
};
