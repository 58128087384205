import { gql, useQuery } from '@apollo/client';
import {
  Admission,
  Connection,
  Floor,
  HospitalInformationSection,
} from '@quality24/inpatient-typings';
import { Spinner } from '@quality24/design-system';
import classNames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet';

import {
  getFloorId,
  getRoomId,
} from '@/inpatient-patient-pwa/services/storage';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';

import HospitalInformationContent from './HospitalInformationContent';

export const SELECT_QUERY = gql`
  query FetchHospitalInformation($admissionId: UUID!, $floorId: UUID!) {
    admissionById(id: $admissionId) {
      id
      wifiLogin
      wifiPassword
      hospital {
        hospitalInformationSections(
          orderBy: ORDER_ASC
          filter: { hideOnPwa: { equalTo: false }, parentId: { isNull: true } }
        ) {
          nodes {
            id
            data
            type
            trackable
            children: childHospitalInformationSections {
              nodes {
                id
                data
                type
                trackable
              }
            }
          }
        }
      }
    }

    floorById(id: $floorId) {
      id
      unitId
    }
  }
`;

type ParsedHospitalInformation = Pick<
  HospitalInformationSection,
  'id' | 'data' | 'type' | 'trackable'
> & {
  children?: Pick<
    HospitalInformationSection,
    'id' | 'data' | 'type' | 'trackable'
  >[];
};

type HospitalInformation = Pick<
  HospitalInformationSection,
  'id' | 'data' | 'type' | 'trackable'
> & {
  children?: Connection<
    Pick<HospitalInformationSection, 'id' | 'data' | 'type' | 'trackable'>
  >;
};

export interface QueryResult {
  admissionById: Pick<Admission, 'id' | 'wifiLogin' | 'wifiPassword'> & {
    hospital: {
      hospitalInformationSections: Connection<HospitalInformation>;
    };
  };
  floorById: Pick<Floor, 'id' | 'unitId'>;
}

export interface Props {
  className?: string;
}

/**
 * Checks if we should display this information
 * @param info
 * @param param1
 * @returns
 */
const checkCondition = (
  info: HospitalInformation,
  {
    unitId,
    floorId,
    roomId,
  }: {
    unitId: string | null;
    floorId: string;
    roomId: string;
  },
) => {
  if (!info.data.condition) return true;
  if (
    !info.data.condition.unitId &&
    !info.data.condition.floorId &&
    !info.data.condition.roomId
  ) {
    return true;
  }

  let result = true;
  if (info.data.condition.unitId) {
    const { includes, excludes } = info.data.condition.unitId;
    if (includes && Array.isArray(includes)) {
      result = result && !!includes.find((id) => id === unitId);
    }
    if (excludes && Array.isArray(excludes)) {
      result = result && !excludes.find((id) => id === unitId);
    }
  }
  if (info.data.condition.floorId) {
    const { includes, excludes } = info.data.condition.floorId;
    if (includes && Array.isArray(includes)) {
      result = result && !!includes.find((id) => id === floorId);
    }
    if (excludes && Array.isArray(excludes)) {
      result = result && !excludes.find((id) => id === floorId);
    }
  }
  if (info.data.condition.roomId) {
    const { includes, excludes } = info.data.condition.roomId;
    if (includes && Array.isArray(includes)) {
      result = result && !!includes.find((id) => id === roomId);
    }
    if (excludes && Array.isArray(excludes)) {
      result = result && !excludes.find((id) => id === roomId);
    }
  }
  return result;
};

const parseHospitalInformation = (
  nodes: HospitalInformation[] | undefined,
): ParsedHospitalInformation[] => {
  if (!nodes) return [];

  return nodes.map((node) => ({
    id: node.id,
    data: node.data,
    type: node.type,
    trackable: node.trackable,
    children:
      node.children?.nodes && node.children?.nodes.length > 0
        ? parseHospitalInformation(node.children?.nodes)
        : undefined,
  }));
};

/**
 * HospitalInformationPage component
 */
const HospitalInformationPage: React.FunctionComponent<Props> = ({
  className,
}) => {
  const {
    user: { admissionId },
  } = useAuth();
  const floorId = getFloorId();
  const roomId = getRoomId();

  const { data, loading, error } = useQuery<QueryResult>(SELECT_QUERY, {
    variables: { admissionId, floorId },
    skip: !admissionId,
  });

  const information: ParsedHospitalInformation[] = React.useMemo(
    () =>
      parseHospitalInformation(
        data?.admissionById?.hospital?.hospitalInformationSections?.nodes.filter(
          (info) =>
            checkCondition(info, {
              unitId: data.floorById?.unitId || null,
              floorId,
              roomId,
            }),
        ),
      ),
    [
      data?.admissionById?.hospital?.hospitalInformationSections?.nodes,
      data?.floorById?.unitId,
      floorId,
      roomId,
    ],
  );

  return (
    <>
      <Helmet>
        <title>Informações Gerais - Quality24</title>
      </Helmet>

      <div className={classNames(className, 'd-flex flex-column')}>
        {!loading && !error && data ? (
          <HospitalInformationContent
            information={information}
            wifiLogin={data?.admissionById.wifiLogin}
            wifiPassword={data?.admissionById.wifiPassword}
          />
        ) : (
          <div className="d-flex flex-justify-center p-4">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default HospitalInformationPage;
