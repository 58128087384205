export type UseStorageHook = () => {
  persistItem: (key: string, item: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  retrieveItem: (
    key: string,
    defaultValue: string | null,
  ) => Promise<string | null>;
};

export type UseStorageSyncHook = () => {
  persistItem: (key: string, item: string) => void;
  removeItem: (key: string) => void;
  retrieveItem: (key: string, defaultValue: string | null) => string | null;
};

/**
 * Access the storage service (localStorage for browser, other for ReactNative)
 * @returns
 */
const useStorageSync: UseStorageSyncHook = () => {
  if (localStorage) {
    return {
      persistItem: (key, item) => localStorage.setItem(key, item),
      removeItem: (key) => localStorage.removeItem(key),
      retrieveItem: (key, defaultValue) =>
        localStorage.getItem(key) || defaultValue,
    };
  }

  return {
    persistItem: () => null,
    removeItem: () => null,
    retrieveItem: () => null,
  };
};

export default useStorageSync;
