import React from 'react';

import classNames from 'classnames';
import { faBell, faHome } from '@fortawesome/pro-regular-svg-icons';
import BottomNavbarItem from '@/inpatient-patient-pwa/molecules/BottomNavbarItem';
import { useNavbar } from '@/inpatient-patient-pwa/contexts/navbar/useNavbar';
import styles from './BottomNavbar.module.scss';

const BottomNavbar: React.FunctionComponent = () => {
  const { activeAlerts } = useNavbar();

  return (
    <nav className={classNames('navbar-bottom', styles.wrapper)}>
      <div className="container">
        <ul className="d-flex flex-justify-around w-100">
          <BottomNavbarItem icon={faHome} text="Início" to="/" exact />
          <BottomNavbarItem
            icon={faBell}
            text="Meus Chamados"
            to="/calls"
            exact
            badgeText={activeAlerts ? `${activeAlerts}` : undefined}
          />
        </ul>
      </div>
    </nav>
  );
};

export default BottomNavbar;
