import { AccordionItem, Text } from '@quality24/design-system';
import {
  Alert,
  AlertEvaluation,
  Connection,
  Employee,
  HospitalDepartment,
  ServiceGroupItem,
  Service,
} from '@quality24/inpatient-typings';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import ResolvedAlertCard from '@/inpatient-patient-pwa/molecules/ResolvedAlertCard';

import styles from './ResolvedAlertList.module.scss';

export interface Props {
  className?: string;
  alerts: Array<ExtendedAlert>;
}

interface ExtendedAlert
  extends Required<Pick<Alert, 'id' | 'createdAt' | 'resolvedAt'>> {
  group: Pick<ServiceGroupItem, 'id' | 'name'> & {
    service: Required<Pick<Service, 'id' | 'reason'>> & {
      department: Required<Pick<HospitalDepartment, 'id' | 'name'>>;
    };
  };
  resolutionEvent: Connection<{
    id: string;
    employee: Required<Pick<Employee, 'id' | 'name'>> | null;
  }>;
  callEvaluation: Connection<
    Pick<AlertEvaluation, 'id' | 'status' | 'value' | 'alertId'>
  >;
}

type GroupByArray = { [key in 'date']: string } & ExtendedAlert;

interface GroupedArray {
  [key: string]: Array<ExtendedAlert>;
}

const groupBy = (xs: Array<GroupByArray>, key: 'date'): GroupedArray =>
  xs.reduce((rv, x) => {
    const newRv: GroupedArray = rv;
    (newRv[x[key]] = newRv[x[key]] || []).push(x);
    return newRv;
  }, {});

const ResolvedAlertList: React.FunctionComponent<Props> = ({
  className,
  alerts,
}) => {
  const datesArray = groupBy(
    alerts.map((alert) => ({
      ...alert,
      date: moment(alert.createdAt).format('DD/MM/yyyy'),
    })),
    'date',
  );

  return Object.keys(datesArray).length > 0 ? (
    <div className="d-flex flex-column gap-2">
      {Object.keys(datesArray).map((day: string) => (
        <AccordionItem
          key={day}
          label={`${day} (${datesArray[day].length})`}
          className={classNames(styles.wrapper, className)}
          contentPadding="xs"
        >
          {datesArray[day].map((alert: ExtendedAlert) => (
            <ResolvedAlertCard key={alert.id} className="mb-2" alert={alert} />
          ))}
        </AccordionItem>
      ))}
    </div>
  ) : (
    <Text>Não existem chamados resolvidos</Text>
  );
};

export default ResolvedAlertList;
