import { UserParam } from '@quality24/inpatient-typings';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import {
  getFloorId,
  getRoomId,
} from '@/inpatient-patient-pwa/services/storage';

export type UserParams = {
  [K in UserParam]?: string | undefined;
};

const useUserParams = (): UserParams => {
  const { user } = useAuth();

  const params: UserParams = {
    admissionId: user.admissionId,
    floorId: getFloorId(),
    roomId: getRoomId(),
  };

  return params;
};

export default useUserParams;
