import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { Admission, Hospital } from '@quality24/inpatient-typings';
import { Alert, Spinner, useToast } from '@quality24/design-system';

import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import ConfirmationModal from '@/inpatient-patient-pwa/organisms/ConfirmationModal';
import PrivateRouter from '@/inpatient-patient-pwa/routes/PrivateRouter';
import {
  getLgpdStatus,
  storeLgpdStatus,
} from '@/inpatient-patient-pwa/services/storage';

/**
 * GraphQL Mutation
 */

export const LGPD_MUTATION = gql`
  mutation lgpdMutation(
    $admissionId: UUID!
    $state: EnumDataProtectionAgreementState!
  ) {
    updateAdmissionsViewById(
      input: {
        id: $admissionId
        patch: { dataProtectionAgreementState: $state }
      }
    ) {
      clientMutationId
    }
  }
`;

/**
 * GraphQL Query
 */

export const DATA_AGREEMENT_QUERY = gql`
  query DataAgreementQuery($hospitalId: UUID!, $admissionId: UUID!) {
    hospital: hospitalsViewById(id: $hospitalId) {
      id
      lgpdConsentHtml
    }
    admission: admissionsViewById(id: $admissionId) {
      id
      dataProtectionAgreementState
    }
  }
`;

export interface QueryResult {
  hospital: Required<Pick<Hospital, 'id' | 'lgpdConsentHtml'>>;
  admission: Required<Pick<Admission, 'id' | 'dataProtectionAgreementState'>>;
}

const LgpdConsentPage: React.FC = () => {
  const [promptConfirmation, setPromptConfirmation] = React.useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const {
    user: { admissionId },
    user,
    logout,
  } = useAuth();
  const [lgpdMutation, { loading: mutationLoading }] =
    useMutation(LGPD_MUTATION);

  const { data, loading } = useQuery<QueryResult>(DATA_AGREEMENT_QUERY, {
    variables: { hospitalId: user.hospitalId, admissionId },
    skip: getLgpdStatus() === 'NA',
  });

  const shouldCheckLgpd = React.useMemo(() => {
    if (!data) return false;
    if (!data.hospital.lgpdConsentHtml) {
      storeLgpdStatus('NA');
      return false;
    }
    if (data.admission.dataProtectionAgreementState === 'ACCEPTED') {
      storeLgpdStatus('ACCEPTED');
      return false;
    }

    return true;
  }, [data]);

  const html = ReactHtmlParser(data?.hospital.lgpdConsentHtml as string);

  const acceptLgpdAgreement = React.useCallback(async () => {
    try {
      await lgpdMutation({
        variables: {
          admissionId,
          state: 'ACCEPTED',
        },
      });
      storeLgpdStatus('ACCEPTED');
      navigate('/');
      toast(
        'Você concordou com os termos de proteção de dados. Seja bem-vindo(a)!',
      );
    } catch (err) {
      toast('Houve um erro na solicitação. Por favor, tente novamente.');
    }
  }, [admissionId, lgpdMutation, toast, navigate]);

  const refuseLgpdAgreement = React.useCallback(async () => {
    try {
      await lgpdMutation({
        variables: {
          admissionId,
          state: 'REFUSED',
        },
      });

      logout();
    } catch (err) {
      toast('Houve um erro na solicitação. Por favor, tente novamente.');
    }
  }, [admissionId, lgpdMutation, logout, toast]);

  if (!shouldCheckLgpd) return <PrivateRouter />;

  return (
    <ConfirmationModal
      onConfirm={acceptLgpdAgreement}
      onCancel={
        promptConfirmation
          ? refuseLgpdAgreement
          : () => setPromptConfirmation(true)
      }
      onHide={() => {
        navigate('/');
      }}
      confirmLabel="Concordar"
      cancelLabel={promptConfirmation ? 'Recusar e deslogar' : 'Recusar'}
      confirmBtnClassName="fw-light"
      show
    >
      {loading || mutationLoading ? (
        <div className="d-flex flex-justify-center p-4">
          <Spinner />
        </div>
      ) : (
        <>
          {html}
          {promptConfirmation && (
            <Alert
              variant="warning"
              className="my-2"
              icon={faExclamationTriangle}
            >
              Você tem certeza? Ao recusar os termos, você será deslogado do
              aplicativo.
            </Alert>
          )}
        </>
      )}
    </ConfirmationModal>
  );
};

export default LgpdConsentPage;
