import {
  IconProp,
  findIconDefinition,
} from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { iconsMap } from './library';

export interface GetterOptions {
  prefix: 'far';
}

export type GetIconFn = (
  iconName?: string,
  defaultIcon?: IconProp,
  options?: GetterOptions,
) => IconProp;

/**
 * Retrieves a FontAwesomeIcon from its name
 * @param iconName the icon name, i.e. faUser
 * @param defaultIcon a default icon to use if icon not found
 * @returns the IconProp if icon is found or the defaultIcon.
 */
export const getIcon: GetIconFn = (iconName, defaultIcon = faInfoCircle) => {
  if (!iconName) return defaultIcon;
  const name = iconsMap[iconName];
  return findIconDefinition({ prefix: 'far', iconName: name }) || defaultIcon;
};
