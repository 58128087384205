import { faWrench } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useMaintenanceMode } from '@quality24/react-maintenance-mode';
import { Icon, Text } from '@quality24/design-system';
import classNames from 'classnames';
import styles from './MaintenanceMode.module.scss';

export interface Props {
  className?: string;
}

const MaintenanceMode: React.FunctionComponent<Props> = ({ className }) => {
  const { isEnabled } = useMaintenanceMode();

  return isEnabled ? (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.header} />
      <div className={styles.content}>
        <div>
          <Icon icon={faWrench} size="xl" />
          <Text className="text-1">Este serviço está em manutenção.</Text>
          <Text className="text-2">Por favor, aguarde para utilizá-lo.</Text>
        </div>
      </div>
    </div>
  ) : null;
};

export default MaintenanceMode;
