import React from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import {
  Alert,
  Connection,
  Employee,
  HospitalDepartment,
  ServiceGroupItem,
  Service,
  SubscriptionPayload,
  AlertEvaluation,
  UUIDv4,
  ServiceGroup,
} from '@quality24/inpatient-typings';
import { PillsFilter, Spinner } from '@quality24/design-system';
import moment from 'moment';
import orderBy from 'lodash/orderBy';

import ApiError from '@/inpatient-patient-pwa/molecules/ApiError';
import ActiveAlertList from '@/inpatient-patient-pwa/organisms/ActiveAlertList';
import ResolvedAlertList from '@/inpatient-patient-pwa/organisms/ResolvedAlertList';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import { useNavbar } from '@/inpatient-patient-pwa/contexts/navbar/useNavbar';

const AlertFragment = gql`
  fragment AlertFragment on Alert {
    id
    createdAt
    resolvedAt
    cancelledAt
    group {
      id
      name
      service {
        id
        reason
        department {
          id
          name
        }
      }
      group {
        id
        config
      }
    }
    callEvaluation: alertEvaluations(orderBy: CREATED_AT_ASC) {
      nodes {
        id
        status
        value
        alertId
      }
    }
  }
`;

export const SELECT_QUERY = gql`
  query FetchTrackingPageData($admissionId: UUID!) {
    activeAlerts: alerts(
      orderBy: CREATED_AT_ASC
      filter: {
        admissionId: { equalTo: $admissionId }
        resolvedAt: { isNull: true }
        cancelledAt: { isNull: true }
        group: { group: { config: { contains: { concierge: true } } } }
      }
    ) {
      nodes {
        ...AlertFragment
      }
    }
    resolvedAlerts: alerts(
      orderBy: CREATED_AT_DESC
      filter: {
        admissionId: { equalTo: $admissionId }
        resolvedAt: { isNull: false }
        cancelledAt: { isNull: true }
        group: { group: { config: { contains: { concierge: true } } } }
      }
    ) {
      nodes {
        ...AlertFragment
        resolutionEvent: alertEvents(filter: { type: { equalTo: SOLUTION } }) {
          nodes {
            id
            employee {
              id
              name
            }
          }
        }
      }
    }
  }
  ${AlertFragment}
`;

export const ALERT_SUBSCRIPTION = gql`
  subscription onAlertChanged($admissionId: UUID!) {
    alertChanges: alertChangesByAdmissionId(admissionId: $admissionId) {
      event
      alert: alertData {
        ...AlertFragment
        resolutionEvent: alertEvents(filter: { type: { equalTo: SOLUTION } }) {
          nodes {
            id
            employee {
              id
              name
            }
          }
        }
      }
    }
  }
  ${AlertFragment}
`;

interface ExtendedAlert
  extends Required<
    Pick<Alert, 'id' | 'createdAt' | 'resolvedAt' | 'cancelledAt'>
  > {
  group: Pick<ServiceGroupItem, 'id' | 'name'> & {
    service: Required<Pick<Service, 'id' | 'reason'>> & {
      department: Required<Pick<HospitalDepartment, 'id' | 'name'>>;
    };
    group: Pick<ServiceGroup, 'id' | 'config'>;
  };
  callEvaluation: Connection<
    Pick<AlertEvaluation, 'id' | 'status' | 'value' | 'alertId'>
  >;
}

interface ExtendedResolvedAlert extends ExtendedAlert {
  resolutionEvent: Connection<{
    id: UUIDv4;
    employee: Required<Pick<Employee, 'id' | 'name'>> | null;
  }>;
}

export interface QueryResult {
  activeAlerts: Connection<ExtendedAlert>;
  resolvedAlerts: Connection<ExtendedResolvedAlert>;
}

interface SubscriptionResult {
  subscriptionData: {
    data: {
      alertChanges: SubscriptionPayload<'alert', ExtendedAlert>;
    };
  };
}

/**
 * Handles a new alert changes event
 * @param {*} prev
 * @param {*} param1
 */
const handleAlertChanges = (
  prev: QueryResult,
  { subscriptionData }: SubscriptionResult,
) => {
  if (!subscriptionData.data) return prev;
  const { event, alert } = subscriptionData.data.alertChanges;

  // Check if alert is from a concierge group
  if (!alert.group.group.config?.concierge === true) {
    return prev;
  }

  let activeAlerts: ExtendedAlert[] = [];
  let resolvedAlerts: ExtendedResolvedAlert[] = [];

  switch (event) {
    case 'insert-alertEvent':
    case 'insert-alertEvaluation':
    case 'update-alertEvaluation':
    case 'update': {
      const filteredActiveNodes = prev.activeAlerts.nodes.filter(
        (n) => n.id !== alert.id,
      );

      const filteredResolvedNodes = prev.resolvedAlerts.nodes.filter(
        (n) => n.id !== alert.id,
      );

      activeAlerts =
        alert.resolvedAt || alert.cancelledAt
          ? filteredActiveNodes
          : [...filteredActiveNodes, alert];

      resolvedAlerts = alert.resolvedAt
        ? [...filteredResolvedNodes, alert as ExtendedResolvedAlert]
        : filteredResolvedNodes;
      break;
    }

    case 'insert':
      activeAlerts = [...prev.activeAlerts.nodes, alert];
      resolvedAlerts = [...prev.resolvedAlerts.nodes];
      break;

    default:
      return prev;
  }

  activeAlerts = orderBy(
    activeAlerts,
    (item) => moment(item.createdAt).format('YYYYMMDD'),
    ['asc'],
  );

  resolvedAlerts = orderBy(
    resolvedAlerts,
    (item) => moment(item.resolvedAt).format('YYYYMMDD'),
    ['desc'],
  );

  return {
    activeAlerts: { ...prev.activeAlerts, nodes: activeAlerts },
    resolvedAlerts: { ...prev.resolvedAlerts, nodes: resolvedAlerts },
  };
};

const PatientCallTrackingPage: React.FunctionComponent = () => {
  const {
    user: { admissionId },
  } = useAuth();

  const { setRenderGreeting } = useNavbar();
  React.useEffect(() => setRenderGreeting(true), [setRenderGreeting]);

  const [filterValue, setFilterValue] = React.useState('open');

  const { data, loading, error, refetch, subscribeToMore } =
    useQuery<QueryResult>(SELECT_QUERY, {
      variables: { admissionId },
      fetchPolicy: 'network-only',
    });

  React.useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: ALERT_SUBSCRIPTION,
      variables: { admissionId },
      updateQuery: handleAlertChanges,
    });
    return unsubscribe;
  }, [admissionId, subscribeToMore]);

  const activeAlerts = React.useMemo(
    () => data?.activeAlerts.nodes || [],
    [data?.activeAlerts],
  );
  const resolvedAlerts = React.useMemo(
    () => data?.resolvedAlerts.nodes || [],
    [data?.resolvedAlerts],
  );

  const filterOptions = React.useMemo(
    () => [
      { id: 'open', label: `Abertos (${activeAlerts.length || 0})` },
      { id: 'finished', label: `Finalizados (${resolvedAlerts.length || 0})` },
    ],
    [activeAlerts, resolvedAlerts],
  );

  return (
    <>
      <Helmet>
        <title>Chamados - Quality24</title>
      </Helmet>

      <div>
        {/* Loading state */}
        {loading && (
          <div className="d-flex flex-justify-center p-2">
            <Spinner />
          </div>
        )}

        {/* Error state */}
        {error && <ApiError error={error} refetch={refetch} />}

        {!loading && !error && (
          <div className="d-flex flex-column gap-2">
            <PillsFilter
              options={filterOptions}
              name=""
              size="md"
              value={filterValue}
              onChange={(_name, val) => setFilterValue(val)}
            />
            <div className="mt-3">
              {/* Alert list */}
              {filterValue === 'open' && (
                <ActiveAlertList alerts={activeAlerts} />
              )}
              {/* Resolved Alert list */}
              {filterValue === 'finished' && (
                <ResolvedAlertList alerts={resolvedAlerts} />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientCallTrackingPage;
