import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import {
  Admission,
  Connection,
  Patient,
  ReleasedAdmissionSurvey,
  Survey,
  SurveyPage,
} from '@quality24/inpatient-typings';
import { Spinner } from '@quality24/design-system';

import ApiError from '@/inpatient-patient-pwa/molecules/ApiError';
import DailySurvey from '@/inpatient-patient-pwa/organisms/DailySurvey';
import { getFloorId } from '@/inpatient-patient-pwa/services/storage';

export const SELECT_QUERY = gql`
  query FetchReleasedSurvey($releasedSurveyId: UUID!) {
    releasedSurvey: releasedAdmissionSurveyViewById(id: $releasedSurveyId) {
      id
      status
      createdAt
      admission {
        id
        patient {
          id
          name
        }
      }
      survey {
        id
        pages: surveyPageViews(
          orderBy: ORDER_ASC
          filter: {
            order: { greaterThanOrEqualTo: 0 }
            state: { equalTo: ENABLED }
          }
        ) {
          nodes {
            id
            category
            type
            title
            data
            conditional
          }
        }
      }
    }
  }
`;

type ComponentSurveyPage = Required<
  Pick<SurveyPage, 'id' | 'category' | 'type' | 'title'>
> &
  Pick<SurveyPage, 'data' | 'conditional'>;

interface QueryReleasedSurvey
  extends Required<
    Pick<ReleasedAdmissionSurvey, 'id' | 'status' | 'createdAt'>
  > {
  admission: Pick<Admission, 'id'> & {
    patient: Pick<Patient, 'id' | 'name'>;
  };
  survey: Pick<Survey, 'id'> & {
    pages: Connection<ComponentSurveyPage>;
  };
}

interface ComposedReleasedSurvey
  extends Required<
    Pick<ReleasedAdmissionSurvey, 'id' | 'status' | 'createdAt'>
  > {
  admission: Pick<Admission, 'id'> & {
    patient: Pick<Patient, 'id' | 'name'>;
  };
  pages: Array<ComponentSurveyPage>;
}

export interface QueryResult {
  releasedSurvey: QueryReleasedSurvey;
}

/**
 * Parses the survey data.
 * @param survey
 * @returns
 */
const parseSurvey = (
  releasedSurvey: QueryReleasedSurvey,
): ComposedReleasedSurvey => {
  const pages = releasedSurvey.survey.pages.nodes.filter((page) => {
    if (!page.conditional) return true;
    const { visibility } = page.conditional;
    const floorId = getFloorId();

    // If visibility rule has includes, check if arg is in the rules
    if (visibility?.includes && visibility.includes.floorIds.length > 0) {
      return visibility.includes.floorIds.includes(floorId);
    }
    // If visibility rule only has excludes, check if arg is NOT in the rules
    if (visibility?.excludes && visibility.excludes.floorIds.length > 0) {
      return !visibility.excludes.floorIds.includes(floorId);
    }
    return true;
  });

  return {
    ...releasedSurvey,
    pages,
  };
};

const DailySurveyPage: React.FunctionComponent = () => {
  // Router states
  const params = useParams();
  const navigate = useNavigate();

  // Fetch admission data
  const { data, loading, error, refetch } = useQuery<QueryResult>(
    SELECT_QUERY,
    {
      variables: { releasedSurveyId: params.surveyId },
    },
  );

  // If no survey found go to home page
  React.useEffect(() => {
    if (
      !loading &&
      !error &&
      (!data?.releasedSurvey || data.releasedSurvey.status === 'ANSWERED')
    ) {
      navigate('/', { replace: true });
    }
  }, [loading, error, navigate, data?.releasedSurvey]);

  const survey = data?.releasedSurvey && parseSurvey(data.releasedSurvey);
  return (
    <>
      <Helmet>
        <title>Avaliação Diária - Quality24</title>
      </Helmet>

      <div>
        {/* Loading state */}
        {loading && (
          <div className="d-flex flex-justify-center p-4">
            <Spinner />
          </div>
        )}
        {/* Error state */}
        {error && <ApiError error={error} refetch={refetch} />}

        {/* Survey entry point */}
        {!loading && !error && survey && <DailySurvey survey={survey} />}
      </div>
    </>
  );
};

export default DailySurveyPage;
