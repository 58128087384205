import { gql } from '@apollo/client';

export const orderDetailsFragment = gql`
  fragment OrderDetailsFragment on Alert {
    id
    customization
    details
    origin
    integration
    resolvedAt
    cancelledAt
    createdAt
    room {
      id
      name
      floor {
        id
        name
        unit {
          id
          name
        }
      }
    }
    service: reason {
      id
      reason
      modifier
      department {
        id
        name
      }
      parent {
        id
        reason
      }
    }
    group {
      id
      name
      group {
        id
        config
      }
    }

    createdBy {
      id
      name
      registry
    }

    cancelledBy {
      id
      name
      registry
    }

    currentStep {
      id
      name
      nextSteps: childProcessSolutionStepClosures(
        filter: { depth: { greaterThanOrEqualTo: 1 } }
      ) {
        nodes {
          step: child {
            id
            name
          }
        }
      }
    }
    previousEvents: alertEvents(orderBy: CREATED_AT_ASC) {
      nodes {
        id
        createdAt
        employee {
          id
          name
        }
        step: solutionStep {
          id
          name
        }
      }
    }

    admission {
      id
      integration
      patient {
        id
        name
        gender
        birthday
      }
    }

    customFields: orderCustomFieldValues(orderBy: [ORDER_ASC]) {
      nodes {
        id
        label
        value
      }
    }
  }
`;

export const GET_ORDER_DETAIL = gql`
  query GetOrderDetail($orderId: UUID!) {
    order: alertById(id: $orderId) {
      ...OrderDetailsFragment
    }
  }
  ${orderDetailsFragment}
`;
