export interface RuntimeConfig {
  API_URL?: string;
  WS_URL?: string;
}

export const runtimeConfig =
  (window as unknown as Record<'runtimeConfig', RuntimeConfig>).runtimeConfig ||
  {};

export default runtimeConfig;
