import { EnumAlertOrigin } from '@quality24/inpatient-typings';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { AuthPayload, AuthUserRole, LoginState } from '../@types/auth';

Cookies.defaults = {
  secure: process.env.NODE_ENV === 'production',
  // httponly: true,
  expires: 90,
};

interface UserLocalStorage {
  id: string;
  role: AuthUserRole;
  hospitalId?: string;
}

interface DecodedAccessToken {
  aud: string;
  exp: number;
  hospital_id?: string;
  iat: number;
  iss?: string;
  role: string;
  user_id: string;
}

function setWithExpiry(
  key: string,
  value: LoginState | string | UserLocalStorage,
  ttl: number,
) {
  const now = new Date();
  const expiry = now.getTime() + ttl * 24 * 60 * 60 * 1000;

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

/**
 * Storage keys
 */
const StorageKey = {
  AUTH_STATUS: '_authStatus',
  AUTH_USER: '_authUser',
  AUTH_ACCESS_TOKEN: '_authAccessToken',
  AUTH_REFRESH_TOKEN: '_authRefreshToken',
  AUTH_ID_TOKEN: '_authIdToken',
  ADMISSION_ID: '_admissionId',
  THEME: 'q24_theme',
  USER_TYPE: '_userType',
  USER_NAME: '_userName',
  FLOOR_ID: '_floorId',
  ROOM_ID: '_roomId',
  LOGIN_ID: '_loginId',
  PUSH_TOKEN: '_pushToken',
  LGPD_STATUS: '_lgpdStatus',
};

/**
 * Stores the AuthPayload in browser persistent storage
 * @param payload
 */
export const storeAuthPayload = async (payload: AuthPayload): Promise<void> => {
  // Set expiration to 30d (the time of the refresh token)
  const expires = 30;

  // Store tokens to secure cookies
  setWithExpiry(StorageKey.AUTH_STATUS, payload.status, expires);
  setWithExpiry(StorageKey.AUTH_USER, JSON.stringify(payload.user), expires);
  if (typeof payload.accessToken === 'string') {
    Cookies.set(StorageKey.AUTH_ACCESS_TOKEN, payload.accessToken, { expires });
  }
  if (typeof payload.idToken === 'string') {
    Cookies.set(StorageKey.AUTH_ID_TOKEN, payload.idToken, { expires });
  }
  if (typeof payload.refreshToken === 'string') {
    Cookies.set(StorageKey.AUTH_REFRESH_TOKEN, payload.refreshToken, {
      expires,
    });
  }
};

/**
 * Erases the AuthPayload data from browser storage.
 */
export const deleteAuthPayload = (): void => {
  localStorage.removeItem(StorageKey.AUTH_STATUS);
  localStorage.removeItem(StorageKey.AUTH_USER);
  localStorage.removeItem(StorageKey.USER_TYPE);
  localStorage.removeItem(StorageKey.USER_NAME);
  localStorage.removeItem(StorageKey.ADMISSION_ID);
  localStorage.removeItem(StorageKey.LOGIN_ID);
  localStorage.removeItem(StorageKey.FLOOR_ID);
  localStorage.removeItem(StorageKey.ROOM_ID);
  Cookies.remove(StorageKey.AUTH_ACCESS_TOKEN);
  Cookies.remove(StorageKey.AUTH_REFRESH_TOKEN);
  Cookies.remove(StorageKey.AUTH_ID_TOKEN);
  Cookies.remove(StorageKey.LGPD_STATUS);
};

/**
 * Gets the AuthPayload data from browser storage.
 */
export const getAuthPayload = (): AuthPayload => {
  const userStr = getWithExpiry(StorageKey.AUTH_USER);

  const authPayload = {
    status: getWithExpiry(StorageKey.AUTH_STATUS) as LoginState,
    accessToken: Cookies.get(StorageKey.AUTH_ACCESS_TOKEN) as string,
    refreshToken: Cookies.get(StorageKey.AUTH_REFRESH_TOKEN),
    idToken: Cookies.get(StorageKey.AUTH_ID_TOKEN) as string,
    user: userStr ? JSON.parse(userStr) : { role: 'GUEST' },
  };

  return authPayload;
};

/**
 * Stores the admissionId
 * @param admissionId
 */
export const storeAdmissionId = (admissionId?: string): void => {
  if (!admissionId) return;

  // Set expiration to 30d (the time of the refresh token)
  const expires = 30;
  setWithExpiry(StorageKey.ADMISSION_ID, admissionId, expires);
};

/**
 * Retrieves the admissionId
 * @returns
 */
export const getAdmissionId = (): string | undefined =>
  getWithExpiry(StorageKey.ADMISSION_ID);

//
// Token getter helpers
//

/**
 * Returns the access token.
 */
export const getAccessToken = (): string | undefined =>
  Cookies.get(StorageKey.AUTH_ACCESS_TOKEN);

/**
 * Returns the id token.
 */
export const getIdToken = (): string | undefined =>
  Cookies.get(StorageKey.AUTH_ID_TOKEN);

/**
 * Returns the access token.
 */
export const getRefreshToken = (): string | undefined =>
  Cookies.get(StorageKey.AUTH_REFRESH_TOKEN);

/**
 * Returns the decoded JWT token.
 */
export const getDecodedToken = (): DecodedAccessToken | undefined => {
  const token = getAccessToken();
  return token ? jwtDecode(token) : undefined;
};

//
// Local Storage Section
//

/**
 * Stores the themeName into the browser local storage
 * @param themeName
 */
export const storeTheme = (themeName?: string): void => {
  if (!themeName) return;
  localStorage.setItem(StorageKey.THEME, themeName);
};

/**
 * Retrieves the themeName
 * @returns
 */
export const getTheme = (defaultTheme?: string): string => {
  const theme = localStorage.getItem(StorageKey.THEME);
  return theme ?? defaultTheme ?? 'q24';
};

/**
 * Stores User Identity into the browser local storage
 */

export const storeUserType = (userType?: string): void => {
  if (!userType) return;
  const expires = 30;
  setWithExpiry(StorageKey.USER_TYPE, userType, expires);
};

export const getUserType = (): EnumAlertOrigin =>
  getWithExpiry(StorageKey.USER_TYPE);

export const storeUserName = (userName?: string): void => {
  if (!userName) return;
  const expires = 30;
  setWithExpiry(StorageKey.USER_NAME, userName, expires);
};

export const getUserName = (): string => getWithExpiry(StorageKey.USER_NAME);

export const storeFloorId = (floorId?: string): void => {
  if (!floorId) return;
  const expires = 30;
  setWithExpiry(StorageKey.FLOOR_ID, floorId, expires);
};

export const getFloorId = (): string => getWithExpiry(StorageKey.FLOOR_ID);

export const storeRoomId = (roomId?: string): void => {
  if (!roomId) return;
  const expires = 30;
  setWithExpiry(StorageKey.ROOM_ID, roomId, expires);
};

export const getRoomId = (): string => getWithExpiry(StorageKey.ROOM_ID);

export const storeLoginId = (loginId?: string): void => {
  if (!loginId) return;
  const expires = 30;
  setWithExpiry(StorageKey.LOGIN_ID, loginId, expires);
};

export const getLoginId = (): string => getWithExpiry(StorageKey.LOGIN_ID);

export const storePushToken = (token?: string): void => {
  if (!token) return;
  const expires = 30;
  setWithExpiry(StorageKey.PUSH_TOKEN, token, expires);
};

export const getPushToken = (): string => getWithExpiry(StorageKey.PUSH_TOKEN);

export const storeLgpdStatus = (status?: string): void => {
  if (!status) return;
  const expires = 30;
  Cookies.set(StorageKey.LGPD_STATUS, status, { expires });
};

export const getLgpdStatus = (): string | undefined =>
  Cookies.get(StorageKey.LGPD_STATUS);
