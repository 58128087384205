import * as React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import Text from '../Text';
import { FontSizeProps } from '../../../@types/theme';

import styles from './Radio.module.scss';

// Interfaces
// //////////////////////////

export interface Props extends React.HTMLAttributes<HTMLElement> {
  name: string;
  label: string;
  ellipsize?: boolean;
  value: string;
  textSize?: FontSizeProps;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

/**
 * <Radio> component
 */
export const Radio: React.FunctionComponent<Props> = ({
  id,
  className,
  style,
  name,
  label,
  ellipsize = true,
  value,
  textSize = 'sm2',
  checked,
  disabled,
  onChange,
  ...htmlElementProps
}) => {
  const inputId = id ?? uuid();
  return (
    <div
      className={classNames(styles.label, className, { disabled, ellipsize })}
      title={label}
      {...htmlElementProps}
    >
      <input
        id={inputId}
        type="radio"
        className={styles.radio}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />

      <Text
        as="label"
        htmlFor={inputId}
        size={textSize}
        weight={checked ? 'bold' : 'light'}
      >
        {label}
      </Text>
    </div>
  );
};

export default Radio;
