import * as React from 'react';
import classNames from 'classnames';

import Text from '../../atoms/Text';
import Icon from '../../atoms/Icon';
import TabPane, { Props as TabPaneProps } from './TabPane';

import styles from './VerticalTabMobile.module.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  activeKey?: number | string;
  children: React.ReactNode;
  onTabSelected?: (tabKey: number | string) => void;
  tabPaneStyle?: React.CSSProperties;
}

interface TabSubComponents {
  TabPane: React.FunctionComponent<TabPaneProps>;
}

interface TabData {
  [key: string | number]: TabPaneProps;
}

const VerticalTabMobile: React.FunctionComponent<Props> & TabSubComponents = ({
  className,
  activeKey = 0,
  children,
  onTabSelected,
  tabPaneStyle,
  ...htmlElementProps
}: Props) => {
  const [tabsData, setTabsData] = React.useState<TabData>({});

  // Check tabs from children
  React.useEffect(() => {
    const data: TabData = {};

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;

      const { props } = element;
      // eslint-disable-next-line react/prop-types
      data[props.tabKey] = props;
    });

    setTabsData(data);
  }, [children]);

  const handleTabChange = React.useCallback(
    (newTabKey: string | number) => {
      if (onTabSelected) onTabSelected(newTabKey);
    },
    [onTabSelected],
  );

  return (
    <nav className={classNames('d-flex', className)} {...htmlElementProps}>
      <ul className={styles['nav-tab']}>
        {Object.values(tabsData).map(({ tabKey, title, icon, description }) => (
          <div key={tabKey}>
            <li
              key={tabKey}
              className={classNames(styles['nav-item'], {
                active: tabKey === activeKey,
              })}
            >
              <button
                type="button"
                className={styles['nav-link']}
                onClick={() => handleTabChange(tabKey)}
              >
                {icon && <Icon className="icon" icon={icon} size="sm" />}

                <div className={styles['nav-link-content']}>
                  <Text
                    className="title mb-2"
                    size="lg1"
                    weight="bold"
                    color="disabled"
                  >
                    {title}
                  </Text>

                  <Text className="description" weight="light" color="disabled">
                    {description}
                  </Text>
                </div>
              </button>
            </li>

            {activeKey === tabKey && (
              <div className="py-3" style={tabPaneStyle}>
                {tabsData[activeKey].children}
              </div>
            )}
          </div>
        ))}
      </ul>
    </nav>
  );
};

VerticalTabMobile.TabPane = TabPane;

export default VerticalTabMobile;
