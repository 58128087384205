import * as React from 'react';
import { faBarcodeScan } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Image, Surface, Text, useToast } from '@quality24/design-system';
import jwtDecode from 'jwt-decode';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  storeAdmissionId,
  storeLoginId,
  storeUserName,
  storeUserType,
} from '@/inpatient-patient-pwa/services/storage';
import LoginProcessForm from '@/inpatient-patient-pwa/organisms/LoginProcessForm';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import styles from './LoginPage.module.scss';
import sprite from './sprite.svg';

export interface SearchString {
  user?: string;
  pass?: string;
}

interface Token {
  token: string;
  validations?: string[];
}

const LoginPage: React.FunctionComponent = () => {
  const { error } = useToast();
  const { search } = useLocation();
  const token = React.useMemo(
    () => new URLSearchParams(search).get('token'),
    [search],
  );

  const decodedToken: Token | null = token ? jwtDecode<Token>(token) : null;

  const validations: string[] =
    decodedToken && decodedToken.validations ? decodedToken.validations : [];

  const { login } = useAuth();
  const navigate = useNavigate();

  const loginPWA = React.useCallback(
    (values) => {
      if (token) {
        login(
          token,
          values.name,
          values.userType,
          values.birthDate,
          values.admissionNumber,
        )
          .then((data) => {
            storeUserType(
              (values.userType as string).toUpperCase() === 'PATIENT_COMPANION'
                ? 'RELATIVE'
                : 'PATIENT',
            );
            storeLoginId(
              data.data?.authFlow?.authenticationResult?.userData?.loginId,
            );
            if (values.name) storeUserName(values.name);

            /**
             * Temporarily store admissionId
             * This might be removed after refreshDeviceTokenWithType is updated in the API to return the admissionId
             */
            storeAdmissionId(
              data.data?.authFlow?.authenticationResult?.userData?.admissionId,
            );

            navigate('/');
          })
          .catch((err) => {
            if (process.env.NODE_ENV === 'development') {
              // eslint-disable-next-line no-console
              console.log('Error on login', err);
            }
            error(
              'Não foi possível carregar sua internação. Por favor, verifique seus dados e conexão com a internet.',
            );
          });
      }
    },
    [token, login, navigate, error],
  );

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Confirme sua identidade</title>
      </Helmet>

      {token ? (
        <>
          <Image
            className="w-100"
            src={sprite}
            alt="Ilustração com profissionais da saúde prontos para atender"
            fluid
          />
          <div className={styles.content}>
            <LoginProcessForm onSubmit={loginPWA} validations={validations} />
          </div>
        </>
      ) : (
        <div className="w-100 h-100 d-flex flex-column justify-content-center p-4 text-center">
          <Surface className="d-flex flex-column align-items-center justify-content-center gap-3">
            <Icon icon={faBarcodeScan} size="lg" />
            <Text>Por favor, escaneie o QR code fornecido.</Text>
          </Surface>
        </div>
      )}
    </div>
  );
};
export default LoginPage;
