/* eslint-disable import/prefer-default-export */
import React from 'react';
import { GenericTheme } from '@quality24/inpatient-typings';
import { AuthContext } from '@/inpatient-patient-pwa/types/auth';
import authContext from './authContext';

export const useAuth = <TTheme extends GenericTheme>(): AuthContext<TTheme> => {
  const context = React.useContext<AuthContext<TTheme>>(
    authContext as unknown as React.Context<AuthContext<TTheme>>,
  );
  return context;
};
