import * as React from 'react';
import type { Theme } from '../../@types/theme';
import defaultTheme from '../../styles/theme';
import { convertThemeVars, createTheme } from '../../utils/themeUtils';

export interface IThemeProvider<TTheme extends Theme> {
  theme: TTheme;
  updateTheme: (theme: TTheme) => void;
}

export interface Props {
  theme?: Partial<Theme>;
}

// The initial authorization context
export const context = React.createContext<IThemeProvider<Theme>>({
  theme: defaultTheme,
  updateTheme: () => null,
});

/**
 *  Injects a <style> tag with css variables to override default theme
 */
const injectUserTheme = (userTheme: Partial<Theme>) => {
  const styleSheet = document.createElement('style');
  styleSheet.setAttribute('type', 'text/css');
  styleSheet.setAttribute('rel', 'stylesheet');
  styleSheet.innerHTML = `
  :root {${convertThemeVars(userTheme as unknown as Record<string, unknown>)
    .map((v) => `  ${v}`)
    .join('\n')}
  }`;
  // Inserting style at the end of the <head>
  document.head.appendChild(styleSheet);
};

const ContextProvider: React.FunctionComponent<Props> = ({
  theme: userTheme,
  children,
}) => {
  const [theme, setTheme] = React.useState<Theme>(createTheme(userTheme));

  React.useLayoutEffect(() => {
    if (userTheme) {
      injectUserTheme(userTheme);
    }
  }, [userTheme]);

  const updateTheme = React.useCallback((newTheme: Theme) => {
    // Update theme state
    setTheme(createTheme(newTheme));
    // inject variables
    injectUserTheme(newTheme);
  }, []);

  const providerValue = React.useMemo(
    () => ({
      theme,
      updateTheme,
    }),
    [theme, updateTheme],
  );
  return <context.Provider value={providerValue}>{children}</context.Provider>;
};

export default ContextProvider;
