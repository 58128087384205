import React from 'react';

export interface NavbarContext {
  activeAlerts?: number;
  renderGreeting: boolean;
  setRenderGreeting: (value: boolean) => void;
}

const navbarContext = React.createContext<NavbarContext>({
  activeAlerts: 0,
  renderGreeting: false,
  setRenderGreeting: () => undefined,
});

export default navbarContext;
