/* eslint-disable import/prefer-default-export */
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import {
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBarcodeScan,
  faBars,
  faBell,
  faBellSlash,
  faBriefcaseMedical,
  faBuilding,
  faBuildings,
  faCalendarClock,
  faCalendarDays,
  faChartUser,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircle,
  faClipboardCheck,
  faClose,
  faCommentQuestion,
  faDoorClosed,
  faEdit,
  faEllipsisVertical,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFaceAngry,
  faFaceFrownSlight,
  faFaceGrin,
  faFaceGrinHearts,
  faFaceSmile,
  faFileExport,
  faFilter,
  faHeart,
  faHexagonExclamation,
  faHome,
  faHouseChimneyMedical,
  faImagePolaroidUser,
  faKey,
  faLeft,
  faLock,
  faMagnifyingGlass,
  faMemoCircleInfo,
  faMessageHeart,
  faMessageLines,
  faMessagesQuestion,
  faMinus,
  faPen,
  faPencil,
  faPlay,
  faPlus,
  faQuestionCircle,
  faRankingStar,
  faRedo,
  faRefresh,
  faRightFromBracket,
  faStar,
  faStopwatch,
  faTabletButton,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faUniversalAccess,
  faUser,
  faUserDoctor,
  faUserMd,
  faVenusMars,
  faVolume,
  faVolumeSlash,
  faWrench,
  faXmarkLarge,
  faChartLine,
  faUserCheck,
  faReply,
  faCommentLines,
  faLightbulbOn,
  faChartBar,
  faClipboardUser,
  faFileArrowDown,
  faBedPulse,
  faChartPie,
  faUserNurse,
  faChartLineUp,
  faHospitalUser,
  faCake,
  faStretcher,
  faClipboardMedical,
  faFileShield,
  faMemoPad,
  faMessage,
  faMagnifyingGlassChart,
  faSort,
  faPlusCircle,
  faCircleCheck,
  faAlarmClock,
  faUsers,
  faLocationDot,
  faRotateExclamation,
  faHourglassHalf,
  faIdCardClip,
  faFaceMeh,
  faBroom,
  faClock,
  faPumpSoap,
  faHashtag,
  faSackDollar,
  faBan,
  faPotFood,
  faBed,
  faFlask,
  faStethoscope,
  faFaceVomit,
  faNotesMedical,
  faVials,
  faBrain,
  faStarOfLife,
  faHospital,
  faVirus,
  faJoint,
  faCauldron,
  faReceipt,
  faViruses,
  faKidneys,
  faUsersMedical,
  faTablets,
  faXRay,
  faWheelchairMove,
  faWheelchair,
  faWeightScale,
  faWavePulse,
  faWatchFitness,
  faWalker,
  faVirusSlash,
  faVirusCovidSlash,
  faVirusCovid,
  faVialVirus,
  faVialCircleCheck,
  faVial,
  faUserNurseHairLong,
  faUserNurseHair,
  faUserDoctorMessage,
  faUserDoctorHairLong,
  faUserDoctorHair,
  faTruckMedical,
  faToothbrush,
  faTooth,
  faThermometer,
  faTeethOpen,
  faTeeth,
  faSyringe,
  faSuitcaseMedical,
  faStomach,
  faStaffSnake,
  faSquareVirus,
  faSquarePlus,
  faSquareH,
  faSmoking,
  faSkullCrossbones,
  faSkull,
  faSkeleton,
  faShieldVirus,
  faScalpelLineDashed,
  faScalpel,
  faRadiation,
  faPumpMedical,
  faPrescriptionBottlePill,
  faPrescriptionBottleMedical,
  faPrescriptionBottle,
  faPrescription,
  faPoop,
  faPills,
  faPersonHalfDress,
  faPersonDotsFromLine,
  faPersonCane,
  faPersonBreastfeeding,
  faNoteMedical,
  faNose,
  faMortarPestle,
  faMonitorWaveform,
  faMicroscope,
  faMessageMedical,
  faMaskVentilator,
  faMaskFace,
  faLungsVirus,
  faLungs,
  faLips,
  faLaptopMedical,
  faKitMedical,
  faInhaler,
  faHouseMedicalFlag,
  faHouseMedicalCircleXmark,
  faHouseMedicalCircleExclamation,
  faHouseMedicalCircleCheck,
  faHouseMedical,
  faHospitals,
  faHeartPulse,
  faHeartCircleXmark,
  faHeartCirclePlus,
  faHeartCircleMinus,
  faHeartCircleExclamation,
  faHeartCircleCheck,
  faHeartCircleBolt,
  faHeadSideVirus,
  faHeadSideMedical,
  faHeadSideMask,
  faHeadSideCoughSlash,
  faHeadSideCough,
  faHeadSideBrain,
  faHandHoldingMedical,
  faHandDots,
  faFolderMedical,
  faFlaskVial,
  faFlaskRoundPotion,
  faFireFlameSimple,
  faFilesMedical,
  faFileWaveform,
  faFilePrescription,
  faFileMedical,
  faFaceThermometer,
  faFaceMask,
  faFaceHeadBandage,
  faEyeDropper,
  faEar,
  faDna,
  faDisplayMedical,
  faDisease,
  faCrutches,
  faCrutch,
  faCommentMedical,
  faClockRotateLeft,
  faClipboardPrescription,
  faCircleRadiation,
  faCircleH,
  faCapsules,
  faBooksMedical,
  faBookUser,
  faBookMedical,
  faBong,
  faBoneBreak,
  faBone,
  faBiohazard,
  faBandage,
  faBanSmoking,
  faBacterium,
  faBacteria,
  faSkeletonRibs,
  faCompass,
  faGraduationCap,
  faUserHeadset,
  faPlateUtensils,
  faClothesHanger,
  faUserHelmetSafety,
  faHandsPraying,
  faBaby,
  faTankWater,
  faToilet,
  faBreadSlice,
  faCookie,
  faJug,
  faGlassWater,
  faUtensils,
  faPhone,
  faSoap,
  faScroll,
  faToiletPaper,
  faVacuum,
  faBedFront,
  faBlanket,
  faCircleQuestion,
  faCouch,
  faShower,
  faPlug,
  faLightbulbSlash,
  faOutlet,
  faFaucetDrip,
  faDropletSlash,
  faLoveseat,
  faToiletPortable,
  faTabletRugged,
  faRefrigerator,
  faTv,
  faDoorOpen,
  faFaceTired,
  faFaceNauseated,
  faVolumeMute,
  faConciergeBell,
  faForkKnife,
  faGlassWhiskey,
  faScrewdriverWrench,
  faCalendar,
  faVolumeXmark,
  faBook,
  faTShirt,
  faAirConditioner,
  faBlinds,
  faLamp,
  faLightSwitch,
  faPaw,
  faPawSimple,
  faSink,
  faSuitcaseRolling,
  faCartFlatbedBoxes,
  faCars,
  faHandsHoldingHeart,
  faHeadSide,
  faBrainCircuit,
  faFileLines,
  faWashingMachine,
  faPersonPregnant,
  faPersonWalking,
  faSprayCan,
  faPhoneOffice,
  faBullseyePointer,
  faFlagSwallowtail,
  faDatabase,
  faChartColumn,
  faMemoCircleCheck,
  faHandPointer,
  faBug,
  faLeaf,
} from '@fortawesome/pro-regular-svg-icons';

/**
 * Add icons to be used by the library
 * The icons are obtained by the findIconDefinition function called by getIcon
 */
library.add(
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBarcodeScan,
  faBars,
  faBell,
  faBellSlash,
  faBriefcaseMedical,
  faBuilding,
  faBuildings,
  faCalendarClock,
  faCalendarDays,
  faChartUser,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircle,
  faClipboardCheck,
  faClose,
  faCommentQuestion,
  faDoorClosed,
  faEdit,
  faEllipsisVertical,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFaceAngry,
  faFaceFrownSlight,
  faFaceGrin,
  faFaceGrinHearts,
  faFaceSmile,
  faFileExport,
  faFilter,
  faHeart,
  faHexagonExclamation,
  faHome,
  faHouseChimneyMedical,
  faImagePolaroidUser,
  faKey,
  faLeft,
  faLock,
  faMagnifyingGlass,
  faMemoCircleInfo,
  faMessageHeart,
  faMessageLines,
  faMessagesQuestion,
  faMinus,
  faPen,
  faPencil,
  faPlay,
  faPlus,
  faQuestionCircle,
  faRankingStar,
  faRedo,
  faRefresh,
  faRightFromBracket,
  faStar,
  faStopwatch,
  faTabletButton,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faUniversalAccess,
  faUser,
  faUserDoctor,
  faUserMd,
  faVenusMars,
  faVolume,
  faVolumeSlash,
  faWrench,
  faXmarkLarge,
  faChartLine,
  faUserCheck,
  faReply,
  faCommentLines,
  faLightbulbOn,
  faChartBar,
  faClipboardUser,
  faFileArrowDown,
  faBedPulse,
  faChartPie,
  faUserNurse,
  faChartLineUp,
  faHospitalUser,
  faCake,
  faStretcher,
  faClipboardMedical,
  faFileShield,
  faMemoPad,
  faMessage,
  faMagnifyingGlassChart,
  faSort,
  faPlusCircle,
  faCircleCheck,
  faAlarmClock,
  faUsers,
  faLocationDot,
  faRotateExclamation,
  faHourglassHalf,
  faIdCardClip,
  faFaceMeh,
  faBroom,
  faClock,
  faPumpSoap,
  faHashtag,
  faSackDollar,
  faBan,
  faPotFood,
  faBed,
  faFlask,
  faStethoscope,
  faFaceVomit,
  faNotesMedical,
  faVials,
  faBrain,
  faStarOfLife,
  faHospital,
  faVirus,
  faJoint,
  faCauldron,
  faReceipt,
  faViruses,
  faKidneys,
  faUsersMedical,
  faTablets,
  faXRay,
  faWheelchairMove,
  faWheelchair,
  faWeightScale,
  faWavePulse,
  faWatchFitness,
  faWalker,
  faVirusSlash,
  faVirusCovidSlash,
  faVirusCovid,
  faVialVirus,
  faVialCircleCheck,
  faVial,
  faUserNurseHairLong,
  faUserNurseHair,
  faUserDoctorMessage,
  faUserDoctorHairLong,
  faUserDoctorHair,
  faTruckMedical,
  faToothbrush,
  faTooth,
  faThermometer,
  faTeethOpen,
  faTeeth,
  faSyringe,
  faSuitcaseMedical,
  faStomach,
  faStaffSnake,
  faSquareVirus,
  faSquarePlus,
  faSquareH,
  faSmoking,
  faSkullCrossbones,
  faSkull,
  faSkeleton,
  faShieldVirus,
  faScalpelLineDashed,
  faScalpel,
  faRadiation,
  faPumpMedical,
  faPrescriptionBottlePill,
  faPrescriptionBottleMedical,
  faPrescriptionBottle,
  faPrescription,
  faPoop,
  faPills,
  faPersonHalfDress,
  faPersonDotsFromLine,
  faPersonCane,
  faPersonBreastfeeding,
  faNoteMedical,
  faNose,
  faMortarPestle,
  faMonitorWaveform,
  faMicroscope,
  faMessageMedical,
  faMaskVentilator,
  faMaskFace,
  faLungsVirus,
  faLungs,
  faLips,
  faLaptopMedical,
  faKitMedical,
  faInhaler,
  faHouseMedicalFlag,
  faHouseMedicalCircleXmark,
  faHouseMedicalCircleExclamation,
  faHouseMedicalCircleCheck,
  faHouseMedical,
  faHospitals,
  faHeartPulse,
  faHeartCircleXmark,
  faHeartCirclePlus,
  faHeartCircleMinus,
  faHeartCircleExclamation,
  faHeartCircleCheck,
  faHeartCircleBolt,
  faHeadSideVirus,
  faHeadSideMedical,
  faHeadSideMask,
  faHeadSideCoughSlash,
  faHeadSideCough,
  faHeadSideBrain,
  faHandHoldingMedical,
  faHandDots,
  faFolderMedical,
  faFlaskVial,
  faFlaskRoundPotion,
  faFireFlameSimple,
  faFilesMedical,
  faFileWaveform,
  faFilePrescription,
  faFileMedical,
  faFaceThermometer,
  faFaceMask,
  faFaceHeadBandage,
  faEyeDropper,
  faEar,
  faDna,
  faDisplayMedical,
  faDisease,
  faCrutches,
  faCrutch,
  faCommentMedical,
  faClockRotateLeft,
  faClipboardPrescription,
  faCircleRadiation,
  faCircleH,
  faCapsules,
  faBooksMedical,
  faBookUser,
  faBookMedical,
  faBong,
  faBoneBreak,
  faBone,
  faBiohazard,
  faBandage,
  faBanSmoking,
  faBacterium,
  faBacteria,
  faSkeletonRibs,
  faCompass,
  faGraduationCap,
  faUserHeadset,
  faPlateUtensils,
  faClothesHanger,
  faUserHelmetSafety,
  faHandsPraying,
  faBaby,
  faTankWater,
  faToilet,
  faBreadSlice,
  faCookie,
  faJug,
  faGlassWater,
  faUtensils,
  faPhone,
  faSoap,
  faScroll,
  faToiletPaper,
  faVacuum,
  faBedFront,
  faBlanket,
  faCircleQuestion,
  faCouch,
  faShower,
  faPlug,
  faLightbulbSlash,
  faOutlet,
  faFaucetDrip,
  faDropletSlash,
  faLoveseat,
  faToiletPortable,
  faTabletRugged,
  faRefrigerator,
  faTv,
  faDoorOpen,
  faFaceTired,
  faFaceNauseated,
  faVolumeMute,
  faConciergeBell,
  faForkKnife,
  faGlassWhiskey,
  faScrewdriverWrench,
  faCalendar,
  faVolumeXmark,
  faBook,
  faTShirt,
  faAirConditioner,
  faBlinds,
  faLamp,
  faLightSwitch,
  faPaw,
  faPawSimple,
  faSink,
  faSuitcaseRolling,
  faCartFlatbedBoxes,
  faCars,
  faHandsHoldingHeart,
  faHeadSide,
  faBrainCircuit,
  faFileLines,
  faWashingMachine,
  faPersonPregnant,
  faPersonWalking,
  faSprayCan,
  faPhoneOffice,
  faBullseyePointer,
  faFlagSwallowtail,
  faDatabase,
  faChartColumn,
  faMemoCircleCheck,
  faHandPointer,
  faBug,
  faLeaf,
);

export const iconsMap: Record<string, IconName> = {
  fa1: '1',
  fa2: '2',
  fa3: '3',
  fa4: '4',
  fa5: '5',
  fa6: '6',
  fa7: '7',
  fa8: '8',
  fa9: '9',
  faAngleDown: 'angle-down',
  faAngleUp: 'angle-up',
  faArrowLeft: 'arrow-left',
  faArrowRight: 'arrow-right',
  faBarcodeScan: 'barcode-scan',
  faBars: 'bars',
  faBell: 'bell',
  faBellSlash: 'bell-slash',
  faBriefcaseMedical: 'briefcase-medical',
  faBuilding: 'building',
  faBuildings: 'buildings',
  faCalendarClock: 'calendar-clock',
  faCalendarDays: 'calendar-days',
  faChartUser: 'chart-user',
  faCheck: 'check',
  faChevronDown: 'chevron-down',
  faChevronLeft: 'chevron-left',
  faChevronUp: 'chevron-up',
  faCircle: 'circle',
  faClipboardCheck: 'clipboard-check',
  faClose: 'close',
  faCommentQuestion: 'comment-question',
  faDoorClosed: 'door-closed',
  faEdit: 'edit',
  faEllipsisVertical: 'ellipsis-vertical',
  faEnvelope: 'envelope',
  faExclamationTriangle: 'exclamation-triangle',
  faEye: 'eye',
  faEyeSlash: 'eye-slash',
  faFaceAngry: 'face-angry',
  faFaceFrownSlight: 'face-frown-slight',
  faFaceGrin: 'face-grin',
  faFaceGrinHearts: 'face-grin-hearts',
  faFaceSmile: 'face-smile',
  faFileExport: 'file-export',
  faFilter: 'filter',
  faHeart: 'heart',
  faHexagonExclamation: 'hexagon-exclamation',
  faHome: 'home',
  faHouseChimneyMedical: 'house-chimney-medical',
  faImagePolaroidUser: 'image-polaroid-user',
  faKey: 'key',
  faLeft: 'left',
  faLock: 'lock',
  faMagnifyingGlass: 'magnifying-glass',
  faMemoCircleInfo: 'memo-circle-info',
  faMessageHeart: 'message-heart',
  faMessageLines: 'message-lines',
  faMessagesQuestion: 'messages-question',
  faMinus: 'minus',
  faPen: 'pen',
  faPencil: 'pencil',
  faPlay: 'play',
  faPlus: 'plus',
  faQuestionCircle: 'question-circle',
  faRankingStar: 'ranking-star',
  faRedo: 'redo',
  faRefresh: 'refresh',
  faRightFromBracket: 'right-from-bracket',
  faStar: 'star',
  faStopwatch: 'stopwatch',
  faTabletButton: 'tablet-button',
  faThumbsDown: 'thumbs-down',
  faThumbsUp: 'thumbs-up',
  faTimes: 'times',
  faTrash: 'trash',
  faTriangleExclamation: 'triangle-exclamation',
  faUniversalAccess: 'universal-access',
  faUser: 'user',
  faUserDoctor: 'user-doctor',
  faUserMd: 'user-md',
  faVenusMars: 'venus-mars',
  faVolume: 'volume',
  faVolumeSlash: 'volume-slash',
  faWrench: 'wrench',
  faXmarkLarge: 'xmark-large',
  faChartLine: 'chart-line',
  faUserCheck: 'user-check',
  faReply: 'reply',
  faCommentLines: 'comment-lines',
  faLightbulbOn: 'lightbulb-on',
  faChartBar: 'chart-bar',
  faClipboardUser: 'clipboard-user',
  faFileArrowDown: 'file-arrow-down',
  faBedPulse: 'bed-pulse',
  faChartPie: 'chart-pie',
  faUserNurse: 'user-nurse',
  faChartLineUp: 'chart-line-up',
  faHospitalUser: 'hospital-user',
  faCake: 'cake',
  faStretcher: 'stretcher',
  faClipboardMedical: 'clipboard-medical',
  faFileShield: 'file-shield',
  faMemoPad: 'memo-pad',
  faMessage: 'message',
  faMagnifyingGlassChart: 'magnifying-glass-chart',
  faSort: 'sort',
  faPlusCircle: 'plus-circle',
  faCircleCheck: 'circle-check',
  faAlarmClock: 'alarm-clock',
  faUsers: 'users',
  faLocationDot: 'location-dot',
  faRotateExclamation: 'rotate-exclamation',
  faHourglassHalf: 'hourglass-half',
  faIdCardClip: 'id-card-clip',
  faFaceMeh: 'face-meh',
  faBroom: 'broom',
  faClock: 'clock',
  faPumpSoap: 'pump-soap',
  faHashtag: 'hashtag',
  faSackDollar: 'sack-dollar',
  faBan: 'ban',
  faPotFood: 'pot-food',
  faBed: 'bed',
  faFlask: 'flask',
  faStethoscope: 'stethoscope',
  faFaceVomit: 'face-vomit',
  faNotesMedical: 'notes-medical',
  faVials: 'vials',
  faBrain: 'brain',
  faStarOfLife: 'star-of-life',
  faHospital: 'hospital',
  faVirus: 'virus',
  faJoint: 'joint',
  faCauldron: 'cauldron',
  faReceipt: 'receipt',
  faViruses: 'viruses',
  faKidneys: 'kidneys',
  faUsersMedical: 'users-medical',
  faTablets: 'tablets',
  faXRay: 'x-ray',
  faWheelchairMove: 'wheelchair-move',
  faWheelchair: 'wheelchair',
  faWeightScale: 'weight-scale',
  faWavePulse: 'wave-pulse',
  faWatchFitness: 'watch-fitness',
  faWalker: 'walker',
  faVirusSlash: 'virus-slash',
  faVirusCovidSlash: 'virus-covid-slash',
  faVirusCovid: 'virus-covid',
  faVialVirus: 'vial-virus',
  faVialCircleCheck: 'vial-circle-check',
  faVial: 'vial',
  faUserNurseHairLong: 'user-nurse-hair-long',
  faUserNurseHair: 'user-nurse-hair',
  faUserDoctorMessage: 'user-doctor-message',
  faUserDoctorHairLong: 'user-doctor-hair-long',
  faUserDoctorHair: 'user-doctor-hair',
  faTruckMedical: 'truck-medical',
  faToothbrush: 'toothbrush',
  faTooth: 'tooth',
  faThermometer: 'thermometer',
  faTeethOpen: 'teeth-open',
  faTeeth: 'teeth',
  faSyringe: 'syringe',
  faSuitcaseMedical: 'suitcase-medical',
  faStomach: 'stomach',
  faStaffSnake: 'staff-snake',
  faSquareVirus: 'square-virus',
  faSquarePlus: 'square-plus',
  faSquareH: 'square-h',
  faSmoking: 'smoking',
  faSkullCrossbones: 'skull-crossbones',
  faSkull: 'skull',
  faSkeleton: 'skeleton',
  faShieldVirus: 'shield-virus',
  faScalpelLineDashed: 'scalpel-line-dashed',
  faScalpel: 'scalpel',
  faRadiation: 'radiation',
  faPumpMedical: 'pump-medical',
  faPrescriptionBottlePill: 'prescription-bottle-pill',
  faPrescriptionBottleMedical: 'prescription-bottle-medical',
  faPrescriptionBottle: 'prescription-bottle',
  faPrescription: 'prescription',
  faPoop: 'poop',
  faPills: 'pills',
  faPersonHalfDress: 'person-half-dress',
  faPersonDotsFromLine: 'person-dots-from-line',
  faPersonCane: 'person-cane',
  faPersonBreastfeeding: 'person-breastfeeding',
  faNoteMedical: 'note-medical',
  faNose: 'nose',
  faMortarPestle: 'mortar-pestle',
  faMonitorWaveform: 'monitor-waveform',
  faMicroscope: 'microscope',
  faMessageMedical: 'message-medical',
  faMaskVentilator: 'mask-ventilator',
  faMaskFace: 'mask-face',
  faLungsVirus: 'lungs-virus',
  faLungs: 'lungs',
  faLips: 'lips',
  faLaptopMedical: 'laptop-medical',
  faKitMedical: 'kit-medical',
  faInhaler: 'inhaler',
  faHouseMedicalFlag: 'house-medical-flag',
  faHouseMedicalCircleXmark: 'house-medical-circle-xmark',
  faHouseMedicalCircleExclamation: 'house-medical-circle-exclamation',
  faHouseMedicalCircleCheck: 'house-medical-circle-check',
  faHouseMedical: 'house-medical',
  faHospitals: 'hospitals',
  faHeartPulse: 'heart-pulse',
  faHeartCircleXmark: 'heart-circle-xmark',
  faHeartCirclePlus: 'heart-circle-plus',
  faHeartCircleMinus: 'heart-circle-minus',
  faHeartCircleExclamation: 'heart-circle-exclamation',
  faHeartCircleCheck: 'heart-circle-check',
  faHeartCircleBolt: 'heart-circle-bolt',
  faHeadSideVirus: 'head-side-virus',
  faHeadSideMedical: 'head-side-medical',
  faHeadSideMask: 'head-side-mask',
  faHeadSideCoughSlash: 'head-side-cough-slash',
  faHeadSideCough: 'head-side-cough',
  faHeadSideBrain: 'head-side-brain',
  faHandHoldingMedical: 'hand-holding-medical',
  faHandDots: 'hand-dots',
  faFolderMedical: 'folder-medical',
  faFlaskVial: 'flask-vial',
  faFlaskRoundPotion: 'flask-round-potion',
  faFireFlameSimple: 'fire-flame-simple',
  faFilesMedical: 'files-medical',
  faFileWaveform: 'file-waveform',
  faFilePrescription: 'file-prescription',
  faFileMedical: 'file-medical',
  faFaceThermometer: 'face-thermometer',
  faFaceMask: 'face-mask',
  faFaceHeadBandage: 'face-head-bandage',
  faEyeDropper: 'eye-dropper',
  faEar: 'ear',
  faDna: 'dna',
  faDisplayMedical: 'display-medical',
  faDisease: 'disease',
  faCrutches: 'crutches',
  faCrutch: 'crutch',
  faCommentMedical: 'comment-medical',
  faClockRotateLeft: 'clock-rotate-left',
  faClipboardPrescription: 'clipboard-prescription',
  faCircleRadiation: 'circle-radiation',
  faCircleH: 'circle-h',
  faCapsules: 'capsules',
  faBooksMedical: 'books-medical',
  faBookUser: 'book-user',
  faBookMedical: 'book-medical',
  faBong: 'bong',
  faBoneBreak: 'bone-break',
  faBone: 'bone',
  faBiohazard: 'biohazard',
  faBandage: 'bandage',
  faBanSmoking: 'ban-smoking',
  faBacterium: 'bacterium',
  faBacteria: 'bacteria',
  faSkeletonRibs: 'skeleton-ribs',
  faCompass: 'compass',
  faGraduationCap: 'graduation-cap',
  faUserHeadset: 'user-headset',
  faPlateUtensils: 'plate-utensils',
  faClothesHanger: 'clothes-hanger',
  faUserHelmetSafety: 'user-helmet-safety',
  faHandsPraying: 'hands-praying',
  faBaby: 'baby',
  faTankWater: 'tank-water',
  faToilet: 'toilet',
  faBreadSlice: 'bread-slice',
  faCookie: 'cookie',
  faJug: 'jug',
  faGlassWater: 'glass-water',
  faUtensils: 'utensils',
  faPhone: 'phone',
  faSoap: 'soap',
  faScroll: 'scroll',
  faToiletPaper: 'toilet-paper',
  faVacuum: 'vacuum',
  faBedFront: 'bed-front',
  faBlanket: 'blanket',
  faCircleQuestion: 'circle-question',
  faCouch: 'couch',
  faShower: 'shower',
  faPlug: 'plug',
  faLightbulbSlash: 'lightbulb-slash',
  faOutlet: 'outlet',
  faFaucetDrip: 'faucet-drip',
  faDropletSlash: 'droplet-slash',
  faLoveseat: 'loveseat',
  faToiletPortable: 'toilet-portable',
  faTabletRugged: 'tablet-rugged',
  faRefrigerator: 'refrigerator',
  faTv: 'tv',
  faDoorOpen: 'door-open',
  faFaceTired: 'face-tired',
  faFaceNauseated: 'face-nauseated',
  faVolumeMute: 'volume-mute',
  faConciergeBell: 'concierge-bell',
  faForkKnife: 'fork-knife',
  faGlassWhiskey: 'glass-whiskey',
  faScrewdriverWrench: 'screwdriver-wrench',
  faCalendar: 'calendar',
  faVolumeXmark: 'volume-xmark',
  faBook: 'book',
  faTShirt: 'shirt',
  faAirConditioner: 'air-conditioner',
  faBlinds: 'blinds',
  faLamp: 'lamp',
  faLightSwitch: 'light-switch',
  faPaw: 'paw',
  faPawSimple: 'paw-simple',
  faSink: 'sink',
  faSuitcaseRolling: 'suitcase-rolling',
  faCartFlatbedBoxes: 'cart-flatbed-boxes',
  faCars: 'cars',
  faHandsHoldingHeart: 'hands-holding-heart',
  faHeadSide: 'head-side',
  faBrainCircuit: 'brain-circuit',
  faFileLines: 'file-lines',
  faWashingMachine: 'washing-machine',
  faPersonPregnant: 'person-pregnant',
  faPersonWalking: 'person-walking',
  faSprayCan: 'spray-can',
  faPhoneOffice: 'phone-office',
  faBullseyePointer: 'bullseye-pointer',
  faFlagSwallowtail: 'flag-swallowtail',
  faDatabase: 'database',
  faChartColumn: 'chart-column',
  faMemoCircleCheck: 'memo-circle-check',
  faHandPointer: 'hand-pointer',
  faBug: 'bug',
  faLeaf: 'leaf',
};
