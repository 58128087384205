import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Admission,
  Patient,
  ReleasedAdmissionSurvey,
  SurveyPage,
} from '@quality24/inpatient-typings';
import { Text } from '@quality24/design-system';
import classNames from 'classnames';

import { useSurvey } from '@/inpatient-patient-pwa/utils/surveyHooks';
import SurveyNavigation from '@/inpatient-patient-pwa/molecules/SurveyNavigation';
import StaticPage from './components/StaticPage';
import IntroductionPage from './components/IntroductionPage';
import DefaultQuestionPage from './components/DefaultQuestionPage';
import SmileyQuestionPage from './components/SmileyQuestionPage';
import ConfirmationModal from '../ConfirmationModal';
import YesOrNoQuestionPage from './components/YesOrNoQuestionPage';

import styles from './DailySurvey.module.scss';

type ComponentSurveyPage = Required<
  Pick<SurveyPage, 'id' | 'category' | 'type' | 'title'>
> &
  Pick<SurveyPage, 'data'>;

interface ComposedReleasedSurvey
  extends Required<Pick<ReleasedAdmissionSurvey, 'id' | 'createdAt'>> {
  admission: Pick<Admission, 'id'> & {
    patient: Pick<Patient, 'id' | 'name'>;
  };
  pages: Array<ComponentSurveyPage>;
}

export interface Props {
  className?: string;
  survey: ComposedReleasedSurvey;
}

const DailySurvey: React.FunctionComponent<Props> = ({ className, survey }) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const navigate = useNavigate();

  const handleSubmitOrRefuse = React.useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  const { currentPage, answer, nav } = useSurvey(
    survey,
    handleSubmitOrRefuse,
    handleSubmitOrRefuse,
  );

  const navigation = {
    ...nav,
    submit: () => setShowConfirmation(true),
  };
  const emptySurvey = nav.answersAmount === 0;

  const isQuestionPage =
    (currentPage.category === 'STATIC_PAGE' &&
      currentPage.type !== 'PAGE_INTRODUCTION') ||
    currentPage.category === 'SINGLE_QUESTION';

  return (
    <div className={classNames(styles.wrapper, className)}>
      {/* Introduction */}
      {currentPage.category === 'STATIC_PAGE' &&
        currentPage.type === 'PAGE_INTRODUCTION' && (
          <IntroductionPage
            page={currentPage}
            patient={survey.admission.patient}
            onAnswer={nav.next}
            onRefuse={nav.refuse}
            onSkip={handleSubmitOrRefuse}
          />
        )}

      {/* Static pages */}
      {currentPage.category === 'STATIC_PAGE' &&
        currentPage.type !== 'PAGE_INTRODUCTION' && (
          <StaticPage page={currentPage} />
        )}

      {/* Multiple choice page */}
      {currentPage.category === 'SINGLE_QUESTION' &&
        currentPage.type === 'OPEN_0_10' && (
          <DefaultQuestionPage page={currentPage} answer={answer} />
        )}

      {/* 5 point smiley face page */}
      {currentPage.category === 'SINGLE_QUESTION' &&
        currentPage.type === '_5_POINT_SMILEY_FACE' && (
          <SmileyQuestionPage page={currentPage} answer={answer} />
        )}

      {/* Yes or no page */}
      {currentPage.category === 'SINGLE_QUESTION' &&
        currentPage.type === 'YES_NO' && (
          <YesOrNoQuestionPage page={currentPage} answer={answer} />
        )}

      {/* Survey navigation */}
      {isQuestionPage && <SurveyNavigation navigation={navigation} />}

      {/* Submit confirmation modal */}
      <ConfirmationModal
        show={showConfirmation}
        title={
          nav.answersAmount > 0
            ? 'Você tem certeza que deseja finalizar e enviar esta avaliação?'
            : 'Você não respondeu nenhuma pergunta!'
        }
        onHide={() => setShowConfirmation(false)}
        onConfirm={() => (emptySurvey ? nav.refuse() : nav.submit())}
        confirmLabel={emptySurvey ? 'Cancelar avaliação' : 'Enviar'}
        cancelLabel={emptySurvey ? 'Rever perguntas' : 'Cancelar'}
        confirmBtnClassName={emptySurvey ? 'btn btn-danger' : ''}
      >
        {nav.answersAmount > 0 ? (
          <Text>
            {`Se desejar rever ou alterar alguma resposta clique em
            "Cancelar". Caso contrário, clique em "Enviar".`}
          </Text>
        ) : (
          <Text>
            Gostaria de cancelar esta avaliação ou rever as perguntas?
          </Text>
        )}
      </ConfirmationModal>
    </div>
  );
};

export default DailySurvey;
