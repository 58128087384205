import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Icon, IconProps, Text } from '@quality24/design-system';
import styles from './BottomNavbarItem.module.scss';

export interface Props {
  className?: string;
  icon: IconProps['icon'];
  text: string;
  to: string;
  exact?: boolean;
  badgeText?: string;
}

const BottomNavbarItem: React.FunctionComponent<Props> = ({
  className,
  icon,
  text,
  to,
  exact = false,
  badgeText,
}) => (
  <li className={classNames(className, styles.wrapper)}>
    <NavLink to={to} end={exact}>
      <Icon icon={icon} size="md" />
      <Text size="sm2" weight="semiBold">
        {text}
      </Text>
      {badgeText && <span className={styles.badge}>{badgeText}</span>}
    </NavLink>
  </li>
);
export default BottomNavbarItem;
