import * as React from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Input, { InputProps } from '../../atoms/Input';
import Icon from '../../atoms/Icon';

import styles from './InputWithIcon.module.scss';

export interface Props extends Omit<InputProps, 'prepend' | 'append'> {
  prependIcon?: FontAwesomeIconProps['icon'];
  appendIcon?: FontAwesomeIconProps['icon'];
  onPrependClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onPrependMouseDown?: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => void;
  onAppendClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onAppendMouseDown?: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => void;
  helperText?: string;
  disablePrepend?: boolean;
  disableAppend?: boolean;
}

const InputWithIcon = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      style,
      prependIcon,
      appendIcon,
      onPrependClick,
      onPrependMouseDown,
      onAppendClick,
      onAppendMouseDown,
      type,
      disabled,
      disablePrepend,
      disableAppend,
      ...inputProps
    },
    ref,
  ) => (
    <div className={classNames(styles.wrapper, className)} style={style}>
      <Input
        ref={ref}
        type={type}
        className={classNames(styles['padded-input'], {
          prepended: prependIcon,
          appended: appendIcon,
        })}
        disabled={disabled}
        prepend={
          prependIcon && (
            <Icon
              className={classNames(styles.icon, 'prepend', {
                'has-action': onPrependClick && !disablePrepend && !disabled,
              })}
              aria-hidden
              role={onPrependClick && 'presentation'}
              icon={prependIcon}
              size="sm"
              color="grayDark"
              onClick={onPrependClick}
              onMouseDown={onPrependMouseDown}
            />
          )
        }
        append={
          appendIcon && (
            <Icon
              className={classNames(styles.icon, 'append', {
                'has-action': onAppendClick && !disableAppend && !disabled,
              })}
              aria-hidden
              role={onAppendClick && 'presentation'}
              icon={appendIcon}
              size="sm"
              color="grayDark"
              onClick={onAppendClick}
              onMouseDown={onAppendMouseDown}
            />
          )
        }
        {...inputProps}
      />
    </div>
  ),
);

export default InputWithIcon;
