import React from 'react';
import { SurveyPage } from '@quality24/inpatient-typings';
import { Heading, Text } from '@quality24/design-system';

export interface Props {
  page: Pick<SurveyPage, 'id' | 'title' | 'data'>;
}

const StaticPage: React.FunctionComponent<Props> = ({ page }) => {
  const description = page.data?.description;
  return (
    <>
      <Heading as="h1" weight="semiBold" size="lg1">
        {page.title}
      </Heading>
      <Text className="my-4" style={{ whiteSpace: 'pre-wrap' }}>
        {description}
      </Text>
    </>
  );
};

export default StaticPage;
