import * as React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Patient } from '@quality24/inpatient-typings';
import classNames from 'classnames';

import BottomNavbar from '@/inpatient-patient-pwa/organisms/BottomNavbar';
import Topbar from '@/inpatient-patient-pwa/organisms/Topbar';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import { useNavbar } from '@/inpatient-patient-pwa/contexts/navbar/useNavbar';

import styles from './NavbarTemplate.module.scss';

export const SELECT_QUERY = gql`
  query FetchNavbarData($admissionId: UUID!) {
    admission: admissionsViewById(id: $admissionId) {
      id
      patient {
        name
      }
    }
  }
`;

export interface QueryResult {
  admission: {
    patient: Pick<Patient, 'name'>;
  };
}

export interface Props {
  className?: string;
  children: React.ReactNode;
  enableTopbar?: boolean;
  enableBottombar?: boolean;
}

const NavbarTemplate: React.FunctionComponent<Props> = ({
  className,
  children,
  enableBottombar = true,
  enableTopbar = true,
}) => {
  const {
    user: { admissionId },
  } = useAuth();

  const { renderGreeting } = useNavbar();

  const { data } = useQuery<QueryResult>(SELECT_QUERY, {
    variables: {
      admissionId,
    },
    skip: !renderGreeting,
    fetchPolicy: 'network-only',
  });

  return (
    <div className={className}>
      {enableTopbar && (
        <Topbar
          patientName={data?.admission.patient.name || undefined}
          renderGreeting={renderGreeting}
        />
      )}

      <main
        className={classNames(styles.main, {
          'has-greeting': renderGreeting,
        })}
      >
        {children}
      </main>

      {enableBottombar && <BottomNavbar />}
    </div>
  );
};

export default NavbarTemplate;
