import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import * as React from 'react';
import classNames from 'classnames';

import { SpacingProps } from '../../../@types/theme';
import Button, { ButtonProps } from '../../atoms/Button';
import Surface from '../../atoms/Surface';

import styles from './AccordionItem.module.scss';

export interface Props
  extends Omit<ButtonProps, 'type' | 'children' | 'onSelect' | 'onClick'> {
  label: string;
  children: React.ReactNode;
  contentPadding?: SpacingProps;
  expand?: boolean;
  onToggleExpand?: (newState: boolean) => void;
}

/**
 * @todo Where is using this component? Kind of strange to define an AccordionItem and not the Accordion itself in the DS
 * @param param0
 * @returns
 */
export const AccordionItem: React.FunctionComponent<Props> = ({
  className,
  label,
  size = 'md',
  children,
  contentPadding,
  expand,
  onToggleExpand,
  ...props
}) => {
  const [expandState, setExpandState] = React.useState(expand);

  return (
    <div className={classNames('d-flex flex-column', className)}>
      <div>
        <Button
          variant="link"
          size={size}
          icon={expandState ? faChevronUp : faChevronDown}
          onClick={() => {
            setExpandState(!expandState);
            onToggleExpand?.(!expandState);
          }}
          {...props}
        >
          {label}
        </Button>
      </div>

      <Surface
        className={classNames(styles.content, { hide: !expandState })}
        padding={contentPadding}
      >
        {children}
      </Surface>
    </div>
  );
};

export default AccordionItem;
