import classNames from 'classnames';
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import Text from '../Text';
import CustomTooltipInfo from '../CustomTooltipInfo';

import styles from './Input.module.scss';

export interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  type?: React.HTMLInputTypeAttribute;
  name: string;
  label?: string;
  value?: string;
  size?: 'md' | 'sm';
  inputSize?: number;
  placeholder?: string;
  tooltip?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  helperText?: string;
  errorText?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      className,
      style,
      type = 'text',
      helperText,
      errorText,
      name,
      label,
      value,
      size = 'md',
      inputSize,
      placeholder,
      tooltip,
      prepend,
      append,
      disabled,
      onChange,
      ...props
    },
    ref,
  ) => {
    const inputId = id ?? uuid();

    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> =
      React.useCallback(
        (evt) => {
          const modifiedEvent = { ...evt };
          modifiedEvent.target.value = modifiedEvent.target.value?.trim();
          props.onBlur?.(modifiedEvent);
        },
        [props],
      );

    const cls = classNames(styles['label-wrapper'], className, {
      disabled,
      error: !!errorText,
      'size-sm': size === 'sm',
    });

    return (
      <div className={cls} style={style}>
        {label && (
          <div className="d-flex gap-1 text-items-center mb-2">
            <Text as="label" htmlFor={inputId} weight="bold">
              {label}
            </Text>
            {tooltip && <CustomTooltipInfo info={tooltip} />}
          </div>
        )}

        <div className={styles.wrapper}>
          {prepend}
          <input
            id={inputId}
            className={classNames({ error: !!errorText })}
            ref={ref}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            onBlur={handleOnBlur}
            size={inputSize}
            {...props}
          />

          {append}
        </div>

        {helperText && !errorText && (
          <Text as="span" className="mt-2" size="sm1">
            {helperText}
          </Text>
        )}
        {errorText && (
          <Text as="span" className="mt-2" color="red400" size="sm1">
            {errorText}
          </Text>
        )}
      </div>
    );
  },
);

export default Input;
