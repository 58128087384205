import React from 'react';

import { context, IThemeProvider } from './ThemeProvider';
import { Theme } from '../../@types/theme';

// eslint-disable-next-line import/prefer-default-export
export const useTheme = <TTheme extends Theme>(): IThemeProvider<TTheme> => {
  const themeContext = React.useContext<IThemeProvider<TTheme>>(
    context as unknown as React.Context<IThemeProvider<TTheme>>,
  );
  return themeContext;
};
