/* eslint-disable no-console */
import { createClient } from 'graphql-ws';

import { headers as projectHeaders } from './http';
import { getIdToken } from './storage';
import runtimeConfig from '../config';

//
// WebSocket States
//

export enum WebSocketState {
  CONNECTING = WebSocket.CONNECTING,
  CONNECTED = WebSocket.OPEN,
  DISCONNECTING = WebSocket.CLOSING,
  DISCONNECTED = WebSocket.CLOSED,
}

export interface WebSocketStateObject {
  state: WebSocketState;
  lastConnection: Date | null;
}

/**
 * The default WebSocket state object
 */
export const defaultWebSocketState: WebSocketStateObject = {
  state: WebSocketState.DISCONNECTED,
  lastConnection: null,
};

let setWebSocketSetter:
  | ((state: WebSocketStateObject) => void)
  | undefined = () => {};

/**
 * Updates the WebSocket last connection timestamp
 */
const setWebSocketState = (state: WebSocketState): void => {
  defaultWebSocketState.state = state;
  defaultWebSocketState.lastConnection = new Date();

  if (setWebSocketSetter) {
    setWebSocketSetter({
      state,
      lastConnection: new Date(),
    });
  }
};

export const setWebSocketSetterRef = (
  setter: ((state: WebSocketStateObject) => void) | undefined,
): void => {
  setWebSocketSetter = setter;
};

/**
 * The WebSocket transport client
 */
const subscriptionClient = createClient({
  url: runtimeConfig.WS_URL || (process.env.REACT_APP_WS_URL as string),
  shouldRetry: () => true,
  retryAttempts: Infinity,
  keepAlive: 10000,
  retryWait: async (retryNumber: number) => {
    // Backoff algorithm:
    // 0. 1s
    // 1. 2s
    // 2. 4s
    // 3. 8s
    // 4. 16s
    // 5. 32s
    // >= 6. 60s
    // plus a fixed random number between 0 and 1s
    await new Promise((resolve) => {
      setTimeout(
        resolve,
        (retryNumber <= 5 ? 2 ** retryNumber : 60) * 1000 +
          Math.floor(Math.random() * 1000),
      );
    });
  },
  connectionParams: async () => ({
    headers: {
      authorization: `Bearer ${getIdToken()}`,
      ...projectHeaders,
    },
  }),

  // Event Listeners
  on: {
    connected: () => {
      console.info('WebSocket connected.');
      setWebSocketState(WebSocketState.CONNECTED);
    },
    closed: () => {
      console.info('WebSocket disconnected.');
      setWebSocketState(WebSocketState.DISCONNECTED);
    },
    error: () => {
      console.info('WebSocket error.');
    },
    connecting: () => {
      console.info('WebSocket connecting.');
      setWebSocketState(WebSocketState.CONNECTING);
    },
  },
});

/**
 * Forces the WebSocket connection to close.
 */
export const disconnectWebsocket = (): void => {
  subscriptionClient.terminate();
};

export default subscriptionClient;
