import { InMemoryCache } from '@apollo/client';

import { AuthenticationResult } from '@quality24/inpatient-typings';
import {
  logout,
  parseAuthFlowResult,
  refreshAuthToken,
  setAuthPayload,
} from './auth';
import subscriptionClient, {
  defaultWebSocketState as wsState,
  WebSocketState,
} from './websocket';

import { AuthPayload } from '../@types/auth';
import { getAdmissionId } from './storage';

// //////////////////////////////////////////////
// Cache definitions
// //////////////////////////////////////////////

// Create cache
export const cache = new InMemoryCache();

/**
 * Resets the apollo-client cache
 */
export const resetCache = async (): Promise<void> => {
  await cache.reset();
};

// //////////////////////////////////////////////
// Custom fetch definition
// //////////////////////////////////////////////

/**
 * Stores the promise of a refresh operation.
 * Note: store it globally in order not to run multiple
 * refreshes at the same time
 */

export const retryRefreshToken = (
  uri: string,
  refreshToken: string,
): Promise<AuthPayload | void | null> =>
  refreshAuthToken(uri, refreshToken)
    .then(async (res) => {
      if (!res.ok) {
        // eslint-disable-next-line no-console
        console.error(
          `Failed to refresh token, logging user out. 
          Request status: ${res.status} - ${res.statusText}`,
        );

        // Clear auth data to force a logout
        await resetCache();
        logout();
        return false;
      }

      return res.json();
    })
    .then(async (json) => {
      // Get the auth data
      const authResult = json.data?.authFlow
        ?.authenticationResult as AuthenticationResult;
      if (!authResult) {
        // eslint-disable-next-line no-console
        console.error(
          'Could not refresh tokens using refreshToken. Logging user out',
        );
        // Clear auth data to force a logout
        await resetCache();
        logout();
        return null;
      }

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.debug('graphql: ran retryRefreshToken', authResult);
      }

      // Save new auth payload
      const authPayload = parseAuthFlowResult({
        ...authResult,
        /**
         * Temporarily bypass admissionId
         * This might be removed after refreshDeviceTokenWithType is updated in the API to return the admissionId
         */
        userData: { ...authResult.userData, admissionId: getAdmissionId() },
      });
      setAuthPayload(authPayload);

      // If previous WebSocket connection was closed, force a reconnection
      // after refreshing the token
      if (wsState.state === WebSocketState.DISCONNECTED) {
        subscriptionClient.terminate();
      }
      return authPayload;
    })
    .catch(async (err) => {
      // eslint-disable-next-line no-console
      console.error('Failed to refresh token, will retry in a moment', err);
      setTimeout(() => retryRefreshToken(uri, refreshToken), 5000);
    });
