/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  tabKey: string;
  title: string;
  icon?: FontAwesomeIconProps['icon'];
}

export const TabPane: React.FunctionComponent<Props> = ({ children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>
);

export default TabPane;
