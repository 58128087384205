import * as React from 'react';
import classNames from 'classnames';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import Icon from '../Icon';

import styles from './Button.module.scss';

export interface Props
  extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'color'> {
  type?: 'button' | 'submit';
  size?: 'sm' | 'md' | 'lg' | 'jumbo';
  variant?: 'default' | 'outline' | 'link';
  icon?: FontAwesomeIconProps['icon'];
  appendIcon?: FontAwesomeIconProps['icon'];
  isUpperCase?: boolean;
  ellipsize?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger';
  fluid?: boolean;
  debounceInterval?: number; // TODO
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      style,
      type = 'button',
      variant = 'default',
      size = 'md',
      icon,
      children,
      isUpperCase = false,
      ellipsize = false,
      disabled,
      appendIcon,
      onClick,
      color = 'primary',
      fluid = false,
      debounceInterval,
      ...buttonProps
    },
    ref,
  ) => {
    const cls = classNames(
      styles.button,
      {
        [styles[`button-${variant}`]]: variant,
        [`btn-${size}`]: size,
        [`btn-${color}`]: color,
        fluid,
      },
      className,
    );

    return (
      <button
        className={cls}
        style={{
          textTransform: isUpperCase ? 'uppercase' : 'inherit',
          ...style,
        }}
        // Forcing type due to eslint rule react/button-has-type
        // Ref: https://github.com/jsx-eslint/eslint-plugin-react/issues/1555
        type={type === 'button' ? 'button' : 'submit'}
        title={typeof children === 'string' ? children : ''}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
        {...buttonProps}
      >
        {icon && <Icon className={styles.icon} icon={icon} />}

        {children && (
          <span className={classNames(styles['button-label'], { ellipsize })}>
            {children}
          </span>
        )}

        {appendIcon && <Icon className={styles.icon} icon={appendIcon} />}
      </button>
    );
  },
);

export default Button;
