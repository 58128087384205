import React from 'react';

import { getLgpdStatus } from '@/inpatient-patient-pwa/services/storage';
import { LoginState } from '@/inpatient-patient-pwa/types/auth';
import LgpdConsentPage from '@/inpatient-patient-pwa/pages/LgpdConsentPage';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';

/**
 * <Router> component
 */
const Router: React.FunctionComponent = () => {
  const { status } = useAuth();
  const isAuth = status === LoginState.AUTHENTICATED;

  if (!isAuth) return <PublicRouter />;

  const shouldCheckLgpd = !getLgpdStatus();

  return shouldCheckLgpd ? <LgpdConsentPage /> : <PrivateRouter />;
};

export default Router;
