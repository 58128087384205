import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button, Input, Heading, Text } from '@quality24/design-system';
import RadioField from '../../molecules/RadioField';
import styles from './LoginProcessForm.module.scss';

export interface Props {
  onSubmit?: (values: { userType: string }) => void;
  className?: string;
  validations?: string[];
}

interface FormValues {
  userType: string;
  admissionNumber: string;
  name: string;
  birthDate: string;
}

/*
 *
 * Validation Schema
 */

const validationSchema = (validations: string[]) =>
  Yup.object({
    name: Yup.string().when('userType', {
      is: 'PATIENT_COMPANION',
      then: (schema) => schema.required('Campo obrigatório'),
      // otherwise: (schema) => schema.string(),
    }),
    admissionNumber: Yup.string().required('Campo obrigatório'),
    birthDate: validations.includes('birthDate')
      ? Yup.string().required('Campo obrigatório')
      : Yup.string().nullable(),
    userType: Yup.string().oneOf(['PATIENT_COMPANION', 'PATIENT']),
  });

const LoginProcessForm: React.FunctionComponent<Props> = ({
  onSubmit = () => {},
  className = '',
  validations = [],
}) => {
  const options = [
    { label: 'Sou paciente', value: 'PATIENT' },
    { label: 'Sou acompanhante', value: 'PATIENT_COMPANION' },
  ];

  const defaultValues: FormValues = {
    userType: '',
    admissionNumber: '',
    name: '',
    birthDate: '',
  };

  const formik = useFormik<FormValues>({
    initialValues: defaultValues,
    validationSchema: validationSchema(validations),
    onSubmit,
  });
  const cls = classNames(className, 'h-100');

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit} className={cls}>
      <div>
        {!formik.values.userType && (
          <>
            <Heading as="h2" size="xl" weight="bold" className="mb-2">
              Seja bem-vindo(a)!
            </Heading>
            <Text>
              Para que comece a utilizar o app, precisamos que confirme se você
              é o <strong>paciente</strong> ou <strong>acompanhante</strong>.
            </Text>
            <Text className="mt-4">
              Utilizamos esta informação para garantir que você tenha a melhor
              experiência!
            </Text>
            <div className={styles.navigation}>
              <RadioField
                className={classNames(styles.button, 'w-100 text-uppercase')}
                type="button"
                direction="column"
                name="userType"
                controlId="userType"
                value={formik.values.userType}
                onChange={(_, value) => {
                  formik.setValues({
                    userType: value,
                    admissionNumber: '',
                    name: '',
                    birthDate: '',
                  });
                  formik.setTouched({ admissionNumber: false, name: false });
                  // setUserType(value);
                }}
                options={options}
                optionsClassName="text-center"
                size="lg"
                fluid
              />
            </div>
          </>
        )}

        {formik.values.userType === 'PATIENT_COMPANION' && (
          <>
            <Heading as="h2" size="xl" weight="bold" className="mb-2">
              Quase lá...
            </Heading>
            <Text>
              Para acessar o sistema, por favor, insira abaixo o seu nome e o{' '}
              <strong>número de atendimento</strong> localizado na pulseira de
              identificação da pessoa que está sendo acompanhada, ou o{' '}
              <strong>código de acesso</strong> localizado abaixo do QR Code,
              conforme instrução da equipe do hospital.
            </Text>
            <div className="d-flex flex-column gap-3 mt-3">
              <Input
                id="name"
                type="text"
                placeholder="Seu nome"
                name="name"
                value={formik.values.name}
                helperText={formik.errors.name}
                onChange={formik.handleChange}
              />

              <Input
                id="admissionNumber"
                type="text"
                placeholder="Nº do atendimento / código"
                name="admissionNumber"
                value={formik.values.admissionNumber}
                helperText={
                  formik.touched.admissionNumber
                    ? formik.errors.admissionNumber
                    : ''
                }
                onChange={formik.handleChange}
              />

              {validations.includes('birthDate') && (
                <Input
                  id="birthDate"
                  type="date"
                  label="Qual é a data de nascimento do paciente?"
                  name="birthDate"
                  value={formik.values.birthDate}
                  helperText={
                    formik.touched.birthDate ? formik.errors.birthDate : ''
                  }
                  onChange={formik.handleChange}
                />
              )}
            </div>
          </>
        )}

        {formik.values.userType === 'PATIENT' && (
          <>
            <Heading as="h2" size="xl" weight="bold" className="mb-2">
              Quase lá...
            </Heading>
            <Text>
              Para acessar o sistema, por favor, insira abaixo o seu{' '}
              <strong>número de atendimento</strong>, localizado na pulseira de
              identificação, ou o <strong>código de acesso</strong> localizado
              abaixo do QR Code, conforme instrução da equipe do hospital.
            </Text>

            <div className="d-flex flex-column gap-3 mt-3">
              <Input
                id="admissionNumber"
                type="text"
                placeholder="Nº do atendimento / código"
                name="admissionNumber"
                value={formik.values.admissionNumber}
                helperText={
                  formik.touched.admissionNumber
                    ? formik.errors.admissionNumber
                    : ''
                }
                onChange={formik.handleChange}
              />

              {validations.includes('birthDate') && (
                <Input
                  id="birthDate"
                  type="date"
                  label="Qual é a sua data de nascimento?"
                  name="birthDate"
                  value={formik.values.birthDate}
                  helperText={
                    formik.touched.birthDate ? formik.errors.birthDate : ''
                  }
                  onChange={formik.handleChange}
                />
              )}
            </div>
          </>
        )}
      </div>

      {formik.values.userType && (
        <div className={styles.navigation}>
          <Button type="submit" size="lg" fluid>
            Concluir
          </Button>
          <Button
            variant="outline"
            size="lg"
            onClick={() => formik.setFieldValue('userType', '')}
            fluid
          >
            Voltar
          </Button>
        </div>
      )}
    </form>
  );
};

export default LoginProcessForm;
