import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import { useTheme } from '../../../contexts/theme';
import Heading from '../../atoms/Heading';
import SelectionContainer, {
  SelectionOption,
} from '../../../containers/SelectionContainer';

import styles from './ScaleSelection.module.scss';
import { ColorStyleProps } from '../../../@types/theme';

export interface Props
  extends Omit<React.HTMLAttributes<HTMLElement>, 'onChange'> {
  value: string | number | undefined;
  onChange: (value: string | number | undefined) => void;
  options?: number[];
  colors?: ColorStyleProps[];
  compact?: boolean;
}

const COLOR_MAP: ColorStyleProps[] = [
  'nps0',
  'nps1',
  'nps2',
  'nps3',
  'nps4',
  'nps5',
  'nps6',
  'nps7',
  'nps8',
  'nps9',
  'nps10',
];

const ScaleSelection: React.FunctionComponent<Props> = ({
  className,
  value,
  onChange,
  options,
  colors,
  compact,
  ...htmlElementProps
}) => {
  const { theme } = useTheme();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.md})`,
  });

  const optionsMap = React.useMemo(
    () =>
      options?.length && options?.length > 0
        ? options
        : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [options],
  );

  const colorsMap = React.useMemo(
    () => (colors?.length && colors?.length > 0 ? colors : COLOR_MAP),
    [colors],
  );

  return (
    <div
      className={classNames(styles.wrapper, className)}
      {...htmlElementProps}
    >
      <SelectionContainer
        value={value}
        onChange={onChange}
        mode={isDesktop || compact ? 'horizontal' : 'vertical'}
        gap={isDesktop ? 0.75 : 0.25}
        allowMultiple={false}
        className={classNames(styles.container, {
          compact,
        })}
      >
        {optionsMap.map((number, i) => (
          <SelectionOption
            className={classNames(
              styles.option,
              `option-${number}`,
              `bg-${colorsMap[i]}`,
              {
                'option-mobile': !isDesktop && !compact,
              },
            )}
            key={number}
            value={number}
          >
            <Heading as="h5" size="xl" weight="bold" color="white">
              {number}
            </Heading>
          </SelectionOption>
        ))}
      </SelectionContainer>
    </div>
  );
};

export default ScaleSelection;
