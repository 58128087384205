import { SurveyPage } from '@quality24/inpatient-typings';
import React from 'react';

import { Heading, Text, TextArea } from '@quality24/design-system';
import ImprovementsSelector from '@/inpatient-patient-pwa/molecules/ImprovementsSelector';
import SmileyInputField from '@/inpatient-patient-pwa/molecules/SmileyInputField';

import { SurveyHook } from '@/inpatient-patient-pwa/utils/surveyHooks';

export interface Props {
  page: Pick<SurveyPage, 'id' | 'category' | 'type' | 'title' | 'data'>;
  answer: SurveyHook['answer'];
}

const DefaultQuestionPage: React.FunctionComponent<Props> = ({
  page,
  answer,
}) => {
  const description = page.data?.description;
  const improvements = page.data?.improvements;

  const showImprovementsSelector =
    answer.values.value.smileyScore !== undefined &&
    answer.values.value.smileyScore < 5;

  return (
    <>
      <Heading as="h2" weight="semiBold" size="md">
        {page.title}
      </Heading>
      <Text className="mb-4" style={{ whiteSpace: 'pre-wrap' }}>
        {description}
      </Text>

      <SmileyInputField
        className="mb-5"
        value={answer.values.value.smileyScore}
        onChange={(v: number) => {
          answer.setFieldValue('value.smileyScore', v);
          if (v === 5) {
            answer.setFieldValue('value.improvements', []);
          }
        }}
      />
      {improvements && improvements.length > 0 && showImprovementsSelector && (
        <ImprovementsSelector
          className="my-4"
          titles={improvements}
          value={answer.values.value.improvements || []}
          onChange={(v) => answer.setFieldValue('value.improvements', v)}
        />
      )}
      <TextArea
        id="formFeedback"
        className="my-4"
        label="Deixe seu comentário abaixo:"
        name="text"
        value={answer.values.value.text}
        errorText={answer.errors?.value?.text}
        onChange={(e) => answer.setFieldValue('value.text', e.target.value)}
      />
    </>
  );
};

export default DefaultQuestionPage;
