import React from 'react';
import classNames from 'classnames';
import { Button } from '@quality24/design-system';
import { SurveyHook } from '@/inpatient-patient-pwa/utils/surveyHooks';
import styles from './SurveyNavigation.module.scss';

export interface Props {
  className?: string;
  navigation: Pick<
    SurveyHook['nav'],
    'hasNext' | 'hasPrevious' | 'next' | 'previous' | 'submit'
  >;
}

const SurveyNavigation: React.FunctionComponent<Props> = ({
  className,
  navigation,
}) => {
  const handleNotApplicable = React.useCallback(() => {
    if (navigation.hasNext) {
      navigation.next();
      return window.scrollTo(0, 0);
    }
    return navigation.submit();
  }, [navigation]);

  const handleNext = React.useCallback(() => {
    navigation.next();
    return window.scrollTo(0, 0);
  }, [navigation]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Button variant="link" onClick={handleNotApplicable} size="lg">
        Não aplicável
      </Button>
      {navigation.hasNext && (
        <Button onClick={handleNext} size="lg">
          Próximo
        </Button>
      )}

      {!navigation.hasNext && (
        <Button onClick={() => navigation.submit()} size="lg">
          Enviar
        </Button>
      )}
    </div>
  );
};

export default SurveyNavigation;
