import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { hasPermission } from '@/inpatient-patient-pwa/services/rbac-rules';
import { LoginState } from '@/inpatient-patient-pwa/types/auth';
import { useAuth } from '@/inpatient-patient-pwa/contexts/auth/useAuth';

export interface Props {
  action: string;
  children: JSX.Element;
}

/**
 * <PrivateRoute> component
 */
const PrivateRoute: React.FunctionComponent<Props> = ({ action, children }) => {
  const location = useLocation();
  const { user, status } = useAuth();

  // Test for authenticated user
  if (status !== LoginState.AUTHENTICATED) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Test for the permission
  if (!hasPermission(user, action)) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default PrivateRoute;
