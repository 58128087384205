import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  AlertEvaluation,
  AlertEvent,
  Connection,
  Employee,
  ServiceGroupItem,
  Service,
} from '@quality24/inpatient-typings';

import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import { Spinner } from '@quality24/design-system';
import ApiError from '@/inpatient-patient-pwa/molecules/ApiError';
import CallEvaluationForm from '@/inpatient-patient-pwa/organisms/CallEvaluationForm';

/**
 * Query
 */

const FETCH_ALERT_QUERY = gql`
  query fetchAlertData($evalId: UUID!) {
    alertEvaluationViewById(id: $evalId) {
      id
      type
      status
      value
      alert {
        id
        solutionEvent: alertEventsViews(
          filter: {
            type: { equalTo: SOLUTION }
            alert: { cancelledAt: { isNull: true } }
          }
        ) {
          nodes {
            id
            employee {
              id
              name
            }
          }
        }
        group {
          id
          service {
            id
            reason
          }
          parent {
            id
            service {
              id
              reason
            }
          }
        }
      }
    }
  }
`;

const UPDATE_ALERT_EVAL = gql`
  mutation updateAlertEvaluation($input: UpdateAlertEvaluationViewByIdInput!) {
    updateAlertEvaluationViewById(input: $input) {
      clientMutationId
    }
  }
`;

export interface IQueryResult {
  alertEvaluationViewById: Pick<AlertEvaluation, 'id' | 'status'> & {
    type: '_10_POINT' | '_5_POINT_SMILEY' | string;
    alert: Pick<Alert, 'id'> & {
      group: Pick<ServiceGroupItem, 'id'> & {
        service: Required<Pick<Service, 'id' | 'reason'>>;
        parent: Pick<ServiceGroupItem, 'id'> & {
          service: Required<Pick<Service, 'id' | 'reason'>>;
        };
      };
      solutionEvent: Connection<
        Pick<AlertEvent, 'id'> & {
          employee: Required<Pick<Employee, 'id' | 'name'>> | null;
        }
      >;
    };
  };
}

const PatientCallEvaluationPage = (): JSX.Element => {
  const { evalId } = useParams<{ evalId: string }>();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery<IQueryResult>(
    FETCH_ALERT_QUERY,
    { variables: { evalId } },
  );
  const [updateEvalMutation] = useMutation(UPDATE_ALERT_EVAL);

  // Get the reason
  let reason;
  if (data) {
    reason = data.alertEvaluationViewById.alert.group.parent
      ? data.alertEvaluationViewById.alert.group.parent.service.reason['pt-BR']
      : data.alertEvaluationViewById.alert.group.service.reason['pt-BR'];
  }

  // Get the resolver
  const resolver =
    data && data.alertEvaluationViewById.alert.solutionEvent.nodes.length > 0
      ? data.alertEvaluationViewById.alert.solutionEvent.nodes[0].employee?.name
      : undefined;

  // Get the evaluation type
  const type = data ? data.alertEvaluationViewById.type : undefined;

  const message = resolver
    ? `Como você avalia sua experiência na resolução do chamado para '${reason}', resolvido por ${resolver}?`
    : `Como você avalia sua experiência na resolução do chamado para '${reason}'?`;

  const handleMutation = React.useCallback(
    async (v) => {
      await updateEvalMutation({
        variables: {
          input: {
            id: evalId,
            patch: {
              value: {
                score: type === '_10_POINT' ? v.score : undefined,
                smileyScore: type === '_5_POINT_SMILEY' ? v.score : undefined,
                text: v.text,
              },
              status: 'ANSWERED',
            },
          },
        },
      });

      navigate(-1);
    },
    [updateEvalMutation, type, navigate, evalId],
  );

  const handleRefuse = React.useCallback(async () => {
    await updateEvalMutation({
      variables: {
        input: {
          id: evalId,
          patch: {
            value: {},
            status: 'REFUSED',
          },
        },
      },
    });
    navigate(-1);
  }, [updateEvalMutation, navigate, evalId]);

  return (
    <>
      <Helmet>
        <title>Avaliação do Chamado - Quality24</title>
      </Helmet>

      {/* Loading state */}
      {loading && <Spinner />}
      {/* Error state */}
      {error && <ApiError error={error} refetch={refetch} />}

      {!loading && !error && (
        <CallEvaluationForm
          onSubmit={handleMutation}
          onRefuse={handleRefuse}
          message={message}
          evalType={type}
        />
      )}
    </>
  );
};

export default PatientCallEvaluationPage;
