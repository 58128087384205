import { gql } from '@apollo/client';

export const FETCH_SERVICE_DATA = gql`
  query FetchServiceData(
    $serviceCustomFieldsFilter: ServiceCustomFieldFilter!
  ) {
    serviceCustomFields(
      orderBy: [ORDER_ASC]
      filter: $serviceCustomFieldsFilter
    ) {
      nodes {
        id
        serviceId
        order
        label
        inputType
        placeholder
        required
        data
        when
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_REGISTRY = gql`
  query SearchEmployeeByRegistry($registry: String) {
    employees(
      first: 1
      filter: { registry: { contains: { hospitalRegistry: $registry } } }
    ) {
      nodes {
        id
        name
        registry
        enabled
      }
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query GetEmployeeById($id: UUID!) {
    employee: employeeById(id: $id) {
      id
      name
      registry
      enabled
    }
  }
`;
