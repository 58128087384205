import React from 'react';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { ElapsedTime, Heading, Icon, Text } from '@quality24/design-system';
import {
  Alert,
  HospitalDepartment,
  ServiceGroupItem,
  Service,
} from '@quality24/inpatient-typings';
import classNames from 'classnames';

interface ExtendedAlert extends Required<Pick<Alert, 'id' | 'createdAt'>> {
  group: Pick<ServiceGroupItem, 'id' | 'name'> & {
    service: Required<Pick<Service, 'id' | 'reason'>> & {
      department: Required<Pick<HospitalDepartment, 'id' | 'name'>>;
    };
  };
}

export interface Props {
  className?: string;
  alert: ExtendedAlert;
}

const ActiveAlertCard: React.FunctionComponent<Props> = ({
  className,
  alert,
}) => {
  const { group } = alert;

  return (
    <div
      className={classNames(
        className,
        'rounded-md bg-white p-3 border border-2 border-primary',
      )}
      role="listitem"
    >
      <div className="row">
        <div className="col-6 col-sm-8 col-md-10">
          <Heading as="h3" weight="semiBold">
            {group.service?.department?.name}
          </Heading>
          <Text>{group.name?.['pt-BR'] || group.service?.reason['pt-BR']}</Text>
        </div>
        <div className="col-6 col-sm-4 col-md-2 d-flex align-items-center gap-2">
          <Icon icon={faStopwatch} color="primary" />
          <ElapsedTime start={alert.createdAt} />
        </div>
      </div>
    </div>
  );
};

export default ActiveAlertCard;
