/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';

type Template = (data?: Record<string, unknown>) => string;
type Compiler = (input?: string | null) => Template;

export const handlebarsCompiler: Compiler = (input) => {
  if (!input) return () => '';
  const re = /\{{(.*?)\}}/g;
  const paths: Array<string> = input.match(re) || [];

  const template: Template = (data) =>
    paths.reduce(
      (acc, path) =>
        acc.replace(
          path,
          get(data, path.replace('{{', '').replace('}}', '')) as string,
        ),
      input,
    );

  return template;
};
